import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import {
  Card,
  Tabs,
  Tab,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import GavelIcon from "@mui/icons-material/Gavel";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";

import Table from "../../../components/Table/Table";
import {
  getAllOpenLots,
  getAllClosedLots,
  getAllLots,
  capitalize,
} from "../MarketplaceState";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function ViewLot() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [openLots, setOpenLots] = useState([]);
  const [closedLots, setClosedLots] = useState([]);
  const [allLots, setAllLots] = useState([]);
  const [open, setOpen] = useState(false);
  const [listParticularTableData, setListParticularTableData] = useState("");
  const [openFetchLoader, setOpenFetchLoader] = useState(false);
  const [closeFetchLoader, setCloseFetchLoader] = useState(false);
  const [allfetchLoader, setAllFetchLoader] = useState(false);
  const selectHeader = () => {
    if (value === 1) {
      return [
        {
          label: "Id",
          options: {
            filter: true,
          },
        },

        {
          label: t("lot_title"),
          options: {
            filter: true,
          },
        },
        {
          label: t("close_date"),
          options: {
            filter: true,
          },
        },
        {
          label: t("status"),
          options: {
            filter: true,
          },
        },
        {
          label: t("progress_status"),
          options: {
            filter: true,
          },
        },
        {
          label: t("common_action"),
          options: {
            filter: true,
          },
        },
        {
          label: t("bid_details_view"),
          options: {
            filter: true,
          },
        },
      ];
    } else {
      return [
        {
          label: "Id",
          options: {
            filter: true,
          },
        },

        {
          label: t("lot_title"),
          options: {
            filter: true,
          },
        },
        {
          label: t("close_date"),
          options: {
            filter: true,
          },
        },
        {
          label: t("status"),
          options: {
            filter: true,
          },
        },
        {
          label: t("common_action"),
          options: {
            filter: true,
          },
        },
        {
          label: t("bid_details_view"),
          options: {
            filter: true,
          },
        },
      ];
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("TabValue1", newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onRowClick = (rowData) => {
    setListParticularTableData(rowData);
    setOpen(true);
  };

  const handleEdit = (data) => {
    dispatch({
      type: "SET_SELECTED_LOT",
      payload: data,
    });
    history.push("/app/editLot");
  };

  const viewBids = (data) => {
    dispatch({
      type: "SET_SELECTED_LOT",
      payload: data,
    });
    history.push("/app/viewBids");
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const tabData = [
    { label: t("common_open"), index: 0, name: "Open Listings" },
    { label: t("common_closed"), index: 2, name: "Closed Listings" },
    { label: t("common_all"), index: 3, name: "All Listings" },
  ];

  var openLotsDataTableData = [];
  var closedLotsDataTableData = [];
  var allLotsDataTableData = [];

  const getAllOpenLot = () => {
    setOpenFetchLoader(true);
    getAllOpenLots()
      .then((response) => {
        const status =
          typeof response.status === "string"
            ? parseInt(response.status, 10)
            : response.status;
        if (status === 200) {
          if (response && response.data && response.data.lots) {
            setOpenLots(response.data.lots);
            setOpenFetchLoader(false);
          } else {
            setOpenLots([]);
            setOpenFetchLoader(false);
          }
        } else if (status === 401) {
          setTimeout(() => {
            dispatch({ type: "LOG_OUT" });
            localStorage.clear();
          }, 2000);
        } else {
          console.error("Error fetching data");
          setOpenFetchLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setOpenFetchLoader(false);
      });
  };

  const getAllClosedLot = () => {
    setCloseFetchLoader(true);
    getAllClosedLots()
      .then((response) => {
        const status =
          typeof response.status === "string"
            ? parseInt(response.status, 10)
            : response.status;
        if (status === 200) {
          if (response && response.data && response.data.lots) {
            setClosedLots(response.data.lots.sort(compareProgressCode));
            setCloseFetchLoader(false);
          } else {
            setClosedLots([]);
            setCloseFetchLoader(false);
          }
        } else if (response.status === "401") {
          setTimeout(() => {
            dispatch({ type: "LOG_OUT" });
            localStorage.clear();
          }, 2000);
        } else {
          console.error("Error fetching data");
          setCloseFetchLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setCloseFetchLoader(false);
      });
  };

  const compareProgressCode = (a, b) => {
    return a.progressCode - b.progressCode;
  };

  const getAllLot = () => {
    setAllFetchLoader(true);
    getAllLots()
      .then((response) => {
        const status =
          typeof response.status === "string"
            ? parseInt(response.status, 10)
            : response.status;
        if (status === 200) {
          if (response && response.data && response.data.lots) {
            setAllLots(response.data.lots);
            setAllFetchLoader(false);
          } else {
            setAllLots([]);
            setAllFetchLoader(false);
          }
        } else if (status === 401) {
          setTimeout(() => {
            dispatch({ type: "LOG_OUT" });
            localStorage.clear();
          }, 2000);
        } else {
          console.error("Error fetching data");
          setAllFetchLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setAllFetchLoader(false);
      });
  };

  useEffect(() => {
    getAllOpenLot();
    getAllClosedLot();
    getAllLot();
    setValue(
      parseInt(localStorage.getItem("TabValue1"))
        ? parseInt(localStorage.getItem("TabValue1"))
        : 0
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <Card>
          <Tabs value={value} onChange={handleChange} indicatorColor="primary">
            {tabData.map((item, key) => {
              return (
                <Tab
                  label={item.label}
                  {...a11yProps(item.index)}
                  classes={{ root: classes.tab }}
                  key={key}
                />
              );
            })}
          </Tabs>

          <TabPanel value={value} index={0} key={1}>
            {
              // eslint-disable-next-line
              openLots.map((item, key) => {
                openLotsDataTableData.push([
                  // item.tenantId,
                  item.readableLotId ? item.readableLotId : "LotID",
                  item.title ? capitalize(item.title) : "Title",
                  moment(item.lastDate).format("DD/MM/YYYY"),
                  item.status ? capitalize(item.status) : "Status",
                  <Tooltip title="Edit Lot">
                    <IconButton
                      onClick={() => handleEdit(item)}
                      className={classes.themeIcon}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip title="View Bid Details">
                    <IconButton
                      onClick={() => viewBids(item)}
                      className={classes.themeIcon}
                      size="large"
                    >
                      <GavelIcon />
                    </IconButton>
                  </Tooltip>,
                ]);
              })
            }

            <Table
              data={openLotsDataTableData}
              header={selectHeader()}
              // name={t("open_lot_list")}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
                textLabels: {
                  body: {
                    noMatch: openFetchLoader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#0D6937", marginRight: "5px" }}
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      "No listings found"
                    ),
                  },
                },
              }}
            />
          </TabPanel>

          <TabPanel value={value} index={1} key={2}>
            {
              // eslint-disable-next-line
              closedLots.map((item, key) => {
                const isFinalized = item.progressStatus === "finalized";
                closedLotsDataTableData.push([
                  // item.tenantId,
                  item.readableLotId ? item.readableLotId : "LotID",
                  item.title ? capitalize(item.title) : "Title",
                  moment(item.lastDate).format("DD/MM/YYYY"),
                  item.status ? capitalize(item.status) : "Status",
                  item.progressStatus
                    ? capitalize(item.progressStatus)
                    : "Progress Status",
                  <Tooltip title={isFinalized ? "View lot" : "Edit lot"}>
                    <IconButton
                      onClick={() => handleEdit(item)}
                      className={classes.themeIcon}
                      size="large"
                    >
                      {isFinalized ? <VisibilityIcon /> : <EditIcon />}
                    </IconButton>
                  </Tooltip>,
                  <Tooltip title="View Bid Details">
                    <IconButton
                      onClick={() => viewBids(item)}
                      className={classes.themeIcon}
                      size="large"
                    >
                      <GavelIcon />
                    </IconButton>
                  </Tooltip>,
                ]);
              })
            }

            <Table
              data={closedLotsDataTableData}
              header={selectHeader()}
              // name={t("closed_lot_list")}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
                textLabels: {
                  body: {
                    noMatch: closeFetchLoader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#0D6937", marginRight: "5px" }}
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      "No closed listings found"
                    ),
                  },
                },
              }}
            />
          </TabPanel>

          <TabPanel value={value} index={2} key={3}>
            {
              // eslint-disable-next-line
              allLots.map((item, key) => {
                allLotsDataTableData.push([
                  // item.tenantId,
                  item.readableLotId ? item.readableLotId : "LotID",
                  item.title ? capitalize(item.title) : "Title",
                  moment(item.lastDate).format("DD/MM/YYYY"),
                  item.status ? capitalize(item.status) : "Status",
                  <Tooltip title="Edit Lot">
                    <IconButton
                      onClick={() => handleEdit(item)}
                      className={classes.themeIcon}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>,
                  <Tooltip title="View Bid Details">
                    <IconButton
                      onClick={() => viewBids(item)}
                      className={classes.themeIcon}
                      size="large"
                    >
                      <GavelIcon />
                    </IconButton>
                  </Tooltip>,
                ]);
              })
            }
            <Table
              data={allLotsDataTableData}
              header={selectHeader()}
              // name={t("all_lot_list")}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
                textLabels: {
                  body: {
                    noMatch: allfetchLoader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#0D6937", marginRight: "5px" }}
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      "No listings found"
                    ),
                  },
                },
              }}
            />
          </TabPanel>
        </Card>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Lot Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <List className={classes.listItem}>
            {/* <ListItem>
                        <ListItemText primary="Lot ID:" />
                        <ListItemText secondary={listParticularTableData.tenantId} />
                    </ListItem> */}
            <ListItem>
              <ListItemText primary="Lot title:" />
              <ListItemText secondary={listParticularTableData.title} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Close Date:" />
              <ListItemText
                secondary={new Date(
                  listParticularTableData.lastDate
                ).toLocaleString("en-US")}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Status:" />
              <ListItemText secondary={listParticularTableData.status} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Location:" />
              <ListItemText secondary={listParticularTableData.location} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Base Price:" />
              <ListItemText secondary={listParticularTableData.basePrice} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Location:" />
              <ListItemText secondary={listParticularTableData.location} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Description:" />
              <ListItemText secondary={listParticularTableData.description} />
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
