import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  CircularProgress,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Paper,
  Chip,
  InputBase,
  Alert,
} from "@mui/material";
import {
  Email as EmailIcon,
  History as HistoryIcon,
  ContentCopy as ContentCopyIcon,
  PictureAsPdf as PdfIcon,
} from "@mui/icons-material";
import toast from "react-hot-toast";
import { assignLead, sendForm6 } from "../../../../LeadState";
import { getFiles } from "../utils";

const SendForm6Dialog = ({
  open,
  onClose,
  contactPerson,
  form6File,
  leadDetails,
  dispatch,
  close,
}) => {
  const [emailList, setEmailList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [sendHistory, setSendHistory] = useState(
    leadDetails.form6History || []
  );
  const [error, setError] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if (contactPerson?.email) {
      setEmailList([contactPerson.email]);
    }
  }, [contactPerson]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.endsWith(",") || value.endsWith(" ")) {
      const email = value.slice(0, -1).trim();
      if (email) handleAddEmail(email);
      setInputValue("");
    } else {
      setInputValue(value);
      setError("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (inputValue.trim()) {
        handleAddEmail(inputValue.trim());
        setInputValue("");
      }
    } else if (e.key === "Backspace" && !inputValue) {
      e.preventDefault();
      const lastEmail = emailList[emailList.length - 1];
      if (lastEmail && lastEmail !== contactPerson?.email) {
        setEmailList((prev) => prev.slice(0, -1));
      }
    }
  };

  const handleAddEmail = (email) => {
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }
    if (emailList.includes(email)) {
      setError("Email already added");
      return;
    }
    setEmailList((prev) => [...prev, email]);
    setError("");
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmailList((prev) => prev.filter((email) => email !== emailToRemove));
  };

  const handleGetPdf = async (fileName, folderName) => {
    try {
      await getFiles({ fileName, folderName });
    } catch (error) {
      toast.error("Error retrieving PDF file");
    }
  };

  // need to verify if i have to send the assingLead function to the sendForm6 function

  const handleSendForm6 = async () => {
    if (emailList.length === 0) {
      setError("At least one email address is required");
      return;
    }

    setShowLoader(true);
    try {
      const form6Data = {
        form6: {
          fileName: form6File.fileName,
          folderName: form6File.folderName,
          expireLimit: 1800,
        },
        leadId: leadDetails.external_id,
        recipients: emailList,
      };

      const response = await sendForm6(form6Data, dispatch);

      if (response.status === "200") {
        const leadData = {
          external_id: leadDetails.external_id,
          leadData: {
            status: "Form6 sent",
          },
          mpr_id: leadDetails.Mpr_id,
        };

        await assignLead(leadData, dispatch);
        toast.success("Form-6 sent successfully!");
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        toast.error("Failed to send Form-6");
      }
    } catch (error) {
      toast.error("An error occurred while sending Form-6");
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 2, p: 1, maxWidth: 800 },
      }}
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <EmailIcon color="primary" />
        <Typography variant="h6">Email Form 6 to Customer</Typography>
      </DialogTitle>

      <DialogContent>
        <Alert severity="info">
          All emails will be sent from hello@binbag.in. (Add your email if you’d
          like to receive a copy.)
        </Alert>
        <Box sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Contact Person
          </Typography>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              gap: 2,
              backgroundColor: "action.hover",
            }}
          >
            <Typography variant="body1">
              {contactPerson?.userName || "No name provided"}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            >
              <EmailIcon fontSize="small" />
              {contactPerson?.email || "No email provided"}
            </Typography>
          </Paper>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Recipients
          </Typography>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              minHeight: 56,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: 1,
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {emailList.map((email) => (
              <Chip
                key={email}
                label={email}
                onDelete={() => handleRemoveEmail(email)}
                color={email === contactPerson?.email ? "primary" : "default"}
                size="small"
              />
            ))}
            <InputBase
              sx={{ flex: 1, ml: 1 }}
              placeholder="Add email addresses (separate with comma or Enter)"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </Paper>
          {error && (
            <Typography color="error" variant="caption" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Box>

        {sendHistory.length > 0 && (
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="subtitle1"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <HistoryIcon fontSize="small" />
              Send History
            </Typography>
            <List dense>
              {[...sendHistory].reverse().map((history, index) => (
                <ListItem
                  key={index}
                  divider={index !== sendHistory.length - 1}
                  secondaryAction={
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Tooltip title="View PDF">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() =>
                            handleGetPdf(
                              history.quotationData.fileName,
                              history.quotationData.folderName
                            )
                          }
                        >
                          <PdfIcon fontSize="small" color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Copy emails">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              history.recipients.join(", ")
                            );
                            toast.success("Emails copied!");
                          }}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  }
                >
                  <ListItemText
                    primary={history.recipients.join(", ")}
                    secondary={new Date(history.timestamp).toLocaleString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      }
                    )}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 2, gap: 1 }}>
        <Button variant="outlined" onClick={onClose} disabled={showLoader}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSendForm6}
          disabled={showLoader || emailList.length === 0}
          startIcon={
            showLoader ? <CircularProgress size={20} /> : <EmailIcon />
          }
        >
          {showLoader ? "Sending..." : "Send Form 6"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendForm6Dialog;
