import React, { useState } from "react";
import {
  Grid,
  Paper,
  CircularProgress,
  TableRow,
  TableCell,
  Link,
  Button,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "../style.css";
import FileBase64 from "react-file-base64";
import useStyles from "../styles";
import { fileUpload } from "../MarketplaceState";
import AttachmentIcon from "@mui/icons-material/Attachment";

const ImageUpload = ({ setForm, formData }) => {
  const classes = useStyles();
  const [uploadedFileReference, setUploadedFileReference] = useState(
    formData.filesWithReference
  );
  const [materialList, setMaterialList] = useState(formData.materialList);
  const [lotType, setLotType] = useState(formData.lotType);
  const [showLoader, setShowLoader] = useState(false);
  const [fileAlert, setFileAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // Universal file upload function
  const handleFileUpload = async (files, config) => {
    const {
      acceptedTypes = ["image/png", "image/jpeg", "image/jpg"],
      folderPath = "mrkplc/",
      errorMessage = "Please upload .png,.jpg,.jpeg image formats",
      destination = "files", // can be "files" or "materialList"
    } = config || {};

    await Promise.all(
      files.map(async (item) => {
        if (acceptedTypes.includes(item.type)) {
          setShowLoader(true);
          setFileAlert(false);

          const fileName = item.name;
          const fileType = fileName.split(".").pop();
          const baseData = item.base64.split(",");

          const getTokenData = {
            abbrevation: Math.floor(Math.random() * 100) + "Mrkplc",
            fileType: fileType,
            folderName: folderPath,
            expireLimt: 1800,
            extension: fileName.split(".").pop(),
          };

          try {
            const response = await fileUpload(
              baseData.length > 0 ? baseData[1] : "",
              getTokenData,
              item.type
            );

            if (response.status === "200") {
              const objWithRef = {
                fileName: response.fileName || response.filename,
                ref: response.reference,
                imageId: response.fileName || response.filename,
                folderName:
                  response.folderName || response.folder || response.FolderName,
              };

              if (destination === "files") {
                formData.filesWithReference.push(objWithRef);
                setUploadedFileReference([...formData.filesWithReference]);
              } else if (destination === "materialList") {
                // Add to materialList
                formData.materialList.push(objWithRef);
                setMaterialList([...formData.materialList]);
              }
            }
          } catch (error) {
            console.error("Upload error:", error);
          } finally {
            setShowLoader(false);
          }
        } else {
          setFileAlert(true);
          setAlertMessage(errorMessage);
        }
      })
    );
  };

  // Handle image uploads
  const handleImageChange = (e) => {
    handleFileUpload(e, {
      acceptedTypes: ["image/png", "image/jpeg", "image/jpg"],
      folderPath: "mrkplc/",
      errorMessage: "Please upload .png,.jpg,.jpeg image formats",
      destination: "files",
    });
  };

  // Handle material file uploads
  const handleMaterialFileUpload = (e) => {
    handleFileUpload(e, {
      acceptedTypes: [
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      folderPath: "mrkplc/materials/",
      errorMessage: "Please upload .pdf or Excel files",
      destination: "materialList",
    });
  };

  // Delete file from filesWithReference
  const deleteFile = (data, index) => {
    let array = [...uploadedFileReference];
    const newArray = array.filter((m) => m.imageId !== data.imageId);
    setUploadedFileReference(newArray);
    formData.filesWithReference = newArray;
  };

  // Delete file from materialList
  const deleteMaterialFile = (data, index) => {
    let array = [...materialList];
    const newArray = array.filter((m) => m.imageId !== data.imageId);
    setMaterialList(newArray);
    formData.materialList = newArray;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12} sm={12}>
          <Paper className={classes.paper}>
            <div className={classes.form}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <span>Upload Material Images</span>
                  <Grid container spacing={2}>
                    <Grid item lg={11} md={11} sm={12} xs={12}>
                      <div className={classes.fileTypeInput}>
                        <FileBase64
                          className={classes.input}
                          multiple={true}
                          onDone={(e) => handleImageChange(e)}
                        />
                      </div>
                      {fileAlert && (
                        <span className={classes.spanAlert}>
                          {alertMessage}
                        </span>
                      )}
                    </Grid>
                    <Grid item lg={4} md={4} sm={2} xs={2}>
                      <label htmlFor="lot-file">
                        {showLoader ? <CircularProgress /> : <></>}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <div className="result" style={{ marginTop: "1rem" }}>
        {uploadedFileReference.length > 0 &&
          uploadedFileReference.map((data, index) => {
            return (
              <div className="img-wrap" key={index}>
                <img src={data.ref} alt="" className="image-wrapper" />
                <CancelIcon
                  className="close"
                  onClick={() => deleteFile(data, index)}
                />
              </div>
            );
          })}
      </div>

      <>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <Paper className={classes.paper}>
              <div className={classes.form}>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <span>Upload Material Files (Excel, PDF)</span>
                    <Grid container spacing={2}>
                      <Grid item lg={11} md={11} sm={12} xs={12}>
                        <div className={classes.fileTypeInput}>
                          <FileBase64
                            className={classes.input}
                            multiple={true}
                            onDone={(e) => handleMaterialFileUpload(e)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <div className="result" style={{ marginTop: "1rem" }}>
          {materialList.length > 0 &&
            materialList.map((data, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{data.fileName}</TableCell>
                  <TableCell>
                    <Link href={data.ref} target="_blank" underline="hover">
                      <AttachmentIcon />
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => deleteMaterialFile(data, index)}>
                      <Typography
                        style={{
                          fontSize: "12px",
                          textTransform: "capitalize",
                          marginLeft: "-16px",
                        }}
                      >
                        Remove
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </div>
      </>
    </>
  );
};

export default ImageUpload;
