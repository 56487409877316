import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Paper,
  Typography,
  Box,
  InputAdornment,
  Divider,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import AsyncSelect from "react-select/async";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";
import { searchTag } from "../MarketplaceState";
import moment from "moment";
import { NumberFormatCustom } from "../../../utils/currencyFormat";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const BasicDetails = ({ setForm, formData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [basicPrice, setBasicPrice] = useState(formData.basicPrice || "");

  // Fields for single item lot type
  const [quantity, setQuantity] = useState(formData.quantity || 0);
  const [quantityUnit, setQuantityUnit] = useState(formData.quantityUnit || "");
  const [ratePerUnit, setRatePerUnit] = useState(formData.ratePerUnit || 0);

  const [title, setTitle] = useState(formData.title || "");
  const [location, setLocation] = useState(
    formData.location || {
      address: "",
      city: "",
      state: "",
      pinCode: "",
    }
  );
  const [description, setDescription] = useState(formData.description || "");
  const [detailedDescription, setDetailedDescription] = useState(
    formData.detailedDescription || ""
  );
  const [lastDate, setLastDate] = useState(formData.lastDate || "");
  const [lotType, setLotType] = useState(formData.lotType || "SAME_ITEMS");
  const [status, setStatus] = useState(formData.status || "open");
  const [lotAvailability, setLotAvailability] = useState(
    formData.lotAvailability || "upcoming"
  );
  const [inputValue, setValue] = useState();
  const [selectedValue, setSelectedValue] = useState(formData.tags || []);

  // Error states
  const [dateError, setDateError] = useState("");
  const [basicPriceError, setBasicPriceError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [detailedDescriptionError, setDetailedDescriptionError] = useState("");
  var tagOptionSelected = [];

  // Update the form data whenever a field changes
  useEffect(() => {
    setForm({
      ...formData,
      basicPrice,
      quantity,
      quantityUnit,
      ratePerUnit,
      title,
      location,
      description,
      detailedDescription,
      lastDate,
      lotType,
      status,
      lotAvailability,
      tags: selectedValue,
    });
  }, [
    basicPrice,
    quantity,
    quantityUnit,
    ratePerUnit,
    title,
    location,
    description,
    detailedDescription,
    lastDate,
    lotType,
    status,
    lotAvailability,
    selectedValue,
  ]);

  const handleBasicPrice = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setBasicPrice(value);
    if (value === "") {
      setBasicPriceError("Field is required");
    } else {
      setBasicPriceError("");
    }
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(value);
    if (value.length <= 1) {
      setTitleError("Please enter atleast 2 characters");
    } else {
      setTitleError("");
    }
  };

  const handleDescription = (e) => {
    const value = e.target.value;
    setDescription(value);
    if (value.length <= 1) {
      setDescriptionError("Please enter atleast 2 characters");
    } else {
      setDescriptionError("");
    }
  };

  const handleDetailedDescription = (e) => {
    const value = e.target.value;
    setDetailedDescription(value);
    if (value.length <= 1) {
      setDetailedDescriptionError("Please enter atleast 2 characters");
    } else {
      setDetailedDescriptionError("");
    }
  };

  const handleLastDate = (date) => {
    const selected = date.target.value;
    const isAfter = moment(selected).isAfter(moment());
    if (isAfter) {
      setLastDate(selected);
      setDateError("");
    } else {
      setLastDate(selected);
      setDateError("Please enter the future date");
    }
  };

  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (tags) => {
    setSelectedValue(tags.value);
    tags.map((item) => {
      const newwObj = { label: item.label, value: item.value };
      tagOptionSelected.push(newwObj);
      setSelectedValue(tagOptionSelected);
    });
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleAvailabilityChange = (event) => {
    setLotAvailability(event.target.value);
  };

  const handleLotTypeChange = (event) => {
    setLotType(event.target.value);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleQuantityUnitChange = (e) => {
    setQuantityUnit(e.target.value);
  };

  const handleRatePerUnitChange = (e) => {
    setRatePerUnit(e.target.value);
  };

  const getAllTag = (e, callback) => {
    if (e.length > 0) {
      searchTag(e).then((response) => {
        const tempArray = [];
        if (response.data.tags) {
          response.data.tags.forEach((element) => {
            tempArray.push({
              label: `${element.item}`,
              value: element._id,
            });
          });
        } else {
          console.error("error");
        }
        callback(tempArray);
      });
    }
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <Grid container spacing={3} mt={2}>
        <Grid lg={2} md={1} xs={0} sm={0} item></Grid>
        <Grid lg={8} md={10} xs={12} sm={12} item>
          <Paper
            className={classes.paper}
            elevation={3}
            sx={{ padding: "1.5rem" }}
          >
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xs={12} sm={12}>
                {/* Lot Type Radio Buttons instead of Tabs */}
                <Box sx={{ marginBottom: "1.5rem" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    Type
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="lot-type"
                      name="lot-type"
                      value={lotType}
                      onChange={handleLotTypeChange}
                    >
                      <FormControlLabel
                        value="SAME_ITEMS"
                        control={<Radio />}
                        label="Single Item"
                      />
                      <FormControlLabel
                        value="DIFFERENT_ITEMS"
                        control={<Radio />}
                        label="Lot"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    {t("lot_title")}
                  </Typography>
                  <TextField
                    id="title"
                    placeholder="Enter title"
                    fullWidth
                    name="title"
                    value={title}
                    onChange={handleTitle}
                    error={!!titleError}
                    helperText={titleError}
                    variant="outlined"
                    margin="dense"
                  />
                </Box>

                {/* Show these fields only for SAME_ITEMS lot type */}
                {lotType === "SAME_ITEMS" && (
                  <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          gutterBottom
                        >
                          Quantity
                        </Typography>
                        <TextField
                          id="quantity"
                          placeholder="Enter quantity"
                          fullWidth
                          name="quantity"
                          value={quantity}
                          onChange={handleQuantityChange}
                          variant="outlined"
                          margin="dense"
                          type="number"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          gutterBottom
                        >
                          Unit
                        </Typography>
                        <TextField
                          id="quantityUnit"
                          placeholder="e.g. kg, pcs"
                          fullWidth
                          name="quantityUnit"
                          value={quantityUnit}
                          onChange={handleQuantityUnitChange}
                          variant="outlined"
                          margin="dense"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          gutterBottom
                        >
                          Rate Per Unit
                        </Typography>
                        <TextField
                          id="ratePerUnit"
                          placeholder="Rate per unit"
                          fullWidth
                          name="ratePerUnit"
                          value={ratePerUnit}
                          onChange={handleRatePerUnitChange}
                          variant="outlined"
                          margin="dense"
                          // type="number"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Base price (Exclusive of tax)
                      </Typography>
                      <TextField
                        id="basic_price"
                        placeholder="Enter starting price"
                        fullWidth
                        name="basicPrice"
                        value={basicPrice}
                        onChange={handleBasicPrice}
                        error={!!basicPriceError}
                        helperText={basicPriceError}
                        variant="outlined"
                        margin="dense"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Location of material
                      </Typography>
                      <TextField
                        id="address-street"
                        placeholder="Address"
                        fullWidth
                        name="address"
                        value={location.address || ""}
                        onChange={(e) => {
                          const updatedLocation = {
                            ...location,
                            address: e.target.value,
                          };
                          setLocation(updatedLocation);
                          formData.location = updatedLocation;
                        }}
                        error={!!locationError}
                        helperText={locationError}
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        City
                      </Typography>
                      <TextField
                        id="address-city"
                        placeholder="City"
                        fullWidth
                        name="city"
                        value={location.city || ""}
                        onChange={(e) => {
                          const updatedLocation = {
                            ...location,
                            city: e.target.value,
                          };
                          setLocation(updatedLocation);
                          formData.location = updatedLocation;
                        }}
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        State
                      </Typography>
                      <TextField
                        id="address-state"
                        placeholder="State"
                        fullWidth
                        name="state"
                        value={location.state || ""}
                        onChange={(e) => {
                          const updatedLocation = {
                            ...location,
                            state: e.target.value,
                          };
                          setLocation(updatedLocation);
                          formData.location = updatedLocation;
                        }}
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Pin Code
                      </Typography>
                      <TextField
                        id="address-pincode"
                        placeholder="Pin Code"
                        fullWidth
                        name="pinCode"
                        value={location.pinCode || ""}
                        onChange={(e) => {
                          const updatedLocation = {
                            ...location,
                            pinCode: e.target.value,
                          };
                          setLocation(updatedLocation);
                          formData.location = updatedLocation;
                        }}
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Status
                      </Typography>
                      <FormControl fullWidth variant="outlined" margin="dense">
                        {/* <InputLabel id="status-select-label">
                            Status
                          </InputLabel> */}
                        <Select
                          labelId="status-select-label"
                          id="status-select"
                          value={status}
                          onChange={handleStatusChange}
                          // label="Status"
                        >
                          <MenuItem value="open">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              Open
                            </Box>
                          </MenuItem>
                          <MenuItem value="closed">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              Closed
                            </Box>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ marginBottom: "1.5rem" }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Availability
                      </Typography>
                      <FormControl fullWidth variant="outlined" margin="dense">
                        <Select
                          labelId="availability-select-label"
                          id="availability-select"
                          value={lotAvailability}
                          onChange={handleAvailabilityChange}
                          // label="Availability"
                        >
                          <MenuItem value="upcoming">Upcoming</MenuItem>
                          <MenuItem value="current">Current</MenuItem>
                          <MenuItem value="past">Past</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                {/* <Box sx={{ marginBottom: "1.5rem" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    {t("common_description")}
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    id="description"
                    placeholder="Enter brief description"
                    name="description"
                    value={description}
                    onChange={handleDescription}
                    error={!!descriptionError}
                    helperText={descriptionError}
                  />
                </Box> */}

                <Box sx={{ marginBottom: "1.5rem" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    {t("lot_detailed_description")}
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    variant="outlined"
                    id="detailedDescription"
                    placeholder="Enter details about the material"
                    name="detailedDescription"
                    value={detailedDescription}
                    onChange={handleDetailedDescription}
                    error={!!detailedDescriptionError}
                    helperText={detailedDescriptionError}
                  />
                </Box>

                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    {t("close_date")}
                  </Typography>
                  <TextField
                    name="date"
                    id="date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={lastDate}
                    onChange={handleLastDate}
                    error={!!dateError}
                    helperText={dateError}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    inputProps={{ min: getCurrentDate() }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DateRangeIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    {t("lot_tags")}
                  </Typography>
                  <AsyncSelect
                    id="tags"
                    placeholder={t("lot_search_tag")}
                    isMulti
                    noOptionsMessage={() => "No tag found"}
                    cacheOptions
                    defaultOptions
                    name="tags"
                    value={selectedValue}
                    loadOptions={getAllTag}
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid lg={2} md={1} xs={0} sm={0} item></Grid>
      </Grid>
    </>
  );
};

export default BasicDetails;
