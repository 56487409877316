import { NumericFormat } from "react-number-format";

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="₹"
    />
  );
}

export function formatIndianCurrency(number) {
  // Handle null/undefined/invalid input
  if (!number || isNaN(number)) return "₹0";

  // Convert to number if string
  const amount = typeof number === "string" ? parseFloat(number) : number;

  // Format with Indian numbering system
  const formatted = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);

  return formatted.replace("INR", "₹").replace("₹ ", "₹");
}



export function currencyFormat(num) {
  return parseInt(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
