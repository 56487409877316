import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Card,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useStyles from "./styles";
import Table from "../../components/Table/Table";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { createMprId } from "../material_pickup_request/MprState";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { deleteAddress } from "./AddressState";
import NotificationContainer from "../notifications/NotificationContainer";

const Address = () => {
  var classes = useStyles();
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [address, setAddress] = useState([]);
  const [showLoader, setShowloader] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState(false);

  var datatableData = [];
  const datatableHeader = [
    {
      label: t("common_address"),
      name: "address",
      options: {
        filter: true,
      },
    },
    {
      label: t("common_country"),
      name: "country",
      options: {
        filter: true,
      },
    },
    {
      label: t("common_city"),
      name: "city",
      options: {
        filter: true,
      },
    },
    {
      label: t("common_state"),
      name: "state",
      options: {
        filter: true,
      },
    },
    {
      label: t("common_pincode"),
      name: "pincode",
      options: {
        filter: true,
      },
    },
    {
      name: t("common_action"),
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return showLoader ? (
            <CircularProgress />
          ) : (
            <IconButton
              aria-label="delete"
              className={classes.deleteButton}
              onClick={() => onRowClick(dataIndex)}
              size="large"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          );
        },
      },
    },
  ];
  const dispatch = useDispatch();
  var data = useSelector((state) => state.userdata);
  let sendData = {
    abbrevation: data.getData.abbrevation,
    customerid: localStorage.getItem("customerid"),
  };

  useEffect(() => {
    createMprId(sendData, dispatch).then((response) =>
      response ? setAddress(response.address) : setAddress([])
    );
    // eslint-disable-next-line
  }, []);

  const onRowClick = (index) => {
    var id = "";
    // eslint-disable-next-line
    address.map((item, key) => {
      if (key === index) {
        id = item._id;
      }
    });
    deleteAddress(id).then((response) => {
      setShowloader(true);
      if (response.status === "200") {
        setShowloader(false);
        setType("success");
        setMessage(response.message);
        setNotification(true);
        createMprId(sendData, dispatch).then((response) =>
          setAddress(response.address)
        );
      } else {
        setShowloader(false);
        let msg = [];
        setNotification(true);
        setType("error");
        if (response.ErrorData) {
          // eslint-disable-next-line
          response.ErrorData.details.map((item) => {
            msg.push(item.message);
          });
          setMessage(`${msg.join()}`);
        } else {
          setMessage("Something went Wrong! Please try again!");
        }
      }
    });
  };

  const breadcrumb = [
    // {label: t("common_home") , link: "/#/app/dashboard"},
    { label: t("cust_administration"), link: "/#/app/customeradmin" },
    { label: t("common_address"), link: "" },
  ];
  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        {notification ? (
          <NotificationContainer message={message} notificationtype={type} />
        ) : (
          <></>
        )}
        <div className={classes.pageTitleContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <div className={classes.HeaderContainer}>
                  <div className={classes.tenantHeader}>
                    <Typography variant="h3" size="sm">
                      {t("common_address")}|{" "}
                    </Typography>
                    <span className={classes.tenantSubHeader}>
                      &nbsp;{t("cust_showing_add_list")}
                    </span>
                  </div>

                  <div>
                    <Button
                      href="/#/app/addAdress"
                      variant="outlined"
                      color="primary"
                      className={classes.buttonOutlined}
                    >
                      {t("cust_add_button")}
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {
              // eslint-disable-next-line
              address.map((item, key) => {
                datatableData.push([
                  item.address.address1 + "," + item.address.address2,
                  item.country,
                  item.district,
                  item.state,
                  item.pincode,
                ]);
              })
            }

            <Table
              data={datatableData}
              header={datatableHeader}
              name={t("cust_add_list")}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Address;
