import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  CircularProgress,
  Box,
  Grid,
  Typography,
  Alert,
} from "@mui/material";

import useStyles from "../styles";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "./ConfirmationDialog";
import { ToWords } from "to-words";
import {
  assignLead,
  quotationGeneration,
  getQuotationGenId,
  addSignature,
} from "../../../LeadState";
import { formatIndianCurrency } from "../../../../../utils/currencyFormat";
import { quotationValueInWords } from "./utils/helperFunctions";
import {
  quotationColumns,
  DEFAULT_CGST_PERCENTAGE,
  DEFAULT_CGST_AMOUNT,
  DEFAULT_SGST_PERCENTAGE,
  DEFAULT_SGST_AMOUNT,
  DEFAULT_IGST_PERCENTAGE,
  DEFAULT_IGST_AMOUNT,
  DEFAULT_REGION,
} from "./utils/constants";
import toast from "react-hot-toast";
import GSTCalculations from "./components/GstCalculations";
import ItemsTable from "./components/ItemsTable";
import Notes from "./components/Notes";
import QuotationHeader from "./components/QuotationHeader";
import FinalAmount from "./components/FinalAmount";
import { fileupload } from "../../../../material_pickup_request/MprState";
import SignatureUploader from "./components/SignatureUploader";

const CreateQuotation = (props) => {
  const classes = useStyles();
  const [fileData, setFileData] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedNoteRow, setSelectedNoteRow] = useState();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isConfirmNoteDialogOpen, setIsConfirmNoteDialogOpen] = useState(false);
  const [notes, setNotes] = useState([""]);
  const [quotationNo, setQuotationNo] = useState("");
  const [gstAmount, setGstAmount] = useState(0);

  // const [showStampLoader, setShowStampLoader] = useState(false);
  const [showSignatureLoader, setShowSignatureLoader] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [thankofProposal, setThankofProposal] = useState(
    `Thank you for considering our proposal. If you have any questions, feel free to contact the undersigned.`
  );
  // const [quotationContent, setQuotationContent] = useState("");
  var selectedLead = useSelector((state) => state.selectedLead[0]);
  const [quotationSubject, setQuotationSubject] = useState(
    `Quotation for disposal of E-Waste.`
  );
  const dispatch = useDispatch();
  const initialItemsData = [["1", " ", " ", " ", " ", " "]];
  const [itemsData, setItemsData] = useState(initialItemsData);
  const [totalItems, setTotalItems] = useState(0);

  const toWords = new ToWords();

  var data = useSelector((state) => state.userdata);
  var userData = data.getData;
  const [signature, setSignature] = useState(userData?.signature);
  var userName = data.getData && data.getData.userName;
  var userMobileNo =
    data.getData && data.getData
      ? data.getData.mobile.number
      : data.getData.email;
  const [UserDetails, setUserDetails] = useState({
    userName: userName,
    userMobileNo: userMobileNo,
  });
  var leadCustomerName = useSelector(
    (state) => state.selectedLead[0].customerName
  );
  const [genratedDate, setGeneratedDate] = useState(new Date());
  const [customer, setCustomer] = useState(leadCustomerName);
  const [otherCharges, setOtherCharges] = useState("");
  ////gst

  const [cgstPercentage, setCgstPercentage] = useState(DEFAULT_CGST_PERCENTAGE);
  const [cgstAmount, setCgstAmount] = useState(DEFAULT_CGST_AMOUNT);
  const [sgstPercentage, setSgstPercentage] = useState(DEFAULT_SGST_PERCENTAGE);
  const [sgstAmount, setSgstAmount] = useState(DEFAULT_SGST_AMOUNT);
  const [igstPercentage, setIgstPercentage] = useState(DEFAULT_IGST_PERCENTAGE);
  const [igstAmount, setIgstAmount] = useState(DEFAULT_IGST_AMOUNT);
  const [includeGST, setIncludeGST] = useState(true);
  const [letterhead, setLetterhead] = useState("BRSPL");

  const [prevGSTValues, setPrevGSTValues] = useState({
    cgst: "",
    sgst: "",
    igst: "",
  });

  const [subtotal, setSubtotal] = useState();
  const [totalInWords, setTotalInWords] = useState("");
  const [region, setRegion] = useState(DEFAULT_REGION);
  const [quotationValue, setQuotationValue] = useState(
    props.list.transactionAmount ? props.list.transactionAmount.amount : ""
  );
  const [gstBreakUps, setGstBreakUps] = useState([]);
  //final calculations
  function calculateTotalItemsCount(itemsData) {
    return itemsData.length;
  }
  //subtotal
  function calculateSubTotalAmount(itemsData) {
    let subTotalAmount = 0;
    itemsData.forEach((row) => {
      const rowAmount = parseFloat(row[5]);
      if (!isNaN(rowAmount)) {
        subTotalAmount += rowAmount;
      }
    });

    return subTotalAmount;
  }

  useEffect(() => {
    if (signature) {
      setShowSignature(true);
    } else {
      setShowSignature(false);
    }
  }, [signature]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let payload = {
          leadID: selectedLead?.external_id,
        };
        const data = await getQuotationGenId(payload);
        if (data.status === "200") {
          setQuotationNo(data.data);
        } else if (data.status === "500") {
          toast.error("something went wrong!");
        }
      } catch (error) {
        toast.error("something went wrong!");
      }
    };

    fetchData();
  }, [selectedLead]);

  const calculateGST = (subtotal, percentage) => {
    const gst = (parseFloat(subtotal) * parseFloat(percentage)) / 100;
    return gst.toFixed(2);
  };

  const handleIncludeGSTChange = (include) => {
    setIncludeGST(include);
    if (!include) {
      // Store current values
      setPrevGSTValues({
        cgst: cgstPercentage,
        sgst: sgstPercentage,
        igst: igstPercentage,
      });
      // Set GST values to 0
      handleCgstChange("0");
      handleSgstChange("0");
      handleIgstChange("0");
    } else {
      // Restore previous values
      handleCgstChange(prevGSTValues.cgst);
      handleSgstChange(prevGSTValues.sgst);
      handleIgstChange(prevGSTValues.igst);
    }
  };

  useEffect(() => {
    const totalItemsCount = calculateTotalItemsCount(itemsData);
    const subTotalAmount = calculateSubTotalAmount(itemsData);
    const otherChargesIncluded = otherCharges;
    const calculateCgst = calculateGST(subTotalAmount, cgstPercentage);
    setCgstAmount(calculateCgst);
    const calculateSgst = calculateGST(subTotalAmount, sgstPercentage);
    setSgstAmount(calculateSgst);
    const calculateIgst = calculateGST(subTotalAmount, igstPercentage);
    setIgstAmount(calculateIgst);

    let totalAmount = 0;
    let gst =
      parseFloat(calculateCgst) +
      parseFloat(calculateSgst) +
      parseFloat(calculateIgst);

    if (otherChargesIncluded !== "") {
      totalAmount =
        parseFloat(subTotalAmount) +
        parseFloat(gst) +
        parseFloat(otherChargesIncluded);
    } else {
      totalAmount = parseFloat(subTotalAmount) + parseFloat(gst);
    }
    setTotalItems(totalItemsCount);
    setSubtotal(formatIndianCurrency(subTotalAmount));
    setQuotationValue(totalAmount.toFixed(2));
    setTotalInWords(quotationValueInWords(totalAmount, quotationValue));

    setGstAmount(gst);
    setGstBreakUps([
      {
        cgstPercentage: cgstPercentage,
        cgstAmount: calculateCgst,
      },
      {
        sgstPercentage: sgstPercentage,
        sgstAmount: calculateSgst,
      },
      {
        igstPercentage: igstPercentage,
        igstAmount: calculateIgst,
      },
    ]);
  }, [itemsData, otherCharges, cgstPercentage, sgstPercentage, igstPercentage]);

  const handleQuotationSubChange = (e) => {
    setQuotationSubject(e.target.value);
  };

  const confirmOpenDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleDeleteRow = (rowIndex) => {
    setSelectedRow(rowIndex);
    confirmOpenDialog();
  };

  const confirmOpenNoteDialog = () => {
    setIsConfirmNoteDialogOpen(true);
  };

  const handleDeleteNote = (index) => {
    setSelectedNoteRow(index);
    confirmOpenNoteDialog();
  };

  const confirmCloseDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const confirmAction = () => {
    const updatedData = [...itemsData];
    updatedData.splice(selectedRow, 1);
    setItemsData(updatedData);
    setSelectedRow();
  };

  const confirmNoteAction = () => {
    const updatedData = [...notes];
    const newArray = updatedData.filter(
      (element, index) => index !== selectedNoteRow
    );
    setNotes(newArray);
    setSelectedNoteRow();
  };

  const confirmCloseNoteDialog = () => {
    setIsConfirmNoteDialogOpen(false);
  };

  const handleGenDate = (date) => {
    setGeneratedDate(date);
  };

  const calculateAmountForAitem = (rowIndex) => {
    const quantity = parseFloat(itemsData[rowIndex][2]);
    const ratePerQuantity = parseFloat(itemsData[rowIndex][4]);
    if (!isNaN(ratePerQuantity) && !isNaN(quantity)) {
      const amount = quantity * ratePerQuantity;
      itemsData[rowIndex][5] = amount;
      return formatIndianCurrency(amount);
    } else {
      itemsData[rowIndex][5] = "";
      return "";
    }
  };

  const handleCellChange = (value, rowIndex, colName) => {
    const updatedData = [...itemsData];
    //     if (colName === 1) {
    //       const capitalizedValue = value.toUpperCase();
    //       updatedData[rowIndex][colName] = capitalizedValue;
    // }
    if (colName === 2) {
      const onlyNumbers = /^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/;
      if (onlyNumbers.test(value)) {
        updatedData[rowIndex][colName] = value;
      } else {
        updatedData[rowIndex][colName] = "";
      }
    } else if (colName === 3) {
      const allowedCharacters = /^[a-zA-Z /]*$/;
      if (allowedCharacters.test(value)) {
        updatedData[rowIndex][colName] = value;
      } else {
        updatedData[rowIndex][colName] = "";
      }
    } else if (colName === 4) {
      const parsedValue = parseFloat(value);
      const onlyNumbers = /^\d{1,10}(\.\d{1,2})?$/;
      if (onlyNumbers.test(parsedValue)) {
        updatedData[rowIndex][colName] = value;
      } else if (isNaN(parsedValue)) {
        updatedData[rowIndex][colName] = " ";
      } else {
        var roundedValue = parsedValue.toFixed(2);
        updatedData[rowIndex][colName] = roundedValue;
      }
    } else if (colName === 5) {
      const parsedValue = parseFloat(value);
      updatedData[rowIndex][colName] = !isNaN(parsedValue) ? parsedValue : "";
    } else {
      updatedData[rowIndex][colName] = value;
    }
    setItemsData(updatedData);
  };

  const handleAddRow = () => {
    const prevRow = itemsData[itemsData.length - 1];
    if (!prevRow) {
      setItemsData([initialItemsData[0]]);
    } else {
      const isRequiredFieldsEmpty = [1, 2, 3, 4].some(
        (columnName) => !prevRow[columnName]
      );
      if (isRequiredFieldsEmpty) {
        toast.error(
          "Required fields (Item Details , Quantity , Unit , Rate/Item)"
        );
      } else {
        setItemsData((prevData) => [...prevData, initialItemsData[0]]);
      }
    }
  };

  const handlePercentageChange = (
    gstType,
    newValue,
    setPercentage,
    setAmount
  ) => {
    const isValidInput =
      /^\d{0,3}(\.\d{0,2})?$/.test(newValue) &&
      parseFloat(newValue) >= 0 &&
      parseFloat(newValue) <= 100;
    if (isValidInput || newValue === "") {
      setPercentage(newValue);
      const calculatedAmount =
        (parseFloat(subtotal) * parseFloat(newValue)) / 100;
      setAmount(calculatedAmount);
      if (newValue !== "") {
        if (gstType === "cgst" || gstType === "sgst") {
          setIgstPercentage("0");
          setIgstAmount("0");
        } else if (gstType === "igst") {
          setCgstPercentage("0");
          setCgstAmount("0");
          setSgstPercentage("0");
          setSgstAmount("0");
        }
      }
    }
  };

  const handleCgstChange = (newValue) => {
    handlePercentageChange("cgst", newValue, setCgstPercentage, setCgstAmount);
  };

  const handleSgstChange = (newValue) => {
    handlePercentageChange("sgst", newValue, setSgstPercentage, setSgstAmount);
  };

  const handleIgstChange = (newValue) => {
    handlePercentageChange("igst", newValue, setIgstPercentage, setIgstAmount);
  };

  const handleThankOfProposal = (e) => {
    setThankofProposal(e.target.value);
  };

  const roundOffTotal = () => {
    const roundedAmount = Math.round(quotationValue);
    const formattedAmount = roundedAmount.toFixed(2);
    setQuotationValue(formattedAmount);
    const inrWords = toWords.convert(roundedAmount, { currency: "true" });
    const [beforeRupees, afterRupees] = inrWords.split("Rupees ");
    const modifiedText = `Rupees ${beforeRupees}${afterRupees}`;
    setTotalInWords(modifiedText);
  };

  const addTextField = () => {
    const lastElement = notes[notes.length - 1];
    if (lastElement !== "") {
      setNotes([...notes, ""]);
    } else {
      toast.error("Please fill in the previous note first!");
    }
  };

  const handleTextFieldChange = (index, value) => {
    const newFields = [...notes];
    newFields[index] = value;
    setNotes(newFields);
    // const lastElement = newFields[newFields.length - 1];
  };

  const handleupdateImage = (e) => {
    setFileData(e);
  };

  const handleEditSignature = () => {
    setShowSignature(false);
  };

  const handleCloseSignature = () => {
    setShowSignature(true);
  };

  const actionOnLead = async (res) => {
    let bodyData = {
      external_id: props.list.external_id,
      leadData: {
        quotation: {
          folderName:
            res.quoteLoc.folderName ||
            res.quoteLoc.folder ||
            res.quoteLoc.FolderName,
          fileName: res.quoteLoc.fileName || res.quoteLoc.filename,
          quoteStatus: "Pdf Generated",
          quotationNo: quotationNo,
          totalAmount: {
            region: region,
            amount: quotationValue,
          },
          description: quotationSubject,
          referenceName: "quote/" + props.list.external_id,
          customerComments: "",
          genratedDate: genratedDate,
        },
        status: "Quotation Generated",
      },
      mpr_id: props.list.Mpr_id,
    };
    const response = await assignLead(bodyData);
    if (response?.status === "200") {
      console.log("success");
    } else if (response?.status === "401") {
      toast.error(response.message);

      setTimeout(() => {
        dispatch({ type: "LOG_OUT" });
        localStorage.clear();
      }, 2000);
    } else {
      toast.error("Something went wrong! Please try again.");
    }
  };

  const generateQuotation = async (Data) => {
    try {
      const response = await quotationGeneration(Data);
      if (response.status === "200") {
        actionOnLead(response);
        toast.success("Quotation pdf generated.");
      } else if (response?.status === "401") {
        toast.error(response.message);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      } else {
        toast.error("Something went wrong");
      }

      setTimeout(() => {
        props.close();
        setShowLoader(false);
      }, 2000);
    } catch (error) {
      toast.error("Something went wrong");
      setTimeout(() => {
        props.close();
        setShowLoader(false);
      }, 2000);
    }
  };

  const handleSubmit = () => {
    const items = itemsData.map((item) => {
      return {
        itemNo: item[0],
        itemDetails: item[1],
        quantity: item[2],
        unit: item[3],
        ratePerUnit: item[4],
        amount: item[5],
      };
    });
    const quotationDetails = {
      genratedDate,
      customer,
      quotationSubject,
      // quotationContent,
      items,
      thankofProposal,
      totalItems,
      subtotal,
      gstAmount,
      gstBreakUps,
      otherCharges,
      notes,
      region,
      quotationNo,
      quotationValue,
      totalInWords,
      UserDetails,
      includeGST,
      userSignature: signature,
      letterhead,
    };
    let Data = {
      external_id: props.list.external_id,
      quotationDetails,
      status: "Quotation Generated",
    };

    setShowLoader(true);
    generateQuotation(Data);
  };

  const handleChange = (type) => {
    if (
      fileData.type === "image/png" ||
      fileData.type === "image/jpeg" ||
      fileData.type === "image/jpg"
    ) {
      setShowSignatureLoader(true);

      var filetype = fileData.type.split("/");
      var baseData = fileData.base64.split(",");
      let getTokenData = {
        abbrevation: data.getData.abbrevation,
        fileType: "assets",
        folderName: props.list.external_id,
        expireLimt: 1800,
        extension: filetype.length > 0 ? filetype[1] : "",
      };
      fileupload(
        baseData.length > 0 ? baseData[1] : "",
        getTokenData,
        fileData.type
      ).then((response) => {
        if (response?.status === "200") {
          setShowSignatureLoader(false);
          let body = {
            thisItem: type,
            thisImage: {
              fileName: response.fileName || response.filename,
              folderName:
                response.folderName || response.folder || response.FolderName,
            },
            thisLead: props.list.external_id,
            thisForm: "quotation",
          };
          setSignature({
            fileName: response.fileName,
            folderName: response.folderName,
          });
          addSignature(body).then((resp) => {
            if (resp?.status === "200") {
              toast.success(resp.message);

              setShowSignature(true);
              setShowSignatureLoader(false);
            } else if (resp?.status === "401") {
              toast.error(resp.message);

              setShowSignatureLoader(false);
              setTimeout(() => {
                dispatch({ type: "LOG_OUT" });
                localStorage.clear();
              }, 2000);
            } else {
              toast.error(resp.message);

              setShowSignatureLoader(false);
            }
          });
        }
      });
    } else {
      toast.error("Please upload Image");
    }
  };

  return (
    <Box className={classes.styledCard} p={2}>
      <form autoComplete="off">
        <QuotationHeader
          quotationNo={quotationNo}
          setQuotationNo={setQuotationNo}
          genratedDate={genratedDate}
          handleGenDate={handleGenDate}
          customer={customer}
          setCustomer={setCustomer}
          quotationSubject={quotationSubject}
          handleQuotationSubChange={handleQuotationSubChange}
          classes={classes}
          letterhead={letterhead}
          setLetterhead={setLetterhead}
        />

        <ItemsTable
          itemsData={itemsData}
          setItemsData={setItemsData}
          handleCellChange={handleCellChange}
          handleDeleteRow={handleDeleteRow}
          handleAddRow={handleAddRow}
          calculateAmountForAitem={calculateAmountForAitem}
          quotationColumns={quotationColumns}
          totalItems={itemsData.length}
        />

        <GSTCalculations
          subtotal={subtotal}
          setSubtotal={setSubtotal}
          cgstPercentage={cgstPercentage}
          handleCgstChange={handleCgstChange}
          cgstAmount={cgstAmount}
          sgstPercentage={sgstPercentage}
          handleSgstChange={handleSgstChange}
          sgstAmount={sgstAmount}
          igstPercentage={igstPercentage}
          handleIgstChange={handleIgstChange}
          igstAmount={igstAmount}
          gstAmount={gstAmount}
          otherCharges={otherCharges}
          setOtherCharges={setOtherCharges}
          includeGST={includeGST}
          setIncludeGST={handleIncludeGSTChange}
          classes={classes}
        />

        <FinalAmount
          region={region}
          setRegion={setRegion}
          quotationValue={quotationValue}
          roundOffTotal={roundOffTotal}
          totalInWords={totalInWords}
          classes={classes}
        />

        <Notes
          notes={notes}
          handleTextFieldChange={handleTextFieldChange}
          handleDeleteNote={handleDeleteNote}
          addTextField={addTextField}
          thankofProposal={thankofProposal}
          handleThankOfProposal={handleThankOfProposal}
          setNotes={setNotes}
          includeGST={includeGST}
          classes={classes}
        />

        <Grid container className={classes.alignButtom}>
          <Grid item lg={6} md={2} xs={4} sm={4}></Grid>
          <Grid item lg={6} md={2} xs={4} sm={4}>
            <Grid style={{ textAlign: "right" }}>
              <Grid>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontStyle: "italic" }}
                >
                  Contact Person
                </Typography>
              </Grid>
              <Grid>
                <Grid>
                  <Typography>{UserDetails.userName}</Typography>
                </Grid>
                <Grid>
                  <Typography>{UserDetails.userMobileNo}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={2} xs={4} sm={4}></Grid>
          <Grid item lg={6} md={10} xs={8} sm={8}>
            {/* {showSignature ? (
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle2"> Signature: </Typography>
                <IconButton
                  onClick={() => getFiles(signature)}
                  target="_blank"
                  className={classes.collapseIcon}
                  size="large"
                >
                  <ImageIcon />
                </IconButton>
                <IconButton onClick={handleEditSignature}>
                  <EditIcon className={classes.collapseIcon} size="large" />
                </IconButton>
              </Grid>
            ) : (
              <Grid style={{ display: "flex", justifyContent: "end" }}>
                <Grid item lg={10} md={8} sm={12} xs={12}>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="p">Upload Signature</Typography>
                    <IconButton onClick={handleCloseSignature}>
                      <CancelIcon className="close" />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ display: "flex", marginTop: "10px" }}
                  >
                    <FormControl variant="standard">
                      <FileBase64
                        className={classes.input}
                        multiple={false}
                        onDone={(e) => handleupdateImage(e)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "right" }}
                  >
                    {showSignatureLoader ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => handleChange("Signature")}
                        className={classes.button}
                      >
                        {t("common_upload")}
                      </Button>
                    )}
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                  >
                    <Divider
                      style={{ backgroundColor: "black", height: "1px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )} */}

            <SignatureUploader
              handleEditSignature={handleEditSignature}
              showSignature={showSignature}
              handleCloseSignature={handleCloseSignature}
              handleupdateImage={handleupdateImage}
              signature={signature}
              handleChange={handleChange}
              showSignatureLoader={showSignatureLoader}
              classes={classes}
            />
          </Grid>
        </Grid>
        <Box style={{ marginTop: "2rem", float: "right" }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
            disabled={
              quotationValue === "0.00" ||
              quotationNo === "" ||
              notes[0] === "" ||
              showLoader
            }
          >
            {showLoader ? (
              <>
                Generating pdf...
                <CircularProgress
                  style={{ marginLeft: "4px" }}
                  size={26}
                />{" "}
              </>
            ) : (
              "Generate PDF"
            )}
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => props.close()}
            disabled={showLoader}
          >
            Close
          </Button>
        </Box>
      </form>
      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={confirmCloseDialog}
        onConfirm={confirmAction}
        title="Delete"
        message="Are you sure you want to delete this item?"
        confirmText="Confirm"
      />
      <ConfirmationDialog
        open={isConfirmNoteDialogOpen}
        onClose={confirmCloseNoteDialog}
        onConfirm={confirmNoteAction}
        title="Delete"
        message="Are you sure you want to delete this Note?"
        confirmText="Confirm"
      />
    </Box>
  );
};
export default CreateQuotation;
