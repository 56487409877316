import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Chip,
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import Table from "../../../components/Table/Table";
import LeaveRequestForm from "./LeaveRequestForm";
import { actionOnLeaveRequest, getAllLeaveRequests } from "../apis";
import LeaveActionModal from "./LeaveActionModal";
import {
  TabPanel,
  a11yProps,
  leaveTableHeader,
} from "../../internalForms/components/config/tableHelper";
import { tableOptions } from "../../internalForms/components/config/tableConfig";
import toast from "react-hot-toast";

export default function LeaveRequestsTable({ leaveData }) {
  const [value, setValue] = useState(0);
  const data = useSelector((state) => state.userdata.getData);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const [pageData, setPageData] = useState({
    page: 1,
    limit: 10,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 20, 50]);

  useEffect(() => {
    const maxRowsPerPage = totalPages || 10;
    const dynamicRowsPerPageOptions = [10, 20, 50].filter(
      (option) => option <= maxRowsPerPage
    );
    if (!dynamicRowsPerPageOptions.includes(maxRowsPerPage)) {
      dynamicRowsPerPageOptions.push(maxRowsPerPage);
    }
    setRowsPerPageOptions(dynamicRowsPerPageOptions);
  }, [totalPages]);

  let tabData = [];
  let myRequests = [];
  let allRequests = [];
  let pendingRequests = [];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (
    data &&
    data.roles.some((item) => "user" === item) &&
    !data.roles.some((item) => "Approver" === item)
  ) {
    tabData = [{ label: "My Requests", index: 0, name: "", data: myRequests }];
  } else {
    tabData = [
      { label: "All", index: 0, name: "", data: allRequests },
      { label: "Pending", index: 1, name: "", data: pendingRequests },
    ];
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  async function fetchLeaveRequests(data) {
    setFetchLoader(true);
    try {
      const allLeaveRequests = await getAllLeaveRequests(data);
      if (
        allLeaveRequests.status === "200" &&
        allLeaveRequests.data.length > 0
      ) {
        setLeaveRequests(allLeaveRequests.data);
        setTotalPages(allLeaveRequests.totalCount);
      } else {
        setLeaveRequests([]);
      }
      setFetchLoader(false);
    } catch (error) {
      setFetchLoader(false);
    }
  }

  useEffect(() => {
    setIsModalOpen(false);
    fetchLeaveRequests(pageData);
  }, [showForm, pageData]);

  const handleStatusChange = (newStatus, rejectionReason) => {
    if (
      newStatus === selectedData.status &&
      selectedData.reasonForRejection === rejectionReason
    ) {
      return;
    }
    takeActionOnLeaveRequest(selectedData, newStatus, rejectionReason);
  };

  async function takeActionOnLeaveRequest(
    formData,
    newStatus,
    rejectionReason
  ) {
    try {
      let updatedFormData = {
        ...formData,
        status: newStatus,
        raisedBy: formData[0]?._id,
      };

      if (newStatus === "Rejected" && rejectionReason !== "") {
        updatedFormData.reasonForRejection = rejectionReason;
      }

      const result = await actionOnLeaveRequest(updatedFormData);
      if (result.status === "200") {
        toast.success("Successfully updated!");
        fetchLeaveRequests(pageData);
      }
    } catch (error) {
      toast.error("An error occured try again");
    }
  }

  const handleToggleView = (data) => {
    setShowForm(data);
  };

  const handleRowClick = (rowData, rowMeta) => {
    let selectedId = rowData[0];

    let selectedLeave = leaveRequests?.filter(
      (leaveReq) => leaveReq?.leaveId === selectedId
    )[0];
    toggleModal();
    setSelectedData(selectedLeave);
  };

  return (
    <>
      <Box sx={{ minHeight: "100%" }}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            mb: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
            background: "linear-gradient(to right, #f8fafc, #ffffff)",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Leave Requests
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {showForm ? "Submit a new leave request" : ""}
                </Typography>
              </Box>
            </Stack>

            <Button
              variant={showForm ? "outlined" : "contained"}
              onClick={() => setShowForm((prev) => !prev)}
              sx={{
                textTransform: "none",
                minWidth: 135,
                boxShadow: showForm ? "none" : "sm",
              }}
            >
              {showForm ? "View Requests" : "New Request"}
            </Button>
          </Stack>
        </Paper>

        <Paper elevation={0}>
          {showForm ? (
            <Box sx={{ py: 2 }}>
              <LeaveRequestForm toggleView={handleToggleView} leaveData={leaveData}/>
            </Box>
          ) : (
            <>
              <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  sx={{
                    "& .MuiTab-root": {
                      textTransform: "none",
                      minWidth: 100,
                      fontWeight: 500,
                    },
                  }}
                >
                  {tabData.map((item, key) => (
                    <Tab
                      key={key}
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span>{item.label}</span>
                          {item.label === "Pending" &&
                            pendingRequests.length > 0 && (
                              <Chip
                                label={pendingRequests.length}
                                size="small"
                                color="primary"
                                sx={{ height: 20, minWidth: 20 }}
                              />
                            )}
                        </Stack>
                      }
                      {...a11yProps(item.index)}
                    />
                  ))}
                </Tabs>
              </Box>

              {leaveRequests &&
                leaveRequests.length > 0 &&
                leaveRequests.map((item, key) => {
                  const row = [
                    item?.leaveId,
                    moment(item?.createdAt).format("DD-MM-YYYY"),
                    item?.selectedLeaveType,
                    item?.noOfDays ? item?.noOfDays : "N/A",
                    item?.raisedBy[0]?.username,
                    item?.status,
                  ];
                  if (item?.status === "Pending") {
                    pendingRequests.push(row);
                  }
                  if (data?._id === item?.raisedBy[0]?._id) {
                    myRequests.push(row);
                  }
                  if (data?._id === item?.raisedBy[0]?._id) {
                  }
                  allRequests.push(row);
                })}

              {tabData.map((item, key) => {
                // Transform the leave request data
                const transformedData = item.data.map((row) => {
                  const [id, date, type, days, username, status] = row;
                  return [
                    id,
                    date,
                    type,
                    <Typography variant="body2">{days}</Typography>,
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {username}
                    </Typography>,
                    typeof status === "object" ? (
                      status
                    ) : (
                      <Chip
                        label={status}
                        size="small"
                        color={
                          status === "Approved"
                            ? "success"
                            : status === "Rejected"
                              ? "error"
                              : "warning"
                        }
                        sx={{ minWidth: 85, justifyContent: "center" }}
                      />
                    ),
                  ];
                });

                return (
                  <TabPanel value={value} index={item.index} key={key}>
                    <Table
                      data={transformedData}
                      header={leaveTableHeader}
                      name={""}
                      options={{
                        ...tableOptions(
                          fetchLoader,
                          rowsPerPageOptions,
                          setPageData,
                          handleRowClick,
                          "Leave Requests.csv",
                          totalPages,
                          pageData.page
                        ),
                        download: false,
                        tableBodyHeight: "550px",
                        textLabels: {
                          body: {
                            noMatch: fetchLoader ? (
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ py: 3 }}
                              >
                                <CircularProgress size={20} color="primary" />
                                <Typography>Loading...</Typography>
                              </Stack>
                            ) : (
                              <Typography color="text.secondary" sx={{ py: 3 }}>
                                No leave requests found
                              </Typography>
                            ),
                          },
                        },
                      }}
                    />
                  </TabPanel>
                );
              })}
            </>
          )}
        </Paper>
      </Box>

      <LeaveActionModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        data={selectedData}
        onStatusChange={handleStatusChange}
      />
    </>
  );
}
