import React from "react";
import { Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Grid,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Chip,
  Box,
  TextField,
  Tooltip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import dayjs from "dayjs";

const departments = [
  "IT",
  "Sales",
  "Logistics",
  "Accounts",
  "Marketing",
  "Operations",
  "Supply Chain",
  "Administration",
  "Quality Assurance",
  "Customer Support",
  "Human Resources",
  "Compliance & Legal",
  "Other",
];

const EmployeeForm = ({ control, errors, isDisabled }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Controller
            name="designation"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Designation*"
                variant="standard"
                fullWidth
                error={!!errors.designation}
                helperText={errors.designation?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Controller
            name="department"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <FormControl
                variant="standard"
                fullWidth
                error={!!errors.department}
              >
                <InputLabel id="departments-label">Departments*</InputLabel>
                <Select
                  {...field}
                  labelId="departments-label"
                  multiple
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          size="small"
                          sx={{ height: 24 }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {departments.map((dept) => (
                    <MenuItem key={dept} value={dept}>
                      {dept}
                    </MenuItem>
                  ))}
                </Select>
                {errors.department && (
                  <FormHelperText>{errors.department.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="dateOfJoiningBinbag"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <DatePicker
                {...field}
                value={value ? dayjs(value) : null}
                onChange={(newValue) => {
                  onChange(newValue ? newValue.toDate() : null);
                }}
                label="Date of Joining*"
                disableFuture
                slotProps={{
                  textField: {
                    variant: "standard",
                    fullWidth: true,
                    error: !!errors.dateOfJoiningBinbag,
                    helperText: errors.dateOfJoiningBinbag?.message,
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <DatePicker
                {...field}
                value={value ? dayjs(value) : null}
                onChange={(newValue) => {
                  onChange(newValue ? newValue.toDate() : null);
                }}
                label="Date of Birth*"
                disableFuture
                slotProps={{
                  textField: {
                    variant: "standard",
                    fullWidth: true,
                    error: !!errors.dateOfBirth,
                    helperText: errors.dateOfBirth?.message,
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="isTemparory"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                label="Temporary Employee"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="isFactoryEmployee"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={isDisabled}
                  />
                }
                label="Factory Employee"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="isActive"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={
                <Switch
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              }
              label={
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Account Status</Typography>
                  <Tooltip
                    title="When disabled, this user won't be able to login to the system or reset their password. Use this if the employee leaves the organization or you want to temporarily restrict system access."
                    arrow
                    placement="right"
                  >
                    <IconButton size="small" sx={{ padding: 0 }}>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />
          )}
        />
      </Grid>
    </LocalizationProvider>
  );
};

export default EmployeeForm;
