import { getFile } from "../../../LeadState";

export const FORM_FIELDS = {
  senderAddress: {
    id: "no1",
    label: "lead_form_label1",
    stateKey: "senderAdd",
  },
  senderAuth: {
    id: "no2",
    label: "lead_form_label2",
    stateKey: "senderAuthNo",
  },
  manifest: {
    id: "no3",
    label: "lead_form_label3",
    stateKey: "manifest",
  },
  transportAddress: {
    id: "no4",
    label: "lead_form_label4",
    stateKey: "transAdd",
  },
  vehicle: {
    id: "no5",
    label: "lead_form_label5",
    stateKey: "vehicle",
  },
  transportReg: {
    id: "no6",
    label: "lead_form_label6",
    stateKey: "TransReg",
  },
  vehicleReg: {
    id: "no7",
    label: "lead_form_label7",
    stateKey: "vehicleReg",
  },
  receiverAddress: {
    id: "no8",
    label: "lead_form_label8",
    stateKey: "receiverAdd",
  },
  receiverAuth: {
    id: "no9",
    label: "lead_form_label9",
    stateKey: "receiverAuth",
  },
  description: {
    id: "no10",
    label: "lead_form_label10",
    stateKey: "desc",
  },
};

export const getFiles = (file) => {
  let bodyData = {
    fileName: file.fileName || file.filename,
    folderName: file.folderName || file.folder || file.FolderName,
    expireLimt: 1000,
  };
  getFile(bodyData).then((response) => {
    window.open(response.reference, "_blank");
  });
};
