import { apiUrl } from "../data/config";
import { Buffer } from 'buffer';

export async function fileupload(formData, getTokenData, fileType) {
  let url = apiUrl + "/v1/fileinAWS";
  let getRef = apiUrl + "/v1/filegetAWS";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(getTokenData),
  }).then((response) => {
    if (response.ok) {
      return response.json().then((data) => {
        return fetch(data.reference.url, {
          method: "PUT",
          headers: {
            ContentType: fileType,
            "Content-Type": fileType,
            ContentEncoding: "base64",
            "Content-Encoding": "base64",
          },
          body: Buffer.from(formData, "base64"),
        }).then((res) => {
          if (res.ok) {
            return fetch(getRef, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
              },
              body: JSON.stringify({
                fileName: data.reference.fileName || data.reference.filename,
                folderName:
                  data.reference.folderName ||
                  data.reference.folder ||
                  data.reference.FolderName,
                expireLimt: 1800,
              }),
            }).then((list) => {
              return list.json().then((returnData) => {
                return {
                  status: "200",
                  message: "File Uploaded SuccessFully",
                  fileName: data.reference.fileName || data.reference.filename,
                  reference: returnData.reference,
                  folderName:
                    data.reference.folderName ||
                    data.reference.folder ||
                    data.reference.FolderName,
                };
              });
            });
          } else {
            return {
              status: "500",
              message: "Something went wrong",
            };
          }
        });
      });
    }
  });
}

export async function getFile(data) {
  let url = apiUrl + "/v1/filegetAWS";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}
