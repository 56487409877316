// utils/sidebarActions.js
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Looks6Icon from "@mui/icons-material/Looks6";

// Define action configurations
export const getActionConfigs = (t) => ({
  leadDetails: {
    title: "Lead Details",
    icon: VisibilityIcon,
    key: "leadDetails",
  },
  assign: {
    title: `${t("common_assign")} ${t("common_lead")}`,
    icon: PersonAddIcon,
    key: "assign",
  },
  reject: {
    title: `${t("common_reject")} ${t("common_lead")}`,
    icon: CancelIcon,
    key: "reject",
  },
  inspection: {
    title: t("mpr_inspection"),
    icon: HowToRegIcon,
    key: "inspection",
  },
  quotations: {
    title: t("common_quotations"),
    icon: DescriptionIcon,
    key: "quotations",
  },
  payment: {
    title: 'Payment Request',
    icon: CreditCardIcon,
    key: "payment",
  },
  invoice: {
    title: t("mpr_invoice"),
    icon: ReceiptIcon,
    key: "invoice",
  },
  materialPickup: {
    title: t("common_materialpickup"),
    icon: LocalShippingIcon,
    key: "materialPickup",
  },
  form6: {
    title: `${t("common_form")}-6`,
    icon: Looks6Icon,
    key: "form6",
  },
  close: {
    title: t("common_close"),
    icon: CancelPresentationIcon,
    key: "close",
  },
});

// Get actions based on role and permissions
export const getActions = ({
  t,
  viewDetails,
  loginData,
  show,
  reject,
  inspection,
  quotation,
  payment,
  invoice,
  schedule,
  formsix,
  closeLead,
  close_disable,
  assign_disable,
  won_disable,
  form6Button,
}) => {
  const configs = getActionConfigs(t);

  // Define base actions
  const baseActions = [
    {
      ...configs.leadDetails,
      func: viewDetails,
      disable: false,
    },
  ];

  // Define admin actions
  const adminActions = [
    {
      ...configs.assign,
      func: show,
      disable: close_disable,
    },
    {
      ...configs.reject,
      func: reject,
      disable: close_disable,
    },
  ];

  // Define common actions
  const commonActions = [
    {
      ...configs.inspection,
      func: inspection,
      disable: assign_disable,
    },
    {
      ...configs.quotations,
      func: quotation,
      disable: assign_disable,
    },
    {
      ...configs.payment,
      func: payment,
      disable: won_disable,
    },
    // {
    //   ...configs.invoice,
    //   func: invoice,
    //   disable: won_disable,
    // },
    {
      ...configs.materialPickup,
      func: schedule,
      disable: won_disable,
    },
    {
      ...configs.form6,
      func: formsix,
      disable: form6Button,
    },
    {
      ...configs.close,
      func: closeLead,
      disable: close_disable,
    },
  ];

  // Return actions based on user role
  return loginData?.getData?.roles?.includes("user")
    ? [...baseActions, ...commonActions]
    : [...baseActions, ...adminActions, ...commonActions];
};

// Helper function to create icon element
export const createIcon = (IconComponent) => {
  return IconComponent ? <IconComponent /> : null;
};
