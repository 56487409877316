import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { StyledBadge } from "./styles";
import { Inventory } from "@mui/icons-material";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  "& .MuiCardHeader-root": {
    backgroundColor: alpha(theme.palette.primary.main, 0.03),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiCardContent-root": {
    padding: theme.spacing(0),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1.5),
  "&.MuiTableCell-head": {
    backgroundColor: alpha(theme.palette.primary.main, 0.02),
    fontWeight: 600,
  },
}));

const SectionTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.primary.main, 0.02),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const MaterialList = ({ data, title }) => {

  return (
    <StyledCard variant="outlined">
      <CardHeader
        title={
          <Typography variant="h6" color="textSecondary">
            {title}
          </Typography>
        }
      />
      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>File</StyledTableCell>
                <StyledTableCell>View</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>
                      <Typography variant="body2" color="textPrimary">
                        {item[0]}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="body2" color="textSecondary">
                        {item[1]}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={2}>
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="body2"
                    >
                      No files uploaded
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </StyledCard>
  );
};

const MaterialDetails = ({ list, martialList, goodsList }) => {
  const { t } = useTranslation();

  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 2,
        overflow: "hidden",
        backgroundColor: "background.paper",
      }}
    >
      <SectionTitle>
        <StyledBadge
          badgeContent={list.MPRDetails && list.MPRDetails.slot.slots.length}
          color="primary"
          sx={{
            "& .MuiBadge-badge": {
              fontSize: "0.75rem",
              height: "20px",
              minWidth: "20px",
            },
          }}
        >
          <Inventory color="primary" />
        </StyledBadge>
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          {t("mpr_material_details")}
        </Typography>
      </SectionTitle>

      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MaterialList data={martialList} title={t("common_mpr_list")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MaterialList data={goodsList} title={t("common_mpr_goods_list")} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default MaterialDetails;
