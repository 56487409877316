import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const AcceptQuotationDialog = ({
  open,
  onClose,
  onAccept,
  loading,
  comments,
  onCommentsChange,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure you want to accept this quotation?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2, color: "text.primary" }}>
          Please confirm that:
          <ul>
            <li>The customer has reviewed and accepted this quotation</li>
            <li>You have received formal confirmation from the customer</li>
            <li>All terms and conditions have been agreed upon</li>
          </ul>
        </DialogContentText>
        <Typography variant="subtitle2" color="danger.main" sx={{ mb: 2 }}>
          This action cannot be undone. Once accepted, the quotation will be
          finalized.
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          label="Notes"
          placeholder="eg: Accepted via email"
          fullWidth
          multiline
          rows={4}
          value={comments}
          onChange={(e) => onCommentsChange(e.target.value)}
          required
          helperText="Please include details about how the customer confirmed their acceptance."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          {t("common_cancel")}
        </Button>
        <Button
          onClick={onAccept}
          disabled={loading || comments.trim() === ""}
          color="primary"
          variant="contained"
        >
          {loading ? <CircularProgress size={24} /> : t("common_accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptQuotationDialog;
