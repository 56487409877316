import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
  },
  cardWrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  badge: {
    position: "absolute",
    top: theme.spacing(0.1),
    right: theme.spacing(1),
    zIndex: 1,
  },
  customBadge: {
    "& .MuiBadge-badge": {
      minWidth: "30px",
      padding: "0 6px",
      height: "22px",
      borderRadius: "11px",
      fontSize: "0.6rem",
      fontWeight: "bold",
      whiteSpace: "nowrap",
    },
  },
  card: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.3s, box-shadow 0.3s",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    "&:hover": {
      boxShadow: theme.shadows[4],
    },
  },
  cardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    fontSize: 40,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  searchBar: {
    marginBottom: theme.spacing(3),
    width: "100%",
    maxWidth: 500,
  },
  chipContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  alertContainer: {
    marginBottom: theme.spacing(1),
  },
  celebrationCard: {
    marginBottom: theme.spacing(2),
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
    },
  },
  iconContainer: {
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  messageContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: 4,
    top: 4,
    color: "rgba(0, 0, 0, 0.5)",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.7)",
    },
  },
}));
