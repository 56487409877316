import React, { useState, useEffect } from "react";
import {
  Paper,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  CircularProgress,
  Link,
  Typography,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FileBase64 from "react-file-base64";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DeleteIcon from "@mui/icons-material/Delete";

import { fileupload } from "../../apis/globalApi";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledFileInput = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const TruncatedCell = styled(TableCell)({
  maxWidth: 100,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const UploadFiles = ({
  fileUploadData,
  setIsUploading,
  fileUploadType,
  title,
}) => {
  const { t } = useTranslation();
  const formData = { files: [] };
  const [uploadedFile, setUploadedFile] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [formatError, setFormatError] = useState({});

  var loggedInDatas = useSelector((state) => state.userdata);

  const sendFileUploadData = (data) => {
    fileUploadData(data);
  };

  const handleChange = (e, type) => {
    type === "FilesUpload" ? setShowLoader(true) : setShowLoader(false);
    var filetype = e.type.split("/");
    var baseData = e.base64.split(",");
    const supportedFormats = ["application/pdf", "image/png", "image/jpeg"];
    if (!supportedFormats.includes(e.type)) {
      setFormatError({ error: true, message: "Unsupported file format" });
      setShowLoader(false);
      return;
    } else {
      setFormatError({ error: false, message: "" });
    }

    let getTokenData = {
      abbrevation: loggedInDatas.getData.abbrevation,
      fileType: fileUploadType,
      folderName: `${fileUploadType}_${new Date().toISOString()}`,
      expireLimt: 1800,
      extension: filetype.length > 0 ? filetype[1] : "",
    };

    fileupload(
      baseData.length > 0 ? baseData[1] : "",
      getTokenData,
      e.type
    ).then((response) => {
      if (response.status === "200" && type === "FilesUpload") {
        formData?.files.push({
          fileName: response.fileName || response.filename,
          ref: response.reference,
          folderName:
            response.folderName || response.folder || response.FolderName,
        });
        setUploadedFile((prevUploadedFile) => [
          ...prevUploadedFile,
          {
            fileName: response.fileName || response.filename,
            ref: response.reference,
            folderName:
              response.folderName || response.folder || response.FolderName,
          },
        ]);

        setShowLoader(false);
      }
    });
  };
  useEffect(() => {
    GenerateRows();
    sendFileUploadData(uploadedFile);
    setIsUploading(showLoader);
  }, [uploadedFile, showLoader]);

  const handleRemove = (index) => {
    setUploadedFile((prevUploadedFile) => {
      const updatedFiles = [...prevUploadedFile];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const GenerateRows = () => {
    return uploadedFile?.map((item, key) => {
      if (Object.keys(item).length === 0) {
        return null;
      }

      return (
        <TableRow key={key}>
          <TableCell>{item.fileName}</TableCell>
          <TableCell>
            <Link href={item.ref} target="_blank" underline="hover">
              <AttachmentIcon />
            </Link>
          </TableCell>
          <TableCell>
            <Button onClick={() => handleRemove(key)}>
              <Typography
                style={{
                  fontSize: "12px",
                  textTransform: "capitalize",
                  marginLeft: "-16px",
                }}
              >
                Remove
              </Typography>
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <StyledPaper elevation={1}>
      <Typography variant="h6" gutterBottom>
        {title || "Upload files"}
      </Typography>

      <StyledFileInput>
        <FileBase64
          multiple={false}
          onDone={(e) => handleChange(e, "FilesUpload")}
        />
        <Typography variant="caption" display="block" gutterBottom>
          {t("Supported formats: PDF, PNG or JPEG.")}
        </Typography>
        {formatError?.error && (
          <Typography variant="caption" color="error" display="block">
            {formatError?.message}
          </Typography>
        )}
      </StyledFileInput>

      {showLoader && (
        <Box display="flex" alignItems="center" gap={1} mb={2}>
          <CircularProgress size={20} />
          <Typography variant="body2">Uploading...</Typography>
        </Box>
      )}

      {uploadedFile?.length > 0 && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("common_file_name")}</TableCell>
              <TableCell>{t("View")}</TableCell>
              <TableCell>{t("common_action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadedFile.map((item, key) => (
              <TableRow key={key}>
                <TruncatedCell>
                  <Tooltip title={item.fileName}>
                    <span>{item.fileName}</span>
                  </Tooltip>
                </TruncatedCell>
                <TableCell>
                  <Link href={item.ref} target="_blank" underline="hover">
                    <AttachmentIcon />
                  </Link>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleRemove(key)}
                    size="small"
                    color="error"
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </StyledPaper>
  );
};

export default UploadFiles;
