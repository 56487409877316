import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  DialogActions,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { addCustomerContact } from "../CustomerState";
import toast, { Toaster } from "react-hot-toast";

const Contacts = ({
  userData,
  customer,
  showContactsDialog,
  setShowContactsDialog,
}) => {
  const { UnassignedUsers, Customer } = customer;
  const [showAddForm, setShowAddForm] = useState(false);
  const [newContact, setNewContact] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setShowContactsDialog(false);
    setShowAddForm(false);
    setNewContact({ name: "", email: "", phone: "" });
    setErrors({ name: "", email: "", phone: "" });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: "", email: "", phone: "" };

    // Name is always required
    if (!newContact.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    // Check if at least one contact method is provided
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const hasValidEmail = emailRegex.test(newContact.email);
    const hasValidPhone = /^\d{10}$/.test(newContact.phone);

    if (!hasValidEmail && !hasValidPhone) {
      newErrors.email = "Either email or phone number is required";
      newErrors.phone = "Either email or phone number is required";
      isValid = false;
    } else {
      // If email is provided, validate it
      if (newContact.email && !hasValidEmail) {
        newErrors.email = "Invalid email address";
        isValid = false;
      }
      // If phone is provided, validate it
      if (newContact.phone && !hasValidPhone) {
        newErrors.phone = "Phone number must be exactly 10 digits";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    setNewContact({ ...newContact, [field]: value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleAddContact = async () => {
    if (!validateForm()) return;

    setLoader(true);
    const data = {
      user: {
        userName: newContact.name,
        referenceId: customer?.Customer?._id,
        branchId: userData?.BranchInfo?._id,
        email: newContact.email || "emailNotAvail@binbag.in",
        mobile: {
          countryCode: "+91",
          number: newContact.phone,
        },
        isActive: true,
        createdBy: "admin",
        updatedBy: "admin",
        isPrimary: true,
        isTemparory: false,
        type: "Customer",
      },
    };

    try {
      const response = await addCustomerContact(data);
      if (response.status === "200") {
        toast.success("Contact added successfully");
        handleClose();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("An error occurred while adding the contact");
    } finally {
      setLoader(false);
    }
  };

  const AdminListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
    "&::before": {
      content: '"Org Admin"',
      position: "absolute",
      top: 0,
      right: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(0, 1),
      fontSize: "0.75rem",
      borderRadius: "0 0 4px 4px",
    },
  }));

  const UserListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
    "&::before": {
      content: '"User"',
      position: "absolute",
      top: 0,
      right: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(0, 1),
      fontSize: "0.75rem",
      borderRadius: "0 0 4px 4px",
    },
  }));

  return (
    <div>
      <Toaster />
      <Dialog open={showContactsDialog} onClose={handleClose} fullWidth>
        <DialogTitle>Contacts for {Customer?.name}</DialogTitle>
        <DialogContent>
          <List>
            {UnassignedUsers?.map((contact, index) => (
              <AdminListItem key={index}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" fontWeight="bold">
                      {contact.userName}
                    </Typography>
                  }
                  secondary={`${contact.email || "N/A"} | ${contact.mobile.number || "N/A"}`}
                />
              </AdminListItem>
            ))}
          </List>
          <List>
            {userData?.Users?.map((contact, index) => (
              <UserListItem key={index} sx={{ marginBottom: "10px" }}>
                <ListItemText
                  primary={contact?.userName}
                  secondary={`${contact?.email} | ${contact.mobile.number}`}
                />
              </UserListItem>
            ))}
          </List>
          {!showAddForm && (
            <Button
              onClick={() => setShowAddForm(true)}
              variant="outlined"
              color="primary"
            >
              Add New Contact
            </Button>
          )}
          {showAddForm && (
            <>
              <Typography variant="h6">Add New Contact</Typography>
              <TextField
                label="Name"
                value={newContact.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                value={newContact.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Phone"
                value={newContact.phone}
                onChange={(e) =>
                  handleInputChange(
                    "phone",
                    e.target.value.replace(/\D/g, "").slice(0, 10)
                  )
                }
                error={!!errors.phone}
                helperText={errors.phone}
                fullWidth
                margin="normal"
              />
              <Button
                onClick={handleAddContact}
                variant="contained"
                color="primary"
                disabled={loader}
                margin="normal"
              >
                {loader ? <CircularProgress size={20} /> : "Add Contact"}
              </Button>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Contacts;
