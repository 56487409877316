import toast from "react-hot-toast";
import { getFile } from "../../../../../apis/globalApi";

export const inspectionHeader = [
  {
    label: "Scheduled Date",
    options: {
      filter: true,
    },
  },
  {
    label: "Between",
    options: {
      filter: true,
    },
  },
  {
    name: "Action",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const getFiles = (file) => {
  let bodyData = {
    fileName: file.fileName || file.filename,
    folderName: file.folderName || file.folder || file.FolderName,
    expireLimt: 1000,
  };
  return getFile(bodyData); // Return the Promise directly
};

export const handleDownloadImage = async (url, fileName) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    toast.error("Error downloading image");
  }
};
