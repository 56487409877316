import React from "react";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "./styles";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";

const DashboardItem = ({ title, link, icon: Icon, description }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    history.push(link);
  };

  return (
    <Paper
      className={classes.card}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
      elevation={1}
    >
      <div className={classes.cardContent}>
        <Icon className={classes.icon} />
        <Typography variant="h6" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      </div>
    </Paper>
  );
};

const EmployeePortal = () => {

  const breadcrumb = [
    { label: "Home", link: "/#/app/dashboard" },
    { label: "Employee portal", link: "" },
  ];

  
  const employeeCards = [
    {
      title: "Leaves",
      description: "Apply and track your leave requests",
      link: "/app/employee/leaves",
      icon: TimeToLeaveIcon,
    },
    {
      title: "Your Documents",
      description: "Access and manage your documents",
      link: "/app/employee/documents",
      icon: DocumentScannerIcon,
    },
  ];



  return (
    <Box sx={{ marginTop: "100px" }}>
      <Box sx={{ mb: 3 }}>
        <BreadCrumbs data={breadcrumb} />
      </Box>
      <Stack>
        <Grid container spacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}>
          {employeeCards.map((item, key) => (
            <Grid item xs={6} sm={6} md={4} lg={3} key={key}>
              <DashboardItem {...item} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default EmployeePortal;


