import React, { useState, useRef } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  CircularProgress,
  Link,
  Typography,
  Box,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  CloudUpload,
  InsertDriveFile,
  Image,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FileBase64 from "react-file-base64";
import { fileupload } from "../MprState";

const MaterialDetails = ({ setForm, formData }) => {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState(formData.files);
  const [uploadedGoodsFile, setUploadedGoodsFIle] = useState(formData.goodsPic);
  const [showLoader, setShowLoader] = useState(false);
  const [showGoodsLoader, setShowGoodsLoader] = useState(false);

  const stateMpr = useSelector((state) => state.mprid);
  const loggedInDatas = useSelector((state) => state.userdata);

  const getSimplifiedFileName = (fileName) => {
    if (!fileName) return "";
    const match = fileName.match(
      /materialPic-.*\.(jpeg|jpg|png|pdf|doc|docx)/i
    );
    return match ? match[0] : fileName;
  };

  const handleRemoveFile = (index, type) => {
    if (type === "MaterialList") {
      const newFiles = [...formData.files];
      newFiles.splice(index, 1);
      formData.files = newFiles;
      setUploadedFile(newFiles);
    } else {
      const newFiles = [...formData.goodsPic];
      newFiles.splice(index, 1);
      formData.goodsPic = newFiles;
      setUploadedGoodsFIle(newFiles);
    }
  };

  const handleFileUpload = async (file, type) => {
    const isLoading =
      type === "MaterialList" ? setShowLoader : setShowGoodsLoader;
    isLoading(true);

    try {
      const filetype = file.type.split("/");
      const baseData = file.base64.split(",");

      const uploadData = {
        abbrevation: loggedInDatas?.getData?.abbrevation,
        fileType: type === "MaterialList" ? "materialList" : "materialPic",
        folderName: stateMpr,
        expireLimt: 1800,
        extension: filetype[1] || "",
      };

      const response = await fileupload(
        baseData[1] || "",
        uploadData,
        file.type
      );

      if (response.status === "200") {
        const fileData = {
          fileName: response.fileName || response.filename,
          ref: response.reference,
          folderName:
            response.folderName || response.folder || response.FolderName,
        };

        if (type === "MaterialList") {
          // Update both formData and state with the new file
          formData.files = [...formData.files, fileData];
          setUploadedFile((prev) => [...prev, fileData]);
        } else {
          // Update both formData and state with the new file
          formData.goodsPic = [...formData.goodsPic, fileData];
          setUploadedGoodsFIle((prev) => [...prev, fileData]);
        }
      }
    } catch (error) {
      console.error("File upload failed:", error);
    } finally {
      isLoading(false);
    }
  };

  const uploadStyles = {
    uploadBox: {
      border: "2px dashed #ccc",
      borderRadius: "8px",
      padding: "20px",
      textAlign: "center",
      cursor: "pointer",
      transition: "all 0.3s ease",
      "&:hover": {
        border: "2px dashed #2196f3",
        backgroundColor: "rgba(33, 150, 243, 0.04)",
      },
      position: "relative",
      marginBottom: "20px",
    },
    uploadIcon: {
      fontSize: 40,
      color: "#757575",
      marginBottom: "8px",
      transition: "color 0.3s ease",
    },
    fileTable: {
      "& .MuiTableCell-head": {
        backgroundColor: "#f5f5f5",
        fontWeight: "bold",
      },
      "& .MuiTableCell-root": {
        padding: "12px 16px",
      },
    },
    hiddenInput: {
      display: "none",
    },
  };

  const FileUploadSection = ({
    title,
    description,
    type,
    showLoading,
    icon,
  }) => {
    const fileInputRef = useRef(null);

    const handleBoxClick = () => {
      // Create a temporary input element
      const input = document.createElement("input");
      input.type = "file";
      input.accept = type === "MaterialList" ? ".pdf,.doc,.docx" : "image/*";
      input.style.display = "none";

      // Handle file selection
      input.onchange = (e) => {
        const file = e.target.files[0];
        if (file) {
          // Convert to base64
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64 = e.target.result;
            const fileObject = {
              base64: base64,
              name: file.name,
              type: file.type,
            };
            handleFileUpload(fileObject, type);
          };
          reader.readAsDataURL(file);
        }
      };

      // Trigger click
      input.click();
    };
    return (
      <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: "12px" }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
          {title}
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Typography variant="body2" color="textSecondary" gutterBottom>
          {description}
        </Typography>

        <Box onClick={handleBoxClick} sx={uploadStyles.uploadBox}>
          {showLoading ? (
            <CircularProgress size={30} />
          ) : (
            <>
              {icon}
              <Typography variant="body1" sx={{ mb: 1 }}>
                Click here to upload file
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {type === "MaterialList"
                  ? "Supports: PDF, DOC, DOCX"
                  : "Supports: JPG, PNG, JPEG"}
              </Typography>
              <div style={{ display: "none" }}>
                <FileBase64
                  ref={fileInputRef}
                  multiple={false}
                  onDone={(e) => handleFileUpload(e, type)}
                  inputProps={{
                    accept:
                      type === "MaterialList" ? ".pdf,.doc,.docx" : "image/*",
                  }}
                />
              </div>
            </>
          )}
        </Box>

        {(type === "MaterialList" ? uploadedFile : uploadedGoodsFile).length >
          0 && (
          <Table sx={uploadStyles.fileTable}>
            <TableHead>
              <TableRow>
                <TableCell>{t("common_file_name")}</TableCell>
                <TableCell align="center">{t("common_actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(type === "MaterialList" ? uploadedFile : uploadedGoodsFile).map(
                (item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        {type === "MaterialList" ? (
                          <InsertDriveFile color="primary" />
                        ) : (
                          <Image color="primary" />
                        )}
                        {getSimplifiedFileName(item.fileName)}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Link
                          href={item.ref}
                          target="_blank"
                          sx={{
                            textDecoration: "none",
                            "&:hover": { textDecoration: "underline" },
                          }}
                        >
                          {"View"}
                        </Link>

                        <Tooltip title={"Delete"}>
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveFile(index, type)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        )}
      </Paper>
    );
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={5} lg={5}>
        <FileUploadSection
          title={"Material List"}
          description={"Please upload material list"}
          type="MaterialList"
          showLoading={showLoader}
          icon={<CloudUpload sx={uploadStyles.uploadIcon} />}
        />
      </Grid>
      <Grid item xs={12} md={5} lg={5}>
        <FileUploadSection
          title={t("mpr_goods_details")}
          description={t("common_upload_images")}
          type="GoodsList"
          showLoading={showGoodsLoader}
          icon={<Image sx={uploadStyles.uploadIcon} />}
        />
      </Grid>
    </Grid>
  );
};

export default MaterialDetails;
