import { apiUrl } from "../../data/config";

export async function getEmployeeAnniversayNotifications(data) {
  let url = apiUrl + "/v1/getAnniversaryNotifications";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(data),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
}

export async function deleteEmployeeAnniversayNotifications(data) {
  let url = apiUrl + "/v1/deleteAnniversaryNotifications";
  return fetch(url, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(data),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
}
