import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import CustomButton from "../../components/CustomButton";
import BasicDetails from "./pages/BasicLotDetails";
import ImageUpload from "./pages/ImageUpload";
import { addLot } from "./MarketplaceState";
import ReviewDetails from "./pages/ReviewDetails";
import toast from "react-hot-toast";

function getStepContent(step, formData, setForm, validateOnChange, validate) {
  switch (step) {
    case 0:
      return (
        <BasicDetails
          formData={formData}
          setForm={setForm}
          validateOnChange={validateOnChange}
        />
      );
    case 1:
      return <ImageUpload formData={formData} setForm={setForm} />;

    case 2:
      return <ReviewDetails formData={formData} setForm={setForm} />;
    default:
      return "Unknown step";
  }
}

export default function AddLot(props) {
  const userDetails = useSelector((state) => state);
  const { t } = useTranslation();
  const classes = useStyles();
  const steps = getSteps();

  const AddLot = [
    { label: "Home", link: "/#/app/dashboard" },
    { label: "Marketplace", link: "/#/app/marketplace" },
    { label: "New Listing", link: "" },
  ];

  function getSteps() {
    return [
      "Add details",
      "Material images / Item list",
      t("common_review_details"),
    ];
  }

  const defaultData = {
    lotType: "",
    title: "",
    description: "",
    lastDate: "",
    email: userDetails?.userdata?.getData?.email,
    filesWithReference: [],
    materialList: [],
    tags: [],
    location: "",
    incrementValue: "",
    detailedDescription: "",
    termsAndConditions: [],
    quantity: 0,
    quantityUnit: "",
    ratePerUnit: 0,
    lotAvailability: "",
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setForm] = useState(defaultData);
  const [showLoader, setShowLoader] = React.useState(false);

  const handleNext = () => {
    if (activeStep === 0) {
      if (formData.title === "") {
        toast.error("Please enter the title");
      } else if (formData.basicPrice === "") {
        toast.error("Please enter the base price");
      } else if (formData.location === "") {
        toast.error("Please enter the location");
      } else if (formData.email === "") {
        toast.error("Please enter the Email");
      } else if (formData.lastDate === "") {
        toast.error("Please enter the close date");
      } else if (formData.detailedDescription === "") {
        toast.error("Please enter the Detailed Description");
      } else if (formData.tags && formData.tags.length === 0) {
        toast.error("Please select the tags");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (formData.filesWithReference.length === 0) {
        toast.error("Please upload atleast one image");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setShowLoader(false);
  };

  const handlePublish = () => {
    let tagsArray = [];
    formData.tags.map((item) => {
      tagsArray.push(item.value);
    });
    const data = {
      data: {
        lotType: formData.lotType,
        title: formData.title,
        description: formData.description || "N/A",
        lastDate: formData.lastDate,
        images: formData.filesWithReference,
        materialList: formData.materialList,
        basePrice: formData.basicPrice,
        tagId: tagsArray,
        location: formData.location,
        detailedDescription: formData.detailedDescription,
        tenantId: localStorage.getItem("customerid"),
        status: "open",
        email: userDetails.userdata.getData.email,
        quantity: formData.quantity,
        quantityUnit: formData.quantityUnit,
        ratePerUnit: formData.ratePerUnit,
        lotAvailability: formData.lotAvailability,
      },
    };

    setShowLoader(true);
    addLot(data).then((response) => {
      if (response.status === 200) {
        toast.success("Lot added successfully");
        setTimeout(
          () => props.history.push("/app/marketplace"),
          setShowLoader(false),
          2000
        );
      } else if (response.status === 500) {
        toast.error(response.message);
        setShowLoader(false);
      } else {
        let msg = [];
        if (response.ErrorData) {
          response.ErrorData.details.map((item) => {
            msg.push(item.message);
          });
          toast.error(`${msg.join()}`);
          setShowLoader(false);
        } else {
          toast.error("Something went Wrong! Please try again!");
          setShowLoader(false);
        }
      }
    });
  };

  return (
    <>
      <BreadCrumbs data={AddLot} />
      <div className={classes.content}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {getStepContent(activeStep, formData, setForm)}
          <Paper
            className={classes.paperButton}
            classes={{
              root: classnames(classes.widgetRoot, {
                [classes.noWidgetShadow]: null,
              }),
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button href="/#/app/marketplace" className={classes.button}>
                {t("common_cancel")}
              </Button>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                {t("common_back")}
              </Button>

              {activeStep === steps.length - 1 ? (
                <div>
                  {showLoader ? (
                    <CircularProgress />
                  ) : (
                    <CustomButton
                      variant="contained"
                      title={t("common_publish")}
                      onclick={() => handlePublish()}
                    />
                  )}
                </div>
              ) : (
                <CustomButton
                  variant="contained"
                  title={t("common_next")}
                  onclick={handleNext}
                />
              )}
            </div>
          </Paper>
        </div>
      </div>
    </>
  );
}
