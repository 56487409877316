// ItemsTable.jsx
import React, { useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Button,
  Box,
  Typography,
  Tooltip,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/Download";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import RateHistoryModal from "./RateHistoryModal";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { handleTemplateDownload } from "../utils/helperFunctions";

const ItemsTable = ({
  itemsData,
  setItemsData,
  handleCellChange,
  handleDeleteRow: originalHandleDeleteRow,
  handleAddRow: originalHandleAddRow,
  calculateAmountForAitem,
  quotationColumns,
  totalItems,
}) => {
  const [rateHistoryOpen, setRateHistoryOpen] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [clearAllDialogOpen, setClearAllDialogOpen] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const fileInputRef = useRef(null);

  const handleRateHistoryClick = (itemName) => {
    setSelectedItem(itemName);
    setRateHistoryOpen(true);
  };

  const handleRateHistoryClose = () => {
    setRateHistoryOpen(false);
    setSelectedItem("");
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Handle clear all confirmation
  const handleClearAllConfirm = () => {
    setItemsData([]);
    setClearAllDialogOpen(false);
    showSnackbar("All items cleared successfully");
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  // Handle deletion with serial number adjustment
  const handleDeleteWithSlAdjustment = (rowIndex) => {
    // Create new array without the deleted row
    const updatedData = itemsData.filter((_, index) => index !== rowIndex);

    // Adjust serial numbers for remaining rows
    const resequencedData = updatedData.map((row, index) => {
      return [
        (index + 1).toString(), // Update serial number
        ...row.slice(1), // Keep rest of the row data unchanged
      ];
    });

    // Update the state with resequenced data
    setItemsData(resequencedData);
  };

  // Handle adding new row with correct serial number
  const handleAddRowWithSlAdjustment = () => {
    const newRowIndex = itemsData.length;
    const newRow = Array(quotationColumns.length).fill("");
    newRow[0] = (newRowIndex + 1).toString(); // Set correct serial number

    setItemsData([...itemsData, newRow]);
  };

  const adjustSerialNumbers = (data) => {
    return data.map((row, index) => {
      const updatedRow = [...row];
      updatedRow[0] = (index + 1).toString();
      return updatedRow;
    });
  };

  // excel uploads funs
  //this function need to be called in the backedn to upload the file

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoadingFiles(true);
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Take only first 6 columns from headers and data
        const uploadedHeaders = data[0].slice(0, 6);
        const expectedHeaders = quotationColumns
          .slice(0, 6)
          .map((col) => col.label);

        if (!arraysMatch(uploadedHeaders, expectedHeaders)) {
          showSnackbar(
            "Excel headers do not match the template. Please use the standard template.",
            "error"
          );
          setLoadingFiles(false);
          return;
        }

        // Convert data and filter empty rows, considering only first 6 columns
        const formattedData = data
          .slice(1) // Skip the header row
          .map((row) => row.slice(0, 6)) // Take only first 6 columns
          .filter((row) => row.some((cell) => cell?.toString().trim() !== "")); // Filter empty rows

        if (formattedData.length === 0) {
          showSnackbar(
            "No valid data found in the Excel file. Please check the content.",
            "error"
          );
          setLoadingFiles(false);
          return;
        }

        // Adjust serial numbers for the uploaded data
        const dataWithSerialNumbers = adjustSerialNumbers(formattedData);
        setItemsData(dataWithSerialNumbers);
        showSnackbar("Items uploaded successfully");
        setLoadingFiles(false);
      } catch (error) {
        console.error(error);
        showSnackbar(
          "Error processing file. Please check the format.",
          "error"
        );
        setLoadingFiles(false);
      }
    };

    reader.readAsArrayBuffer(file);
    event.target.value = ""; // Reset the file input
  };
  // Helper function to compare arrays
  const arraysMatch = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  };

  return (
    <Box sx={{ mb: 4 }}>
      {/* Upload Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
          gap: 2,
          alignItems: "center",
        }}
      >
        <Tooltip
          title={
            <Box sx={{ p: 1 }}>
              <Typography variant="body2" sx={{ mb: 1, color: "#333" }}>
                Please use this standard template for uploading items.
                <br /> Do not modify the excel headers.
              </Typography>
              <Button
                size="small"
                startIcon={<DownloadIcon />}
                onClick={handleTemplateDownload}
                sx={{
                  color: "#333",
                  textTransform: "none",
                  mt: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                }}
              >
                Download Template
              </Button>
            </Box>
          }
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#fff",
                "& .MuiTooltip-arrow": {
                  color: "#fff",
                },
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                maxWidth: "none",
              },
            },
          }}
        >
          <IconButton
            size="small"
            sx={{
              color: "#0D6937",
              "&:hover": {
                backgroundColor: "rgba(13, 105, 55, 0.04)",
              },
            }}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>

        <input
          type="file"
          accept=".xlsx,.xls"
          onChange={handleFileUpload}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <Button
          onClick={() => fileInputRef.current.click()}
          variant="outlined"
          startIcon={<UploadFileIcon />}
          sx={{
            borderColor: "#0D6937",
            color: "#0D6937",
            "&:hover": {
              borderColor: "#0A5429",
              backgroundColor: "rgba(13, 105, 55, 0.04)",
            },
            textTransform: "none",
            borderRadius: 2,
            px: 3,
          }}
          disabled={loadingFiles}
        >
          {loadingFiles ? (
            <>
              <CircularProgress size={24} /> Uploading{" "}
            </>
          ) : (
            "Upload Excel File"
          )}
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          mb: 2,
          borderRadius: 2,
          "& .MuiTableCell-root": {
            borderColor: "rgba(224, 224, 224, 1)",
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              {quotationColumns.map((column, colIndex) => (
                <TableCell
                  key={colIndex}
                  sx={{
                    padding: "12px 8px",
                    width: column.width,
                    fontWeight: 600,
                    color: "#333",
                    fontSize: "0.95rem",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  width: 70,
                  fontWeight: 600,
                  color: "#333",
                  fontSize: "0.95rem",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsData.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.02)",
                  },
                }}
              >
                {row.map((cell, colIndex) => (
                  <TableCell
                    key={colIndex}
                    sx={{
                      padding: "8px",
                      verticalAlign: "top",
                    }}
                  >
                    <TextField
                      multiline={colIndex === 1}
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                          "&:hover fieldset": {
                            borderColor: "primary.main",
                          },
                        },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        width: quotationColumns[colIndex]?.width || "100%",
                      }}
                      InputProps={{
                        inputProps: {
                          autoComplete: "off",
                          style: {
                            padding: "8px",
                            fontSize: "0.9rem",
                          },
                        },
                      }}
                      disabled={colIndex === 0}
                      value={
                        colIndex === 5
                          ? calculateAmountForAitem(rowIndex)
                          : cell
                      }
                      onChange={(e) =>
                        handleCellChange(e.target.value, rowIndex, colIndex)
                      }
                    />

                    {colIndex === 4 && (
                      <Tooltip title="View Rate History">
                        <IconButton
                          size="small"
                          onClick={() => handleRateHistoryClick(row[1])}
                          sx={{ ml: 1 }}
                        >
                          <ShowChartIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                ))}
                <TableCell sx={{ width: 70 }}>
                  <Tooltip title="Delete Item">
                    <IconButton
                      onClick={() => handleDeleteWithSlAdjustment(rowIndex)}
                      size="small"
                      sx={{
                        color: "error.main",
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: "#666",
            fontWeight: 500,
          }}
        >
          Total Items: {totalItems}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            onClick={() => setClearAllDialogOpen(true)}
            variant="outlined"
            startIcon={<DeleteSweepIcon />}
            sx={{
              borderColor: "#d32f2f",
              color: "#d32f2f",
              "&:hover": {
                borderColor: "#b71c1c",
                backgroundColor: "rgba(211, 47, 47, 0.04)",
              },
              textTransform: "none",
              borderRadius: 2,
              px: 3,
            }}
          >
            Clear All
          </Button>
          <Button
            onClick={handleAddRowWithSlAdjustment}
            variant="contained"
            startIcon={<AddCircleIcon />}
            sx={{
              backgroundColor: "#0D6937",
              "&:hover": { backgroundColor: "#0A5429" },
              textTransform: "none",
              borderRadius: 2,
              px: 3,
            }}
          >
            Add Item
          </Button>
        </Box>
      </Box>

      <RateHistoryModal
        open={rateHistoryOpen}
        handleClose={handleRateHistoryClose}
        itemName={selectedItem}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={clearAllDialogOpen}
        onClose={() => setClearAllDialogOpen(false)}
        aria-labelledby="clear-all-dialog-title"
        aria-describedby="clear-all-dialog-description"
      >
        <DialogTitle id="clear-all-dialog-title">Clear All Items?</DialogTitle>
        <DialogContent>
          <DialogContentText id="clear-all-dialog-description">
            Are you sure you want to clear all items?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button
            onClick={() => setClearAllDialogOpen(false)}
            variant="outlined"
            sx={{
              textTransform: "none",
              borderRadius: 1,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleClearAllConfirm}
            variant="contained"
            color="error"
            sx={{
              textTransform: "none",
              borderRadius: 1,
            }}
          >
            Clear All
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ItemsTable.propTypes = {
  itemsData: PropTypes.arrayOf(PropTypes.array).isRequired,
  setItemsData: PropTypes.func.isRequired,
  handleCellChange: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  handleAddRow: PropTypes.func.isRequired,
  calculateAmountForAitem: PropTypes.func.isRequired,
  quotationColumns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      width: PropTypes.string,
    })
  ).isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default ItemsTable;
