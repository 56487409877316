import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import {
  LocalShipping,
  Description,
  Schedule,
  DirectionsCar,
  CalendarMonth,
} from "@mui/icons-material";
import toast from "react-hot-toast";
import moment from "moment";

import { assignLead, form6generation } from "../../../LeadState";

function ChangeTime(hour, min) {
  let time = hour > 12 ? hour - 12 : "0" + hour;
  let minute = min < 10 ? "0" + min : min;
  let ampm = hour > 12 ? "PM" : "AM";
  return time + ":" + minute + " " + ampm;
}

const InfoCard = ({ title, value, icon }) => (
  <Box sx={{ mb: 2 }}>
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      {icon}
      <Typography variant="subtitle1" sx={{ ml: 1, color: "text.secondary" }}>
        {title}
      </Typography>
    </Box>
    <Typography variant="body1" sx={{ ml: 4 }}>
      {value || "N/A"}
    </Typography>
  </Box>
);

const PickUpForm = ({ pickup, list, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let receiver = pickup?.ReceiverAddress
    ? [
        pickup.ReceiverAddress.Address.address1,
        pickup.ReceiverAddress.Address.address2,
        pickup.ReceiverAddress.City,
        pickup.ReceiverAddress.State,
        pickup.ReceiverAddress.Pincode,
      ]
        .filter(Boolean)
        .join(", ")
    : "";

  const [pickupDate, setPickupDate] = useState(
    pickup?.editPickupDate ? new Date(pickup.editPickupDate) : new Date()
  );
  const [pickupTime, setPickupTime] = useState(
    pickup?.editPickupTime ? new Date(pickup.editPickupTime) : new Date()
  );
  const [senderAddress, setSenderAddress] = useState(
    pickup?.senderAddress || ""
  );
  const [receiverAddress] = useState(receiver);
  const [materailDescription, setMaterialDescription] = useState(
    pickup?.materialDescription || ""
  );
  const [vehicleNumber, setVehicleNumber] = useState(
    pickup?.vechileRegNumber || ""
  );
  const [vehicleType, setVehicleType] = useState(pickup?.typeOfVehicle || "");
  const [transportNo, setTransportNo] = useState(
    pickup?.transportRegnumber || ""
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Prepare pickup data
      const pickupData = {
        external_id: list.external_id,
        leadData: {
          pickup: {
            pickupDate: moment(pickupDate).format("DD-MM-YYYY"),
            pickupTime: ChangeTime(
              pickupTime.getHours(),
              pickupTime.getMinutes()
            ),
            editPickupTime: pickupTime,
            editPickupDate: pickupDate,
            vechileRegNumber: vehicleNumber,
            typeOfVehicle: vehicleType,
            transportRegnumber: transportNo,
            senderAddress: senderAddress,
            ReceiverAddress: pickup.ReceiverAddress,
            materialDescription: materailDescription,
            pickupStatus: Object.keys(pickup).length > 2,
          },
          status:
            Object.keys(pickup).length > 2
              ? "pickup completed"
              : "pickup initiated",
        },
        mpr_id: list.Mpr_id,
      };

      // Assign lead
      const response = await assignLead(pickupData, dispatch);

      if (response.status !== "200") {
        throw new Error("Failed to assign lead");
      }

      // Handle form 6 generation if pickup is completed
      if (pickupData.leadData.status === "pickup completed") {
        const form6Response = await form6generation(
          { leadID: list.external_id },
          dispatch
        );

        if (form6Response.status !== "200") {
          throw new Error("Form 6 generation failed");
        }

        toast.success("Material Pickup Completed Successfully");
      } else {
        toast.success("Material Pickup Initiated Successfully");
      }

      // Close the form after successful submission
      setTimeout(close, 2000);
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  const formStyles = {
    textField: {
      mb: 3,
      "& .MuiInputBase-root": {
        borderRadius: 1,
      },
    },
    dateTimePicker: {
      width: "100%",
      mb: 3,
    },
  };

  if (pickup.pickupStatus) {
    return (
      <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom color="primary">
            Pickup Details
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InfoCard
                title={t("common_pickup") + " " + t("common_date")}
                value={pickup.pickupDate}
                icon={<CalendarMonth color="primary" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoCard
                title={t("common_pickup") + " " + t("common_time")}
                value={pickup.pickupTime}
                icon={<Schedule color="primary" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoCard
                title={t("lead_pickup_vehicle_info")}
                value={pickup.vechileRegNumber}
                icon={<DirectionsCar color="primary" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoCard
                title={t("lead_pickup_vehicle_info2")}
                value={pickup.typeOfVehicle}
                icon={<LocalShipping color="primary" />}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoCard
                title={t("common_material_description")}
                value={pickup.materialDescription}
                icon={<Description color="primary" />}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom color="primary">
          Schedule Pickup
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t("common_pickup") + " " + t("common_date")}
                value={pickupDate}
                onChange={setPickupDate}
                disablePast
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "outlined",
                    sx: formStyles.textField,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileTimePicker
                label={t("common_pickup") + " " + t("common_time")}
                value={pickupTime}
                onChange={setPickupTime}
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "outlined",
                    sx: formStyles.textField,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("common_material_description")}
              value={materailDescription}
              onChange={(e) => setMaterialDescription(e.target.value)}
              fullWidth
              multiline
              minRows={2}
              variant="outlined"
              sx={formStyles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={"Pickup Address"}
              value={receiverAddress}
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              InputProps={{ readOnly: true }}
              sx={formStyles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t("lead_form_label8")}
              value={senderAddress}
              onChange={(e) => setSenderAddress(e.target.value)}
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              sx={formStyles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t("lead_form_label7")}
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
              fullWidth
              variant="outlined"
              sx={formStyles.textField}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label={t("lead_form_label5")}
              value={vehicleType}
              onChange={(e) => setVehicleType(e.target.value)}
              fullWidth
              variant="outlined"
              sx={formStyles.textField}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label={t("lead_form_label6")}
              value={transportNo}
              onChange={(e) => setTransportNo(e.target.value)}
              fullWidth
              variant="outlined"
              sx={formStyles.textField}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              minWidth: 150,
              height: 45,
              borderRadius: 2,
              textTransform: "none",
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : Object.keys(pickup).length > 2 ? (
              t("lead_complete_pickup")
            ) : (
              t("lead_initialize_pickup")
            )}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PickUpForm;
