import React, { useState } from "react";
import {
  Grid,
  Paper,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import Forms from "../../contacts/components/Forms";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

const ContactDetails = ({ setForm, formData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const address = useSelector((state) => state.mprData.address);
  const contact = useSelector((state) => state.mprData.contacts);

  const [add, setAdd] = useState(
    formData.contact_address === ""
      ? JSON.stringify(address[0])
      : formData.contact_address
  );
  const [cont, setCont] = useState(formData.contact_contact);
  const [showContactDiv, setShowContactDiv] = useState(false);
  const [addressSearch, setAddressSearch] = useState("");
  const [contactSearch, setContactSearch] = useState("");

  formData.contact_address = add;

  const handleChange = (event) => {
    setAdd(event.target.value);
    formData.contact_address = event.target.value;
  };

  const handleContactChange = (event) => {
    setCont(event.target.value);
    formData.contact_contact = event.target.value;
  };

  const filteredAddresses = address.filter((item) => {
    const searchStr = addressSearch.toLowerCase();
    const fullAddress =
      `${item.address.address1} ${item.address.address2} ${item.district} ${item.state} ${item.pincode}`.toLowerCase();
    return fullAddress.includes(searchStr);
  });

  const filteredContacts = contact.filter((item) => {
    const searchStr = contactSearch.toLowerCase();
    const fullContact =
      `${item.Name} ${item.email} ${item.mobile.number}`.toLowerCase();
    return fullContact.includes(searchStr);
  });

  const contentStyles = {
    maxHeight: "400px",
    overflowY: "auto",
    padding: "10px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  };

  const AddressCard = ({ item }) => {
    const addressObj = typeof item === "string" ? JSON.parse(item) : item;
    const isSelected = add === JSON.stringify(addressObj);

    return (
      <FormControlLabel
        value={JSON.stringify(addressObj)}
        control={<Radio color="primary" />}
        sx={{
          mb: 1,
          ml: 0.1,
          p: 1,
          border: "1px solid",
          borderColor: isSelected ? "primary.main" : "grey.300",
          borderRadius: 1,
          width: "100%",
          "&:hover": {
            backgroundColor: "grey.50",
          },
          "& .MuiFormControlLabel-label": {
            width: "100%",
            wordBreak: "break-word",
          },
        }}
        label={
          <Box sx={{ ml: 1 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 500, display: "flex", alignItems: "center" }}
            >
              <LocationOnIcon sx={{ fontSize: 16, mr: 0.5 }} />
              {addressObj.address.address1}
            </Typography>
            {addressObj.address.address2 && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ ml: 2.5 }}
              >
                {addressObj.address.address2}
              </Typography>
            )}
            <Typography variant="body2" color="text.secondary" sx={{ ml: 2.5 }}>
              {[addressObj.district, addressObj.state, addressObj.pincode]
                .filter(Boolean)
                .join(", ")}
            </Typography>
          </Box>
        }
      />
    );
  };

  const ContactCard = ({ item }) => {
    const contactObj = typeof item === "string" ? JSON.parse(item) : item;
    const isSelected = cont === JSON.stringify(contactObj);

    return (
      <FormControlLabel
        value={JSON.stringify(contactObj)}
        control={<Radio color="primary" />}
        sx={{
          mb: 1,
          ml: 0.1,
          p: 1.5,
          border: "1px solid",
          borderColor: isSelected ? "primary.main" : "grey.300",
          borderRadius: 1,
          width: "100%",
          "&:hover": {
            backgroundColor: "grey.50",
          },
          "& .MuiFormControlLabel-label": {
            width: "100%",
            wordBreak: "break-word",
          },
        }}
        label={
          <Box sx={{ ml: 1 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 500, display: "flex", alignItems: "center" }}
            >
              <PersonIcon sx={{ fontSize: 16, mr: 0.5 }} />
              {contactObj.Name && contactObj.Name !== "NUN"
                ? contactObj.Name
                : "N/A"}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <EmailIcon sx={{ fontSize: 16, mr: 0.5 }} />
              {contactObj.email || "N/A"}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <PhoneIcon sx={{ fontSize: 16, mr: 0.5 }} />
              {contactObj.mobile.number || "N/A"}
            </Typography>
          </Box>
        }
      />
    );
  };

  return (
    <Paper className={classes.paper} elevation={2}>
      <Typography variant="h5" sx={{ p: 2 }}>
        {t("common_contact_details")}
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {"Select Pickup Address"}
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              placeholder={"Search Address"}
              value={addressSearch}
              onChange={(e) => setAddressSearch(e.target.value)}
              sx={{ mb: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={contentStyles}>
              <RadioGroup value={add} onChange={handleChange}>
                {filteredAddresses.map((item, index) => (
                  <AddressCard key={index} item={item} />
                ))}
              </RadioGroup>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {"Select Contact Person"}
            </Typography>

            {showContactDiv ? (
              <Forms close={() => setShowContactDiv(false)} where="modal" />
            ) : (
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={"Search "}
                  value={contactSearch}
                  onChange={(e) => setContactSearch(e.target.value)}
                  sx={{ mb: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={contentStyles}>
                  <RadioGroup value={cont} onChange={handleContactChange}>
                    {filteredContacts.map((item, index) => (
                      <ContactCard key={index} item={item} />
                    ))}
                  </RadioGroup>
                </Box>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ContactDetails;
