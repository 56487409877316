import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import {
  Box,
  TextField,
  Typography,
  Container,
  Grid,
  IconButton,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { stateDrop } from "../../../data/config";
import { addCustomerBranchByTenant } from "../AddressState"; // Import your API method
import { useSelector } from "react-redux";

const AddressForms = ({ close }) => {
  const { t } = useTranslation();

  const getdata = useSelector((state) => state.userdata.getData);

  const [formData, setFormData] = useState({
    address1: "",
    city: "",
    state: "",
    pincode: "",
    country: "India",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.address1) newErrors.address1 = "Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.pincode) newErrors.pincode = "Pincode is required";
    if (formData.pincode && !/^\d{6}$/.test(formData.pincode)) {
      newErrors.pincode = "Invalid pincode. Must be 6 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const bodyData = {
      branch: {
        address: {
          address1: formData.address1,
          address2: "",
        },
        district: formData.city,
        state: formData.state,
        country: formData.country,
        pincode: formData.pincode,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        createdBy: "Self",
        updatedBy: "Self",
        isActive: true,
        tenant_id: getdata?.customerid,
      },
      // customer: customerData.Customer._id,
    };

    setLoading(true);
    try {
      const response = await addCustomerBranchByTenant(bodyData);

      if (response.status === "200") {
        toast.success(response.message);
        close();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Failed to add customer location. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ position: "relative", mt: 4, mb: 4 }}>
        <IconButton
          onClick={close}
          sx={{
            position: "absolute",
            top: -20,
            right: 0,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h4" gutterBottom>
          {t("common_address")}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("common_address")}
              name="address1"
              value={formData.address1}
              onChange={handleChange}
              error={!!errors.address1}
              helperText={errors.address1}
              variant="outlined"
              required
              multiline
              rows={2}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("common_city")}
              name="city"
              value={formData.city}
              onChange={handleChange}
              error={!!errors.city}
              helperText={errors.city}
              variant="outlined"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label={t("common_state")}
              name="state"
              value={formData.state}
              onChange={handleChange}
              error={!!errors.state}
              helperText={errors.state}
              variant="outlined"
              required
            >
              {stateDrop.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("common_pincode")}
              name="pincode"
              type="number"
              value={formData.pincode}
              onChange={handleChange}
              error={!!errors.pincode}
              helperText={errors.pincode}
              variant="outlined"
              inputProps={{ maxLength: 6 }}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              loading={loading}
              variant="contained"
              onClick={handleSubmit}
            >
              {t("common_finish")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AddressForms;
