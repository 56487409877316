import React, { useEffect, useState } from "react";
import { Grid, Paper, Button, Alert, Link, Typography } from "@mui/material";
import Table from "../../../../../components/Table/Table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../styles";
import AddPayment from "./AddPayment";
import { getLead } from "../../../LeadState";
import { formatIndianCurrency } from "../../../../../utils/currencyFormat";
import { StyledPaper } from "../../../styles";

const Payment = ({ list, payment, close }) => {
  const { t } = useTranslation();
  var classes = useStyles();
  const dispatch = useDispatch();
  const header = [
    "Date",
    "Supplier Payment ID",
    t("mpr_invoice_amount"),
    t("common_status"),
  ];
  const state = useSelector((state) => state.selectedLead);
  let external_id = state.length > 0 ? state[0].external_id : "";

  const [showForm, setShowForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [paymentData, setPaymentData] = useState(payment);

  const data = [];

  const handleShowForm = () => {
    setShowForm(!showForm);
    getLead(external_id, dispatch).then((response) => {
      if (response && response.payment) setPaymentData(response.payment);
    });
  };

  useEffect(() => {
    if (Object.keys(paymentData).length > 0) {
      let status =
        paymentData.paymentStatus.charAt(0).toUpperCase() +
        paymentData.paymentStatus.slice(1);
      data.push([
        moment(paymentData.createdOn).format("DD-MM-YYYY"),
        paymentData.supplierPaymentId,
        formatIndianCurrency(paymentData.totalAmount),
        status,
      ]);
    }
    setTableData(data);
  }, [showForm, paymentData]);

  return (
    <StyledPaper>
      <Grid container spacing={3}>
        <Grid item lg={8}>
          <Typography variant="h5" component="h2">
            Payment Request
          </Typography>
        </Grid>
        <Grid item lg={4} xs={12} className={classes.iconGridWithButton}>
          {payment && payment.paymentStatus ? (
            <></>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.buttonOutlined}
              onClick={handleShowForm}
            >
              {t("common_add")}
            </Button>
          )}
          {/* <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.buttonOutlined}
            onClick={() => close()}
          >
            {t("common_close")}
          </Button> */}
        </Grid>
      </Grid>

      {!showForm && (
        <Alert severity="info" sx={{ my: 2 }}>
          To check updated payment details, copy the Supplier Payment ID and
          check in the{" "}
          <Link href="https://my.binbag.in/#/app/payments" underline="hover">
            Payments tab
          </Link>
          .
        </Alert>
      )}

      {showForm ? (
        <>
          <AddPayment
            close={handleShowForm}
            list={list}
            payment={payment}
            paymentData={paymentData}
          />
        </>
      ) : (
        <Table
          data={tableData}
          header={header}
          name=""
          options={{
            filterType: "checkbox",
            print: false,
            download: false,
            filter: false,
            selectableRows: "none",
          }}
        />
      )}
    </StyledPaper>
  );
};

export default Payment;
