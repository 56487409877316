import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  TableContainer,
  TableHead,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Table,
  IconButton,
  TextField,
  CircularProgress,
  FormControl,
  Box,
  Badge,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import FileBase64 from "react-file-base64";
import ImageIcon from "@mui/icons-material/Image";
import toast from "react-hot-toast";
import {
  form6Update,
  getFile,
  assignLead,
  getLead,
  addSignature,
} from "../../../LeadState";
import useStyles from "../styles";
import ViewPDF from "../invoice/ViewPDF";
import { fileupload } from "../../../../material_pickup_request/MprState";
import { getFiles } from "./utils";
import SendForm6Dialog from "./components/SendForm6Dialog";

import { Email as EmailIcon } from "@mui/icons-material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { StyledPaper } from "../../../styles";

const Form6 = ({ contactPerson, form6Datas, form6File, list, close }) => {
  const { t } = useTranslation();
  var classes = useStyles();
  const dispatch = useDispatch();
  const [TableData, setTableData] = useState([]);
  const loginData = useSelector((state) => state.userdata);
  const state = useSelector((state) => state.selectedLead);
  let external_id = state.length > 0 ? state[0].external_id : "";
  let loggedinID = loginData && loginData.getData ? loginData.getData._id : "";
  const [form6Data, setForm6Data] = useState(form6Datas);
  const [showSenderAddress, setShowSenderAddress] = useState(false);
  const [showSenderAuth, setShowSenderAuth] = useState(false);
  const [showManifestDoc, setShowManifestDoc] = useState(false);
  const [showTransAddress, setShowTransAddress] = useState(false);
  const [showVehicle, setShowVehicle] = useState(false);
  const [showtransReg, setShowTransReg] = useState(false);
  const [showVehicleReg, setShowVehicleReg] = useState(false);
  const [showReceiverAuth, setShowReceiverAuth] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showReceiverAdd, setShowReceiverAdd] = useState(false);
  const [showSign, setShowSign] = useState(false);

  const [openSendDialog, setOpenSendDialog] = useState(false);

  const [senderAuthNo, setSenderAuthNo] = useState(
    form6Data && form6Data.Content ? form6Data.Content.no2 : ""
  );
  const [manifest, setManifest] = useState(
    form6Data && form6Data.Content ? form6Data.Content.no3 : ""
  );
  const [transAdd, setTransAdd] = useState(
    form6Data && form6Data.Content ? form6Data.Content.no4 : ""
  );
  const [vehicle, setVehicle] = useState(
    form6Data && form6Data.Content ? form6Data.Content.no5 : ""
  );
  const [TransReg, setTransReg] = useState(
    form6Data && form6Data.Content ? form6Data.Content.no6 : ""
  );
  const [vehicleReg, setVehicleReg] = useState(
    form6Data && form6Data.Content ? form6Data.Content.no7 : ""
  );
  const [receiverAuth, setReceiverAuth] = useState(
    form6Data && form6Data.Content ? form6Data.Content.no9 : ""
  );
  const [desc, setDesc] = useState(
    form6Data && form6Data.Content ? form6Data.Content.no10 : ""
  );
  // eslint-disable-next-line
  const [senderAdd, setSenderAdd] = useState(
    form6Data && form6Data.Content ? form6Data.Content.no1 : ""
  );
  // eslint-disable-next-line
  const [receiverAdd, setReceiverAdd] = useState(
    form6Data && form6Data.Content ? form6Data.Content.no8 : ""
  );
  // eslint-disable-next-line
  const [stampData, setStampData] = useState(
    form6Data && form6Data.Content ? form6Data.thisStamp : ""
  );
  // eslint-disable-next-line
  const [signData, setSignData] = useState(
    form6Data && form6Data.Content ? form6Data.thisSignature : ""
  );
  // eslint-disable-next-line
  const [fileData, setFileData] = useState("");

  const [newForm6File, setNewForm6File] = useState(form6File);

  //message
  const [showloader, setShowLoader] = useState(false);

  const [showStampLoader, setShowStampLoader] = useState(false);
  const [sendButton, setSendButton] = useState(false);

  //pdf
  const [showPDF, setShowPDF] = useState(false);
  const [formUrl, setFormUrl] = useState("");

  const HandleIcon = ({ onclick }) => {
    let icon = (
      <IconButton
        className={classes.collapseIcon}
        onClick={onclick}
        size="large"
      >
        <EditIcon />
      </IconButton>
    );
    return icon;
  };

  const HandleSaveIcon = ({ func, disable }) => {
    let icon = (
      <Button
        variant="outlined"
        disabled={disable}
        size="small"
        onClick={() => handleSave(func)}
        className={classes.buttonOutlined}
      >
        {t("common_save")}
      </Button>
    );
    return icon;
  };

  const isFormComplete = () => {
    return (
      senderAdd !== "" &&
      senderAuthNo !== "" &&
      manifest !== "" &&
      transAdd !== "" &&
      vehicle !== "" &&
      TransReg !== "" &&
      vehicleReg !== "" &&
      receiverAdd !== "" &&
      receiverAuth !== "" &&
      desc !== "" &&
      form6Data.thisStamp !== "" &&
      form6Data.thisSignature !== ""
    );
  };

  const getFieldValue = (label) => {
    switch (label) {
      case t("lead_form_label1"):
        return senderAdd;
      case t("lead_form_label2"):
        return senderAuthNo;
      case t("lead_form_label3"):
        return manifest;
      case t("lead_form_label4"):
        return transAdd;
      case t("lead_form_label5"):
        return vehicle;
      case t("lead_form_label6"):
        return TransReg;
      case t("lead_form_label7"):
        return vehicleReg;
      case t("lead_form_label8"):
        return receiverAdd;
      case t("lead_form_label9"):
        return receiverAuth;
      case t("lead_form_label10"):
        return desc;
      default:
        return "";
    }
  };

  const handleFieldChange = (label, value) => {
    // eslint-disable-next-line default-case
    switch (label) {
      case t("lead_form_label1"):
        setSenderAdd(value);
        break;
      case t("lead_form_label2"):
        setSenderAuthNo(value);
        break;
      case t("lead_form_label3"):
        setManifest(value);
        break;
      case t("lead_form_label4"):
        setTransAdd(value);
        break;
      case t("lead_form_label5"):
        setVehicle(value);
        break;
      case t("lead_form_label6"):
        setTransReg(value);
        break;
      case t("lead_form_label7"):
        setVehicleReg(value);
        break;
      case t("lead_form_label8"):
        setReceiverAdd(value);
        break;
      case t("lead_form_label9"):
        setReceiverAuth(value);
        break;
      case t("lead_form_label10"):
        setDesc(value);
        break;
    }
  };

  const handleshowhidepdf = () => {
    setShowPDF(!showPDF);
  };

  const handleupdateImage = (e) => {
    setFileData(e);
  };

  const handleChange = (type) => {
    if (
      fileData.type === "image/png" ||
      fileData.type === "image/jpeg" ||
      fileData.type === "image/jpg"
    ) {
      setShowStampLoader(true);
      var filetype = fileData.type.split("/");
      var baseData = fileData.base64.split(",");
      let getTokenData = {
        abbrevation: loginData.getData.abbrevation,
        fileType: "assets",
        folderName: list.external_id,
        expireLimt: 1800,
        extension: filetype.length > 0 ? filetype[1] : "",
      };
      fileupload(
        baseData.length > 0 ? baseData[1] : "",
        getTokenData,
        fileData.type
      ).then((response) => {
        if (response.status === "200") {
          let body = {
            thisItem: type,
            thisImage: {
              fileName: response.fileName || response.filename,
              folderName:
                response.folderName || response.folder || response.FolderName,
            },
            thisLead: list.external_id,
            thisForm: "form6",
          };
          addSignature(body).then((resp) => {
            if (resp.status === "200") {
              toast.success(resp.message);
              setShowStampLoader(false);
              setShowSign(false);
              getLead(external_id, dispatch).then((response) => {
                if (response && response.thisFormContent)
                  setForm6Data(response.thisFormContent);
              });
            } else {
              toast.error(resp.message);

              setShowStampLoader(false);
              setShowSign(false);
            }
          });
        }
      });
    } else {
      toast.error("Please upload Image");
    }
  };

  const handleSave = () => {
    if (!isFormComplete()) {
      toast.error("Please fill all fields before saving");
      return;
    }
    setShowLoader(true);
    let BodyData = {
      thisleadID: list.external_id,
      thisDetails: {
        no1: senderAdd,
        no2: senderAuthNo,
        no3: manifest,
        no4: transAdd,
        no5: vehicle,
        no6: TransReg,
        no7: vehicleReg,
        no8: receiverAdd,
        no9: receiverAuth,
        no10: desc,
        no11:
          form6Data &&
          form6Data.Content &&
          form6Data.Content.no11 !== "Invalid date"
            ? form6Data.Content.no11
            : "",
        no12:
          form6Data &&
          form6Data.Content &&
          form6Data.Content.no11 !== "Invalid date"
            ? form6Data.Content.no12
            : "",
        no13:
          form6Data &&
          form6Data.Content &&
          form6Data.Content.no11 !== "Invalid date"
            ? form6Data.Content.no13
            : "",
      },
    };
    form6Update(BodyData).then((response) => {
      if (response.status === "200") {
        toast.success("Updated Successfully");
        setShowLoader(false);
        getLead(list.external_id, dispatch).then((response) => {
          if (response && response.thisFormContent)
            setForm6Data(response.thisFormContent);
          setNewForm6File(response.form6);
        });
      } else {
        toast.error("Something went wrong");
        setShowLoader(false);
      }
    });
  };

  const handleShowPreview = () => {
    setShowPDF(false);
  };

  const handlePreview = () => {
    let body = {
      fileName: newForm6File.fileName,
      folderName: newForm6File.folderName,
      expireLimt: 1800,
    };
    getFile(body).then((response) => {
      setFormUrl(response.reference);
      setShowPDF(true);
    });
  };

  const handlePublish = () => {
    setShowLoader(true);
    let data = {
      external_id: list.external_id,
      leadData: {
        status: "Form6 sent",
      },
      mpr_id: list.Mpr_id,
    };
    assignLead(data, dispatch).then((response) => {
      if (response.status === "200") {
        toast.success("Form-6 Sent Successfully!");
        setTimeout(() => close(), 2000);
        setShowLoader(false);
      } else {
        toast.error("Something went wrong");
        setShowLoader(false);
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem("type") === "tenant") {
      setSendButton(true);
    } else {
      setSendButton(false);
    }
  }, []);

  useEffect(() => {
    let data = [];
    if (Object.keys(form6Data).length > 0) {
      let formData = form6Data.Content;
      let stamp = form6Data.thisStamp;
      let sign = form6Data.thisSignature;
      data.push(
        {
          label: t("lead_form_label1"),
          value: showSenderAddress ? (
            <TextField
              variant="standard"
              autoFocus
              multiline
              label=""
              fullWidth
              value={senderAdd}
              onChange={(e) => setSenderAdd(e.target.value)}
            />
          ) : (
            formData.no1
          ),
          action: showSenderAddress ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              <HandleSaveIcon
                disable={senderAdd === "" ? true : false}
                func={setShowSenderAddress}
              />
            )
          ) : (
            <HandleIcon onclick={() => setShowSenderAddress(true)} />
          ),
        },
        {
          label: t("lead_form_label2"),
          value: showSenderAuth ? (
            <TextField
              variant="standard"
              autoFocus
              multiline
              fullWidth
              label=""
              value={senderAuthNo}
              onChange={(e) => setSenderAuthNo(e.target.value)}
            />
          ) : (
            formData.no2
          ),
          action: showSenderAuth ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              <HandleSaveIcon
                disable={senderAuthNo === "" ? true : false}
                func={setShowSenderAuth}
              />
            )
          ) : (
            <HandleIcon onclick={() => setShowSenderAuth(true)} />
          ),
        },
        {
          label: t("lead_form_label3"),
          value: showManifestDoc ? (
            <TextField
              variant="standard"
              multiline
              fullWidth
              autoFocus
              label=""
              value={manifest}
              onChange={(e) => setManifest(e.target.value)}
            />
          ) : (
            formData.no3
          ),
          action: showManifestDoc ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              <HandleSaveIcon
                disable={manifest === "" ? true : false}
                func={setShowManifestDoc}
              />
            )
          ) : (
            <HandleIcon onclick={() => setShowManifestDoc(true)} />
          ),
        },
        {
          label: t("lead_form_label4"),
          value: showTransAddress ? (
            <TextField
              variant="standard"
              multiline
              fullWidth
              autoFocus
              label=""
              value={transAdd}
              onChange={(e) => setTransAdd(e.target.value)}
            />
          ) : (
            formData.no4
          ),
          action: showTransAddress ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              <HandleSaveIcon
                disable={transAdd === "" ? true : false}
                func={setShowTransAddress}
              />
            )
          ) : (
            <HandleIcon onclick={() => setShowTransAddress(true)} />
          ),
        },
        {
          label: t("lead_form_label5"),
          value: showVehicle ? (
            <TextField
              variant="standard"
              multiline
              fullWidth
              autoFocus
              label=""
              value={vehicle}
              onChange={(e) => setVehicle(e.target.value)}
            />
          ) : (
            formData.no5
          ),
          action: showVehicle ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              <HandleSaveIcon
                disable={vehicle === "" ? true : false}
                func={setShowVehicle}
              />
            )
          ) : (
            <HandleIcon onclick={() => setShowVehicle(true)} />
          ),
        },
        {
          label: t("lead_form_label6"),
          value: showtransReg ? (
            <TextField
              variant="standard"
              multiline
              fullWidth
              autoFocus
              label=""
              value={TransReg}
              onChange={(e) => setTransReg(e.target.value)}
            />
          ) : (
            formData.no6
          ),
          action: showtransReg ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              <HandleSaveIcon
                disable={TransReg === "" ? true : false}
                func={setShowTransReg}
              />
            )
          ) : (
            <HandleIcon onclick={() => setShowTransReg(true)} />
          ),
        },
        {
          label: t("lead_form_label7"),
          value: showVehicleReg ? (
            <TextField
              variant="standard"
              autoFocus
              multiline
              fullWidth
              label=""
              value={vehicleReg}
              onChange={(e) => setVehicleReg(e.target.value)}
            />
          ) : (
            formData.no7
          ),
          action: showVehicleReg ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              <HandleSaveIcon
                disable={vehicleReg === "" ? true : false}
                func={setShowVehicleReg}
              />
            )
          ) : (
            <HandleIcon onclick={() => setShowVehicleReg(true)} />
          ),
        },
        {
          label: t("lead_form_label8"),
          value: showReceiverAdd ? (
            <TextField
              variant="standard"
              autoFocus
              multiline
              label=""
              fullWidth
              value={receiverAdd}
              onChange={(e) => setReceiverAdd(e.target.value)}
            />
          ) : (
            formData.no8
          ),
          action: showReceiverAdd ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              <HandleSaveIcon
                disable={receiverAdd === "" ? true : false}
                func={setShowReceiverAdd}
              />
            )
          ) : (
            <HandleIcon onclick={() => setShowReceiverAdd(true)} />
          ),
        },
        {
          label: t("lead_form_label9"),
          value: showReceiverAuth ? (
            <TextField
              variant="standard"
              autoFocus
              multiline
              fullWidth
              label=""
              value={receiverAuth}
              onChange={(e) => setReceiverAuth(e.target.value)}
            />
          ) : (
            formData.no9
          ),
          action: showReceiverAuth ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              <HandleSaveIcon
                disable={receiverAuth === "" ? true : false}
                func={setShowReceiverAuth}
              />
            )
          ) : (
            <HandleIcon onclick={() => setShowReceiverAuth(true)} />
          ),
        },
        {
          label: t("lead_form_label10"),
          value: showDescription ? (
            <TextField
              variant="standard"
              multiline
              fullWidth
              autoFocus
              label=""
              minRows={4}
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          ) : (
            formData.no10
          ),
          action: showDescription ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              <HandleSaveIcon
                disable={desc === "" ? true : false}
                func={setShowDescription}
              />
            )
          ) : (
            <HandleIcon onclick={() => setShowDescription(true)} />
          ),
        },
        {
          label: t("lead_stamp"),
          value:
            stamp === "" ? (
              loginData.getData.roles.includes("Admin") ? (
                <>
                  <div className={classes.fileTypeInputTable}>
                    <Grid container spacing={3}>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <FormControl
                          variant="standard"
                          className={classes.margin}
                        >
                          <FileBase64
                            className={classes.input}
                            multiple={false}
                            onDone={(e) => handleupdateImage(e)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "right" }}
                      >
                        {showStampLoader ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleChange("Stamp")}
                            className={classes.button}
                          >
                            {t("common_upload")}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <span>Please upload .png,.jpg, .jpeg file formats</span>
                </>
              ) : (
                <></>
              )
            ) : (
              <IconButton
                onClick={() => getFiles(stamp)}
                target="_blank"
                className={classes.collapseIcon}
                size="large"
              >
                <ImageIcon />
              </IconButton>
            ),
          action: "",
        },
        {
          label: t("lead_signature"),
          value:
            sign === "" || showSign ? (
              list.assigned_to === "" || list.assigned_to === loggedinID ? (
                <>
                  <div className={classes.fileTypeInputTable}>
                    <Grid container spacing={3}>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <FormControl
                          variant="standard"
                          className={classes.margin}
                        >
                          <FileBase64
                            className={classes.input}
                            multiple={false}
                            onDone={(e) => handleupdateImage(e)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "right" }}
                      >
                        {showStampLoader ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleChange("Signature")}
                            className={classes.button}
                          >
                            {t("common_upload")}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <span>Please upload .png,.jpg, .jpeg file formats</span>
                </>
              ) : (
                <></>
              )
            ) : (
              <IconButton
                onClick={() => getFiles(sign)}
                target="_blank"
                className={classes.collapseIcon}
                size="large"
              >
                <ImageIcon />
              </IconButton>
            ),
          action: showSign ? (
            showloader ? (
              <CircularProgress size={24} />
            ) : (
              ""
            )
          ) : (
            <HandleIcon onclick={() => setShowSign(true)} />
          ),
        }
      );
    }

    setTableData(data);
    // eslint-disable-next-line
  }, [
    showloader,
    showSenderAuth,
    senderAuthNo,
    manifest,
    showManifestDoc,
    showTransAddress,
    transAdd,
    showVehicle,
    vehicle,
    showtransReg,
    TransReg,
    showVehicleReg,
    vehicleReg,
    showReceiverAuth,
    receiverAuth,
    showDescription,
    desc,
    form6Data,
    fileData,
    showStampLoader,
    showSenderAddress,
    senderAdd,
    showSign,
    showReceiverAdd,
    receiverAdd,
  ]);

  return (
    <StyledPaper >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5} lg={2} md={2}>
          <Typography variant="h5" component="h2">
            {t("lead_form_review")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          lg={10}
          md={10}
          className={classes.iconGridWithButton}
        >
          {showPDF ? (
            <Button
              variant="outlined"
              onClick={handleShowPreview}
              color="primary"
              size="small"
              className={classes.buttonOutlined}
            >
              {t("common_back")}
            </Button>
          ) : (
            <Box style={{ display: "flex", gap: 10 }}>
              <Button
                variant="outlined"
                onClick={handlePreview}
                color="primary"
                size="small"
                disabled={showloader}
              >
                {t("common_preview")}
              </Button>
              {sendButton && (
                <Badge
                  badgeContent={list.form6History?.length || 0}
                  color="primary"
                  max={99}
                  sx={{
                    marginRight: "12px",
                    "& .MuiBadge-badge": {
                      bgcolor: "white",
                      color: "#0D6937",
                      border: "1px solid #0D6937",
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setOpenSendDialog(true)}
                    color="primary"
                    size="small"
                    startIcon={<ForwardToInboxIcon />}
                    disabled={!isFormComplete() || showloader}
                  >
                    Email Form 6
                  </Button>
                </Badge>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item lg={12} md={12} xs={12} sm={12}>
          {showPDF ? (
            <ViewPDF url={formUrl} close={handleshowhidepdf} />
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      {t("common_name")}
                    </TableCell>
                    <TableCell align="left" className={classes.tableHeader}>
                      {t("common_details")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TableData.length > 0 ? (
                    TableData.map((item, key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {item.label}
                        </TableCell>
                        <TableCell align="left" style={{ width: "60%" }}>
                          {item.label === t("lead_stamp") ||
                          item.label === t("lead_signature") ? (
                            item.value
                          ) : (
                            <TextField
                              variant="standard"
                              fullWidth
                              multiline
                              value={getFieldValue(item.label)}
                              onChange={(e) =>
                                handleFieldChange(item.label, e.target.value)
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {t("lead_form_no_data")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        {!showPDF && (
          <>
            <Grid item lg={10} md={10} xs={12}></Grid>
            <Grid item lg={2} md={2} xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={!isFormComplete() || showloader}
                startIcon={
                  showloader ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : null
                }
              >
                Update
              </Button>
            </Grid>{" "}
          </>
        )}
      </Grid>

      <Grid container spacing={3} className={classes.grid}>
        <Grid item lg={10} className={classes.spanForm6}>
          {form6Data.thisStamp === "" || form6Data.thisSignature === "" ? (
            <span>
              Please Upload Your Signature and Company's Stamp to Send Form 6.
            </span>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <SendForm6Dialog
        open={openSendDialog}
        onClose={() => setOpenSendDialog(false)}
        contactPerson={contactPerson}
        form6File={newForm6File}
        leadDetails={list}
        dispatch={dispatch}
        close={close}
      />
    </StyledPaper>
  );
};
export default Form6;
