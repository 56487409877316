export const documentSections = [
  {
    title: "Identity Proof",
    subsections: [
      {
        title: "Aadhar Card",
        description:
          "Upload your Aadhaar card in PDF or image format. Maximum file size: 5MB",
        schemaKey: "aadharcard",
      },
      {
        title: "PAN Card",
        description:
          "Upload your PAN card in PDF or image format. Maximum file size: 5MB",
        schemaKey: "pancard",
      },
    ],
  },
  {
    title: "Professional Documents",
    subsections: [
      {
        title: "Employment Letter",
        description:
          "Upload your current employment letter/contract. Maximum file size: 5MB",
        schemaKey: "appointmentletter",
      },
      {
        title: "Resume",
        description: "Upload your current resume/CV",
        schemaKey: "resume",
      },
      {
        title: "Experience Certificate",
        description: "Upload your professional experience certificate",
        schemaKey: "experiencecertificate",
      },
    ],
  },
  {
    title: "Educational Documents",
    subsections: [
      {
        title: "Education Certificate",
        description: "Upload any relevant educational certifications",
        schemaKey: "educationcertificate",
      },
    ],
  },
  {
    title: "Bank Details",
    subsections: [
      {
        title: "Bank Passbook",
        description: "Upload a copy of your bank passbook",
        schemaKey: "bankpassbook",
      },
    ],
  },
  {
    title: "Other Documents",
    subsections: [
      {
        title: "Other Documents",
        description: "Upload any other documents",
        schemaKey: "otherdocuments",
      },
    ],
  },
];

export const policiesList = [
  {
    Label: "HR Policy & Holidays",
    Link: "https://docs.google.com/spreadsheets/d/1WMCnQuqoJVz5ELMQ7a9s6_m5YWugGjep/edit?usp=sharing&ouid=108342766653445997441&rtpof=true&sd=true",
  },
  {
    Label: "Employee Handbook",
    Link: "https://drive.google.com/file/d/11CPwZCHsHMPMl9OHJBl5PeNnr2Gqw6V7/view?usp=sharing",
  },
  {
    Label: "The Compass",
    Link: "https://drive.google.com/file/d/11bxKuCRDDPpy1kv31Mx92a5-SdWXtkfc/view?usp=sharing",
  },
];
