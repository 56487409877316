import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <Typography variant="subtitle2">{`Month: ${label}`}</Typography>
        <Typography variant="body2" sx={{ color: "#8884d8" }}>
          {`Our Rate: ₹${payload[0].value}`}
        </Typography>
        <Typography variant="body2" sx={{ color: "#82ca9d" }}>
          {`Market Avg: ₹${payload[1].value}`}
        </Typography>
      </Box>
    );
  }
  return null;
};

const RateHistoryModal = ({ open, handleClose, itemName }) => {
  const emptyData = [
    { month: "Jan", rate: 0, avgMarketRate: 0 },
    { month: "Feb", rate: 0, avgMarketRate: 0 },
    { month: "Mar", rate: 0, avgMarketRate: 0 },
    { month: "Apr", rate: 0, avgMarketRate: 0 },
    { month: "May", rate: 0, avgMarketRate: 0 },
    { month: "Jun", rate: 0, avgMarketRate: 0 },
  ];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Rate History for {itemName}</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Alert severity="info" sx={{ mb: 2 }}>
            This chart will automatically populate once there are enough data
            points from your quotations. Start creating quotations in the CRM to
            see historical rate trends.
          </Alert>
          <Typography variant="body2" color="text.secondary">
            The rate history feature will show:
          </Typography>
          <Box sx={{ mt: 1, ml: 2, mb: 3 }}>
            <Typography variant="body2" color="text.secondary">
              • Your quoted rates over time
            </Typography>
            <Typography variant="body2" color="text.secondary">
              • Market average rates for comparison
            </Typography>
            <Typography variant="body2" color="text.secondary">
              • 6-month historical trends
            </Typography>
          </Box>
        </Box>

        <Box sx={{ height: 400, width: "100%", mt: 2 }}>
          <ResponsiveContainer>
            <LineChart
              data={emptyData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" tick={{ fontSize: 12 }} />
              <YAxis
                tick={{ fontSize: 12 }}
                label={{
                  value: "Rate/Item(₹)",
                  angle: -90,
                  position: "insideLeft",
                  fontSize: 12,
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line
                type="monotone"
                dataKey="rate"
                name="Our Rate/Item(₹)"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="avgMarketRate"
                name="Market Average"
                stroke="#82ca9d"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RateHistoryModal;
