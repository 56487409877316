import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CircularProgress,
  Typography,
  TextField,
  Grid,
  Box,
  Paper,
  IconButton,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SubjectIcon from "@mui/icons-material/Subject";
import { useSelector } from "react-redux";
import { useUserRole } from "../../../hooks/useUserRole";

const InfoSection = ({ icon: Icon, title, children }) => (
  <Box>
    <Box display="flex" alignItems="center" mb={1}>
      <Icon color="primary" sx={{ mr: 1 }} />
      <Typography variant="subtitle2">{title}</Typography>
    </Box>
    {children}
  </Box>
);

const LeaveDetailCard = ({ children }) => (
  <Paper
    elevation={1}
    sx={{
      p: 2,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    {children}
  </Paper>
);

const LeaveActionModal = ({
  isModalOpen,
  toggleModal,
  data,
  onStatusChange,
}) => {
  const loggedInData = useSelector((state) => state.userdata.getData);
  const { isViewer, isOnlyUser } = useUserRole(loggedInData);

  const [selectedValue, setSelectedValue] = useState(data?.status);
  const [rejectionReason, setRejectionReason] = useState(
    data?.reasonForRejection
  );
  const [loader, setLoader] = useState(false);
  const [isSameApprover, setIsSameApprover] = useState(false);

  useEffect(() => {
    setSelectedValue(data?.status);
    setRejectionReason(data?.reasonForRejection);
  }, [data?.status, data?.reasonForRejection]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    setIsSameApprover(
      data &&
        loggedInData?._id === data?.raisedBy?.[0]?._id &&
        loggedInData?.roles.some((item) => item === "Approver")
    );
  };

  const handleConfirm = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      onStatusChange(selectedValue, rejectionReason);
      toggleModal();
    }, 2000);
  };

  const isConfirmDisabled =
    isSameApprover ||
    isViewer ||
    isOnlyUser ||
    (selectedValue === data?.status &&
      rejectionReason?.trim() === data?.reasonForRejection) ||
    (selectedValue === "Rejected" && !rejectionReason?.trim()) ||
    data?.status === "Rejected" ||
    loader;

  return (
    <Dialog open={isModalOpen} fullWidth maxWidth="sm" onClose={toggleModal}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Leave Request {data?.leaveId}:</Typography>
          <IconButton onClick={toggleModal} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <LeaveDetailCard>
              <InfoSection icon={DateRangeIcon} title="Leave Period">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Chip
                    label={`From: ${data?.startDate}`}
                    variant="outlined"
                    size="small"
                  />
                  <Chip
                    label={`To: ${data?.endDate}`}
                    variant="outlined"
                    size="small"
                  />
                </Box>
              </InfoSection>
              <InfoSection icon={AccessTimeIcon} title="Duration">
                <Chip
                  label={`${data?.noOfDays || "N/A"} days`}
                  color="primary"
                  size="small"
                />
              </InfoSection>
            </LeaveDetailCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <LeaveDetailCard>
              <InfoSection icon={SubjectIcon} title="Leave Details">
                <Typography variant="body2" color="textSecondary">
                  Type
                </Typography>
                <Chip
                  label={data?.selectedLeaveType}
                  color="secondary"
                  size="small"
                  sx={{ mt: 0.5 }}
                />
              </InfoSection>
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  Reason
                </Typography>
                <Typography variant="body1" sx={{ mt: 0.5 }}>
                  {data?.reasonForLeave}
                </Typography>
              </Box>
            </LeaveDetailCard>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Paper elevation={1} sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {isOnlyUser ? "Status" : "Update Status"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={selectedValue}
                onChange={handleRadioChange}
              >
                {["Pending", "Approved", "Rejected"].map((status) => (
                  <FormControlLabel
                    key={status}
                    value={status}
                    control={<Radio />}
                    label={status}
                    disabled={status === "Pending"}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {selectedValue === "Rejected" && (
              <TextField
                label="Reason*"
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
                fullWidth
                margin="normal"
                variant="filled"
                InputProps={{
                  readOnly: isOnlyUser || isViewer,
                }}
                multiline
                minRows={2}
              />
            )}
          </Paper>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            onClick={toggleModal}
            variant="outlined"
            color="primary"
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            disabled={isConfirmDisabled}
          >
            {loader ? <CircularProgress size={24} /> : "Confirm"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LeaveActionModal;
