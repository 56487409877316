import { makeStyles } from "@mui/styles";

// export default makeStyles((theme) => ({
//   content: {
//     padding: theme.spacing(6),
//     paddingTop: theme.spacing(20),
//   },
//   Datapaper: {
//     marginBottom: theme.spacing(4),
//   },
//   grid: {
//     padding: theme.spacing(1),
//   },
//   userGrid: {
//     paddingLeft: theme.spacing(2),
//   },
//   buttonGrid: {
//     paddingTop: theme.spacing(2),
//   },
//   backIcon: {
//     "&:hover": {
//       textDecoration: "none",
//     },
//   },
//   backIconGrid: {
//     marginTop: "1rem",
//     textAlign: "right",
//   },
//   collapseIcon: {
//     cursor: "pointer",
//     color: theme.palette.primary.main,
//     "&:hover": {
//       //color: theme.palette.secondary.main
//     },
//   },
//   iconGrid: {
//     textAlign: "right",
//   },
//   avatargrid: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",

//   },
//   userActionGrid: {
//     margin: "1rem",
//   },
//   buttonOutlined: {
//     color: theme.palette.primary.main,
//     border: "1px solid",
//     borderColor: theme.palette.primary.main,
//     "&:hover": {
//       backgroundColor: theme.palette.primary.main,
//       color: theme.palette.secondary.contrastText,
//     },
//   },
//   selectedBtn:{
//     backgroundColor: theme.palette.primary.main,
//       color: theme.palette.secondary.contrastText,
//   }
// }));

export default makeStyles((theme) => ({
  widgetRoot: {
    transition: "all 0.3s ease",
    position: "relative",
    marginRight: theme.spacing(4), // Add margin to accommodate the button
    overflow: "visible",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
  },
  avatargrid: {
    display: "flex",
    alignItems: "center",
  },
  buttonOutlined: {
    margin: theme.spacing(1, 0),
    textAlign: "left",
    transition: "all 0.3s ease",
  },
  selectedBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  collapseButton: {
    position: "absolute",
    right: theme.spacing(-2), // Move button closer to the card
    top: theme.spacing(2.5),
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  collapsedContent: {
    width: 80,
    "& $buttonOutlined": {
      minWidth: "unset",
      width: "100%",
      justifyContent: "center",
      padding: theme.spacing(1),
    },
  },
  expandedContent: {
    width: "100%",
  },
  userInfoSection: {
    display: "flex",
    gap: theme.spacing(2),
    padding: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  idText: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  statusChip: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
  },
}));
