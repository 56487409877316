import { ToWords } from "to-words";
import { getFile } from "../../../../LeadState";
import { fileupload } from "../../../../../material_pickup_request/MprState";
import { addSignature } from "../../../../LeadState";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { quotationColumns } from "./constants";

export const getFiles = (file) => {
  let bodyData = {
    fileName: file.fileName || file.filename,
    folderName: file.folderName || file.folder || file.FolderName,
    expireLimt: 1000,
  };
  getFile(bodyData).then((response) => {
    window.open(response.reference, "_blank");
  });
};

export const quotationValueInWords = (totalAmount, quotationValue) => {
  // Return early if invalid inputs
  if (totalAmount == null) {
    return quotationValue ? "Invalid Amount" : "";
  }

  try {
    // Round to 2 decimal places and convert to number
    const parsedAmount = Number(totalAmount.toFixed(2));

    // Validate the parsed amount
    if (!Number.isFinite(parsedAmount) || parsedAmount < 0) {
      throw new Error("Invalid amount");
    }

    // Convert to words
    const toWords = new ToWords();
    const inrWords = toWords.convert(parsedAmount, { currency: true });

    // Format the output
    return inrWords.replace(/^Rupees\s+/, "Rupees ");
  } catch (error) {
    // Handle any errors in conversion
    return quotationValue ? "Invalid Amount" : "";
  }
};

export const handleFileUpload = async ({
  fileData,
  leadId,
  abbrevation,
  formType,
  itemType,
  onSuccess,
  onError,
  setLoading,
}) => {
  // Validate file type
  const validTypes = ["image/png", "image/jpeg", "image/jpg"];
  if (!validTypes.includes(fileData.type)) {
    toast.error("Please upload Image");
    return;
  }

  try {
    setLoading?.(true);

    // Prepare file data
    const fileType = fileData.type.split("/");
    const baseData = fileData.base64.split(",");

    // Token data for upload
    const tokenData = {
      abbrevation,
      fileType: "assets",
      folderName: leadId,
      expireLimt: 1800,
      // extension: fileType[1] || "",
    };

    // Upload file
    const uploadResponse = await fileupload(
      // baseData[1] || "",
      tokenData,
      fileData.type
    );

    if (uploadResponse?.status === "200") {
      const signatureBody = {
        thisItem: itemType,
        thisImage: {
          fileName: uploadResponse.fileName || uploadResponse.filename,
          folderName:
            uploadResponse.folderName ||
            uploadResponse.folder ||
            uploadResponse.FolderName,
        },
        thisLead: leadId,
        thisForm: formType,
      };

      // Add signature
      const signatureResponse = await addSignature(signatureBody);

      if (signatureResponse?.status === "200") {
        toast.success("Uploaded successfully");
        onSuccess?.({
          fileName: uploadResponse.fileName,
          folderName: uploadResponse.folderName,
        });
      } else {
        toast.error(signatureResponse.message);
      }
    }
  } catch (error) {
    onError?.(error);
    toast.error("Upload failed");
  } finally {
    setLoading?.(false);
  }
};

export const handleTemplateDownload = () => {
  const template = XLSX.utils.book_new();
  const headers = quotationColumns.map((col) => col.label);

  const templateDataWithRightNotes = [
    [...headers, "INSTRUCTIONS:"],
    [
      "1",
      "Keyboards",
      "200",
      "pics",
      "8",
      "1600",
      "• If Rate/Item exists in CRM for an item, it will be auto-populated (you don't have to enter it)",
    ],
    [
      "",
      "",
      "",
      "",
      "",
      "",
      "• Serial Number (Sl No) will be auto-adjusted after upload",
    ],
    ["", "", "", "", "", "", "• Please do not modify the column headers"],
    ["", "", "", "", "", "", "• Unit column is for units (eg: kg, pics etc)"],
    ["", "", "", "", "", "", "• Amount column will be auto-calculated"],
    ["", "", "", "", "", "", "• A sample item is provided for reference"],
  ];

  const templateData = templateDataWithRightNotes;

  const ws = XLSX.utils.aoa_to_sheet(templateData);

  // Style the headers
  headers.forEach((_, index) => {
    const cell_address = XLSX.utils.encode_cell({ r: 0, c: index });
    ws[cell_address].s = {
      font: {
        bold: true,
        color: { rgb: "000000" },
      },
      fill: {
        fgColor: { rgb: "CCCCCC" },
      },
    };
  });

  // Style right-side notes
  for (let R = 0; R <= 5; R++) {
    const cell_address = XLSX.utils.encode_cell({ r: R, c: 7 }); // Column H
    if (!ws[cell_address]) continue;

    ws[cell_address].s = {
      font: {
        color: { rgb: "FF0000" },
        italic: true,
      },
    };
  }

  // Set column widths
  const colWidths = [
    { wch: 5 }, // Sl No (Column A)
    { wch: 40 }, // Item Details (Column B)
    { wch: 15 }, // Quantity (Column C)
    { wch: 10 }, // Unit(Column D)
    { wch: 15 }, // Rate/Item (Column E)
    { wch: 15 }, // Amount (Column F)
    { wch: 80 }, // INSTRUCTIONS: (Column H)
  ];

  ws["!cols"] = colWidths;

  XLSX.utils.book_append_sheet(template, ws, "Items Template");
  XLSX.writeFile(template, "quote_items_template.xlsx");
};
