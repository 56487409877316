import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Box,
  Grid,
  Divider,
  Chip,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import SickIcon from "@mui/icons-material/Sick";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import WcIcon from "@mui/icons-material/Wc";
import FactoryIcon from "@mui/icons-material/Factory";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { monthArray } from "../../../utils/helpers";
import {
  StyledCard,
  LeaveStatCard,
  StyledTableContainer,
  StatusChip,
} from "../styles";

import { policiesList } from "../data";

export const LeaveDash = ({ user, showHrManual, leaveData }) => {
  const formatLeaveData = (value) => (value !== undefined ? value : "N/A");

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={showHrManual ? 8 : 12}>
          <StyledCard>
            <Grid container spacing={2}>
              {/* Casual Leave Stats */}
              <Grid item xs={12} sm={6}>
                <LeaveStatCard>
                  <WorkOffIcon color="primary" />
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      Casual Leaves
                    </Typography>
                    <Box sx={{ display: "flex", gap: 3, mt: 1 }}>
                      <StatusChip
                        label={`${formatLeaveData(leaveData?.casualLeave?.entitled)} Entitled`}
                        variant="total"
                        size="small"
                      />
                      <StatusChip
                        label={`${formatLeaveData(leaveData?.casualLeave?.remaining)} Remaining`}
                        variant="available"
                        size="small"
                      />
                      <StatusChip
                        label={`${formatLeaveData(leaveData?.casualLeave?.used)} Used`}
                        variant="taken"
                        size="small"
                      />
                    </Box>
                  </Box>
                </LeaveStatCard>
              </Grid>
              {/* Emergency Leave Stats */}
              <Grid item xs={12} sm={6}>
                <LeaveStatCard>
                  <SickIcon color="primary" />
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      Emergency Leaves
                    </Typography>
                    <Box sx={{ display: "flex", gap: 3, mt: 1 }}>
                      <StatusChip
                        label={`${formatLeaveData(leaveData?.emergencyLeave?.entitled)} Entitled`}
                        variant="total"
                        size="small"
                      />
                      <StatusChip
                        label={`${formatLeaveData(leaveData?.emergencyLeave?.remaining)} Remaining`}
                        variant="available"
                        size="small"
                      />
                      <StatusChip
                        label={`${formatLeaveData(leaveData?.emergencyLeave?.used)} Used`}
                        variant="taken"
                        size="small"
                      />
                    </Box>
                  </Box>
                </LeaveStatCard>
              </Grid>
              {/* Marriage Leave only for office  */}
              {!user?.isFactoryEmployee && (
                <Grid item xs={12} sm={6}>
                  <LeaveStatCard>
                    <WcIcon color="primary" />
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary">
                        Marriage Leaves
                      </Typography>
                      <Box sx={{ display: "flex", gap: 3, mt: 1 }}>
                        <StatusChip
                          label={`${formatLeaveData(leaveData?.marriageLeave?.entitled)} Entitled`}
                          variant="total"
                          size="small"
                        />
                        <StatusChip
                          label={`${formatLeaveData(leaveData?.marriageLeave?.remaining)} Remaining`}
                          variant="available"
                          size="small"
                        />
                        <StatusChip
                          label={`${formatLeaveData(leaveData?.marriageLeave?.used)} Used`}
                          variant="taken"
                          size="small"
                        />
                      </Box>
                    </Box>
                  </LeaveStatCard>
                </Grid>
              )}
              {/* Privilege Leave Stats only for factory employees */}

              {user?.isFactoryEmployee && (
                <Grid item xs={12} sm={6}>
                  <LeaveStatCard>
                    <FactoryIcon color="primary" />
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary">
                        Privilege Leaves
                      </Typography>
                      <Box sx={{ display: "flex", gap: 3, mt: 1 }}>
                        <StatusChip
                          label={`${formatLeaveData(leaveData?.privilegeLeave?.entitled)} Entitled`}
                          variant="total"
                          size="small"
                        />
                        <StatusChip
                          label={`${formatLeaveData(leaveData?.privilegeLeave?.accumulated)} Accumulated`}
                          variant="total"
                          size="small"
                        />
                        <StatusChip
                          label={`${formatLeaveData(leaveData?.privilegeLeave?.remaining)} Remaining`}
                          variant="available"
                          size="small"
                        />
                        <StatusChip
                          label={`${formatLeaveData(leaveData?.privilegeLeave?.used)} Used`}
                          variant="taken"
                          size="small"
                        />
                      </Box>
                    </Box>
                  </LeaveStatCard>
                </Grid>
              )}
            </Grid>
            <Divider sx={{ my: 4 }} />
            <Typography variant="h6" gutterBottom>
              Extra leave's taken
            </Typography>
            <StyledTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell align="center">Days</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaveData?.extraLeave && leaveData.extraLeave.length > 0 ? (
                    leaveData.extraLeave.map((leave) => (
                      <TableRow key={leave._id}>
                        <TableCell>{monthArray[leave.month - 1]}</TableCell>
                        <TableCell align="center">
                          <Chip
                            label={leave.totalLeave}
                            color="error"
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2} align="center" sx={{ py: 3 }}>
                        <Typography color="textSecondary">
                          No data available
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </StyledCard>
        </Grid>

        {showHrManual && (
          <Grid item xs={12} md={4}>
            <StyledCard>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
              >
                <ArticleIcon color="primary" />
                <Typography variant="h6">HR Policy</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" paragraph>
              Please refer to the HR policy for detailed information about leave policies, holidays, as well as our values, culture, and goals at Binbag.
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "10" }}>
                {policiesList.map((policy) => (
                  <Link
                    href={policy.Link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: 0.5,
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                      pb: 1,
                    }}
                  >
                    {policy.Label} <LaunchOutlinedIcon fontSize="10" />
                  </Link>
                ))}
              </Box>
            </StyledCard>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
