// gst values
export const DEFAULT_CGST_PERCENTAGE = "9";
export const DEFAULT_CGST_AMOUNT = "0.00";
export const DEFAULT_SGST_PERCENTAGE = "9";
export const DEFAULT_SGST_AMOUNT = "0.00";
export const DEFAULT_IGST_PERCENTAGE = "0";
export const DEFAULT_IGST_AMOUNT = "0.00";
export const DEFAULT_REGION = "INR";

//table utilities
export const quotationColumns = [
  { label: "S.No", width: 50 },
  { label: "Item Details", width: 400 },
  { label: "Quantity", width: 100 },
  { label: "Unit", width: 100 },
  { label: "Rate/Item(₹)", width: 120 },
  { label: "Amount", width: 120 },
];

export const QUOTATION_NOTES = {
  GST_INCLUSIVE: [
    "Amount mentioned above is inclusive of tax.",
    "Actual amount payable will be based on the final quantity.",
    "Transportation and labour cost to be borne by Binbag.",
  ],
  GST_EXCLUSIVE: [
    "Amount mentioned above is exclusive of tax.",
    "Actual amount payable will be based on the final quantity.",
    "Transportation and labour cost to be borne by Binbag.",
  ],
};
