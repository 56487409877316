import React from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Divider,
  Paper,
  InputAdornment,
  Stack,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Typography } from "../../../components/Wrappers/Wrappers";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NotificationsIcon from "@mui/icons-material/Notifications";

const BasicDetails = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fields = [
    {
      label: t("customer_company_name"),
      name: "companyName",
      type: "text",
      icon: <BusinessIcon color="action" />,
    },
    {
      label: t("mpr_contact_person"),
      name: "contactName",
      type: "text",
      icon: <PersonIcon color="action" />,
    },
    {
      label: t("common_email"),
      name: "email",
      type: "email",
      icon: <EmailIcon color="action" />,
    },
    {
      label: t("common_mobile_no"),
      name: "mobile",
      type: "number",
      icon: <PhoneIcon color="action" />,
    },
    {
      label: t("common_pan"),
      name: "pan_no",
      type: "pan",
      icon: <ReceiptIcon color="action" />,
    },
    {
      label: t("common_gst"),
      name: "taxNo",
      type: "gst",
      icon: <ReceiptIcon color="action" />,
    },
    {
      label: t("common_notification"),
      name: "notificationAllowed",
      type: "checkbox",
      icon: <NotificationsIcon color="action" />,
    },
  ];

  const renderTextField = (item, field, error, helperText) => (
    <TextField
      variant="outlined"
      label={item.label}
      fullWidth
      margin="normal"
      {...field}
      error={error}
      helperText={helperText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{item.icon}</InputAdornment>
        ),
      }}
    />
  );

  return (
    <Paper elevation={0} sx={{ p: 3, backgroundColor: "transparent" }}>
      <Box mb={4} textAlign={"left"}>
        <Typography variant="h5" fontWeight="bold">
          {t("Customer Information")}
        </Typography>
        <Divider sx={{ mt: 2 }} />
      </Box>

      <Grid container spacing={3}>
        {fields.map((item, key) => (
          <React.Fragment key={key}>
            {(() => {
              switch (item.type) {
                case "text":
                  return (
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={control}
                        name={item.name}
                        rules={{
                          required: "This field is required",
                          minLength: 3,
                        }}
                        render={({ field }) =>
                          renderTextField(
                            item,
                            field,
                            Boolean(errors?.[item.name]),
                            errors[item.name]?.message ||
                              (errors[item.name]?.type === "minLength" &&
                                "Must have a minimum of 3 characters")
                          )
                        }
                      />
                    </Grid>
                  );

                case "email":
                  return (
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={control}
                        name={item.name}
                        rules={{
                          // required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        }}
                        render={({ field }) =>
                          renderTextField(
                            item,
                            field,
                            Boolean(errors?.[item.name]),
                            errors[item.name]?.message
                          )
                        }
                      />
                    </Grid>
                  );

                case "number":
                  return (
                    <Grid item xs={12} md={6}>
                      <Stack
                        direction="row"
                        spacing={2}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Box width="30%">
                          <Controller
                            control={control}
                            name="countryCode"
                            rules={{
                              required: "Required",
                              pattern: {
                                value: /^\+\d{1,4}$/,
                                message: "Invalid code",
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label={t("Code")}
                                margin="normal"
                                fullWidth
                                {...field}
                                error={Boolean(errors?.countryCode)}
                                helperText={errors?.countryCode?.message}
                              />
                            )}
                          />
                        </Box>
                        <Box width="70%">
                          <Controller
                            control={control}
                            name={item.name}
                            rules={{
                              pattern: {
                                value: /^\d{10}$/,
                                message: "Invalid phone number",
                              },
                            }}
                            render={({ field }) =>
                              renderTextField(
                                item,
                                field,
                                Boolean(errors?.[item.name]),
                                errors[item.name]?.message
                              )
                            }
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  );

                case "checkbox":
                  return (
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Controller
                          control={control}
                          name={item.name}
                          defaultValue={false}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  checked={!field.value}
                                  onChange={(e) =>
                                    field.onChange(!e.target.checked)
                                  }
                                  color="primary"
                                  disabled
                                />
                              }
                              label={
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  {item.icon}
                                  <Typography>
                                    {t("common_notification")}
                                  </Typography>
                                </Stack>
                              }
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  );

                default:
                  return (
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={control}
                        name={item.name}
                        render={({ field }) =>
                          renderTextField(
                            item,
                            field,
                            Boolean(errors?.[item.name]),
                            errors[item.name]?.message
                          )
                        }
                      />
                    </Grid>
                  );
              }
            })()}
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
};

export default BasicDetails;
