import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Button,
  Radio,
  CircularProgress,
  Typography,
  Box,
  Avatar,
  Card,
  CardContent,
  IconButton,
  Alert,
  Collapse,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Add as AddIcon,
  Close as CloseIcon,
  Person as PersonIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";

import { assignLead, getLeadList } from "../../LeadState";

import Forms from "../../../contacts/components/Forms";

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  marginTop: theme.spacing(-3),
}));

const ContactCard = styled(Card)(({ theme, selected }) => ({
  cursor: "pointer",
  transition: "all 0.2s ease-in-out",
  border: selected
    ? `2px solid ${theme.palette.primary.main}`
    : `1px solid ${theme.palette.divider}`,
  backgroundColor: selected
    ? alpha(theme.palette.primary.main, 0.04)
    : theme.palette.background.paper,
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: theme.shadows[3],
  },
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

const AssignLead = ({ list, hide, lead }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.userdata);
  const contactList = useSelector((state) => state.tenantContactList);

  const [selectedUser, setSelectedUser] = useState(list.assigned_to);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const fetchContacts = async () => {
      if (data) {
        const response = await getLeadList(data.getData.customerid);
        if (response.status === "200" && response.result) {
          dispatch({
            type: "SET_TENANT_CONTACT_LIST",
            payload: response.result.allUsers,
          });
        }
      }
    };
    fetchContacts();
  }, [showForm, data, dispatch]);

  const handleAssign = async () => {
    if (!selectedUser) {
      setNotification({
        show: true,
        type: "error",
        message: "Please select a user to assign",
      });
      return;
    }

    setLoading(true);
    try {
      if (lead) {
        const response = await assignLead(
          {
            external_id: lead,
            leadData: {
              status: "assigned",
              assigned_to: selectedUser,
            },
            mpr_id: list.Mpr_id,
          },
          dispatch
        );

        if (response.status === "200") {
          setNotification({
            show: true,
            type: "success",
            message: "Lead successfully assigned",
          });
          setTimeout(hide, 2000);
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      setNotification({
        show: true,
        type: "error",
        message: "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };

  const formatContactInfo = (contact) => {
    return `${contact.userName}, ${contact.email}, ${contact.mobile.code}${contact.mobile.number}`;
  };

  return (
    <StyledPaper>
      <Collapse in={notification.show}>
        <Alert
          severity={notification.type}
          action={
            <IconButton
              size="small"
              onClick={() => setNotification({ ...notification, show: false })}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {notification.message}
        </Alert>
      </Collapse>

      <HeaderSection>
        <Typography variant="h6" color="textSecondary">
          {list && list.assigned_to === "" ? "Assign Lead" : "Lead Assigned To"}
        </Typography>
        {!showForm && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setShowForm(true)}
          >
            Add Contact
          </Button>
        )}
      </HeaderSection>

      {showForm ? (
        <Forms close={() => setShowForm(false)} where="modal" />
      ) : (
        <Grid container spacing={2}>
          {contactList.map((contact) => (
            <Grid item xs={12} md={6} key={contact._id}>
              <ContactCard
                selected={selectedUser === contact._id}
                onClick={() => setSelectedUser(contact._id)}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Avatar
                      sx={{
                        bgcolor:
                          selectedUser === contact._id
                            ? "primary.main"
                            : "grey.200",
                      }}
                    >
                      <PersonIcon />
                    </Avatar>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="subtitle1">
                        {data.getData && data.getData._id === contact._id
                          ? "Assign to me"
                          : contact.userName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatContactInfo(contact)}
                      </Typography>
                    </Box>
                    <Radio
                      checked={selectedUser === contact._id}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  </Box>
                </CardContent>
              </ContactCard>
            </Grid>
          ))}
        </Grid>
      )}

      {!showForm && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            mt: 3,
          }}
        >
          <Button variant="outlined" onClick={hide}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAssign}
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Assign
          </Button>
        </Box>
      )}
    </StyledPaper>
  );
};

export default AssignLead;
