import { apiUrl } from "../../data/config";

export async function createLeaveRequest(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/leaveRequest";

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getAllLeaveRequests(data) {
  let url =
    apiUrl +
    `/v1/tenant/getAllLeaveRequests?page=${data.page}&limit=${data.limit}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getAllEmployessLeavesInfo(data) {
  let url = apiUrl + `/v1/getLeaveData`;
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getDashboardData(data) {
  let url = apiUrl + "/v1/tenant/dashboard";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getEmployeeDashboardData(data) {
  let url = apiUrl + "/v1/tenant/employeeDashboard";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function actionOnLeaveRequest(formData, dispatch) {
  let url = apiUrl + "/v1/tenant/actionOnLeaveRequest";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function uploadEmployeeDocuments(formData) {
  let url = apiUrl + "/v1/updateEmployeeDocuments";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getEmployees(data) {
  let url = apiUrl + "/v1/getAllEmployees";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
}
