import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import UploadFiles from "../../../../../../components/FileUploads/UploadFiles";

const InspectionCompletionDialog = ({
  open,
  onClose,
  onSubmit,
  selectedSlot,
  isLoading,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [formData, setFormData] = useState({
    notes: "",
    images: [],
    feedback: "",
    slotDetails: selectedSlot || null,
  });
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleSubmit = () => {
    onSubmit({
      ...formData,
      completedAt: new Date().toISOString(),
      slotDetails: selectedSlot,
    });
  };

  const handleUploadedFiles = (files) => {
    const filteredFiles = files.filter((item) => Object.keys(item).length > 0);
    const filesWithoutRef = filteredFiles.map((item) => {
      const { ref, ...newItem } = item;
      return newItem;
    });
    setUploadedFiles(filesWithoutRef);
    setFormData((prev) => ({ ...prev, images: filesWithoutRef }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 2, p: 1, maxWidth: 800 },
      }}
    >
      <DialogTitle sx={{ fontSize: "1.25rem", fontWeight: 600 }}>
        Complete Inspection
      </DialogTitle>

      <DialogContent sx={{ pt: 2 }}>
        {selectedSlot && (
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Completing inspection for slot: {selectedSlot.inspectionDate} at{" "}
            {selectedSlot.time}
          </Typography>
        )}

        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Box>
            <UploadFiles
              fileUploadData={handleUploadedFiles}
              setIsUploading={setUploadingFile}
              fileUploadType={"inspection-images"}
              title={"Upload Images"}
            />
          </Box>

          <Box>
            <Typography sx={{ fontWeight: 500, mb: 1 }}>
              Inspection notes
            </Typography>
            <TextField
              multiline
              rows={4}
              fullWidth
              placeholder="Enter detailed inspection notes..."
              value={formData.notes}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, notes: e.target.value }))
              }
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={uploadingFile || isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={uploadingFile || isLoading}
        >
          {isLoading ? (
            <>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              Saving...
            </>
          ) : (
            "Complete Inspection"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InspectionCompletionDialog;
