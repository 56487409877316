import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
  Container,
  Typography,
  Tooltip,
  Divider,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import Table from "../../components/Table/Table";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { StyledCard, StatusChip } from "./styles";
import { getEmployees, updateContact } from "./ContactState";
import toast from "react-hot-toast";

const ROLES = {
  ADMIN: "Admin",
  TMANAGER: "Tmanger",
  VIEWER: "Viewer",
  APPROVER: "Approver",
  AUTHORIZER: "Authorizer",
};

export default function Contacts() {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [reload, setReload] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userdata);

  const updatedById = userData?.getData?._id;
  const isCustomer = localStorage.getItem("type") !== "tenant";

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    {
      label: t("common_admin"),
      link: isCustomer ? "/#/app/customeradmin" : "/#/app/admin",
    },
    { label: isCustomer ? t("common_contact") : "Employees", link: "" },
  ];

  const datatableHeader = [
    {
      label: t("Name"),
      name: "contact_name",
      options: { filter: true },
    },
    { label: t("common_email"), name: "email", options: { filter: true } },
    { label: t("Contact"), name: "mobile", options: { filter: true } },

    { label: t("Designation"), name: "designation", options: { filter: true } },
    { label: t("Department"), name: "department", options: { filter: true } },
    {
      label: t("Factory employee"),
      name: "factory_employee",
      options: { filter: true },
    },
    { label: t("Roles"), name: "roles", options: { filter: true } },
    { label: t("common_status"), name: "status", options: { filter: true } },
    { label: t("common_action"), name: "action", options: { filter: true } },
  ];

  const tableOptions = {
    filterType: "checkbox",
    print: false,
    download: false,
    filter: false,
    selectableRows: "none",
    elevation: 0,
    responsive: "standard",
    rowsPerPageOptions: [10, 25, 50],
    textLabels: {
      body: {
        noMatch: showLoader ? (
          <Box display="flex" justifyContent="center" alignItems="center" p={3}>
            <CircularProgress size={40} thickness={4} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" p={3}>
            <PersonIcon sx={{ fontSize: 48, color: "text.secondary", mb: 1 }} />
            <Typography variant="body1" color="text.secondary">
              No employees found
            </Typography>
          </Box>
        ),
      },
    },
  };

  useEffect(() => {
    const fetchContacts = async () => {
      setShowLoader(true);
      try {
        const sendData = {
          abbrevation: userData?.getData?.abbrevation,
          customerid: localStorage.getItem("customerid"),
        };
        const response = await getEmployees(sendData);
        setContacts(response?.data || []);
      } catch (error) {
        toast.error("Failed to fetch contacts");
      } finally {
        setShowLoader(false);
      }
    };

    fetchContacts();
  }, [reload, dispatch, userData]);

  const handleRoleToggle = async (contactId, role, currentRoles) => {
    try {
      const hasRole = currentRoles?.includes(role);
      const updatedRoles = hasRole
        ? currentRoles.filter((r) => r !== role)
        : [...(currentRoles || []), role];

      const result = await updateContact(
        { contactId, updatedById },
        updatedRoles
      );

      if (result.status === "200") {
        toast.success("Role Updated");
        setReload(updatedRoles);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (error) {
      toast.error(`Error updating role: ${error.message}`);
    }
  };

  const RoleSwitch = ({ role, isChecked, isDisabled, onChange }) => (
    <FormControlLabel
      control={
        <Switch
          checked={isChecked}
          onChange={onChange}
          disabled={isDisabled}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: (theme) => theme.palette.primary.main,
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }}
        />
      }
      label={
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {role}
        </Typography>
      }
    />
  );

  const generateTableData = () =>
    contacts.map((contact) => {
      const {
        _id,
        userName,
        email,
        mobile,
        isActive,
        roles,
        department = [],
        employeeData,
      } = contact;
      const isAdmin = roles.includes(ROLES.ADMIN);

      return [
        <Box display="flex" alignItems="center">
          <Typography variant="body2" fontWeight={500}>
            {userName}
          </Typography>
        </Box>,
        <Typography variant="body2">{email}</Typography>,
        <Typography variant="body2">{mobile?.number || "N/A"}</Typography>,

        <Typography variant="body2">{employeeData.designation}</Typography>,
        <Typography variant="body2">
          {department.map((dept) => dept).join(", ")}
        </Typography>,
        <StatusChip
          label={employeeData.isFactoryEmployee ? "Yes" : "No"}
          status={employeeData.isFactoryEmployee ? "Yes" : "No"}
        />,
        <FormGroup>
          {Object.values(ROLES)
            .filter((role) => role !== ROLES.ADMIN)
            .map((role) => (
              <RoleSwitch
                key={role}
                role={role}
                isChecked={roles.includes(role)}
                isDisabled={isAdmin && role === ROLES.TMANAGER}
                onChange={() => handleRoleToggle(_id, role, roles)}
              />
            ))}
        </FormGroup>,
        <StatusChip
          label={isActive ? "Active" : "Inactive"}
          status={isActive ? "Active" : "Inactive"}
        />,
        <Tooltip title="Edit Employee or View employee files">
          <IconButton
            onClick={() => {
              dispatch({ type: "SET_SELECTED_USER", payload: contact });
              history.push("/app/addEmployee");
            }}
            sx={{
              color: "primary.main",
              "&:hover": {
                backgroundColor: "primary.lighter",
              },
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>,
      ];
    });

  return (
    <Container maxWidth="xl" sx={{ pt: 10 }}>
      <Box mb={3}>
        <BreadCrumbs data={breadcrumb} />
      </Box>

      <StyledCard>
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Typography variant="h5" fontWeight="bold">
              Employees
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() =>
                history.push(
                  isCustomer ? "/app/addContact" : "/app/addEmployee"
                )
              }
              style={{ textTransform: "none" }}
            >
              Add Employee
            </Button>
          </Box>
          <Divider sx={{ mb: 3 }} />
          <Table
            data={generateTableData()}
            header={datatableHeader}
            options={tableOptions}
          />
        </Box>
      </StyledCard>
    </Container>
  );
}
