import { apiUrl } from "../../data/config";

export function getLeadList(tenantID, dispatch) {
  let url = apiUrl + "/v1/tenant/allLead";
  let bodyData = {
    tenant: tenantID,
  };
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        if (data.status === "200") {
          return data;
        } else if (data.status === "500") {
          dispatch({
            type: "LOG_OUT",
          });
          localStorage.clear();
          return data;
        } else if (data.status === "401") {
          dispatch({
            type: "LOG_OUT",
          });
          localStorage.clear();
          return data;
        }
      } else {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      }
    });
  });
}

export function getLead(leadId, dispatch) {
  let url = apiUrl + "/v1/tenant/viewLead";
  let bodyData = {
    leadId: leadId,
  };
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      }
    });
  });
}

export function assignLead(data, dispatch) {
  let url = apiUrl + "/v1/tenant/actionOnLead";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        if (data.status === "200") {
          return data;
        } else if (data.status === "500") {
          return {
            message: data.message,
            status: data.status,
            gst: data.gst,
            pan: data.pan,
            customerId: data.customerID,
          };
        } else if (data.status === "401") {
          dispatch({
            type: "LOG_OUT",
          });
          localStorage.clear();
          return data;
        }
      } else {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      }
    });
  });
}
export function quotationDraft(data, dispatch) {
  let url = apiUrl + "/v1/tenant/quoteDraft";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      }
    });
  });
}

export function getInvoiceGenId(data) {
  let url = apiUrl + "/v1/tenant/invoiceIdGenerate";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function getFile(data) {
  let url = apiUrl + "/v1/filegetAWS";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function form6generation(data) {
  let url = apiUrl + "/v1/tenant/form6generation";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}
export function invoiceGeneration(data) {
  let url = apiUrl + "/v1/tenant/invoicegeneration";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function quotationGeneration(data) {
  let url = apiUrl + "/v1/tenant/quotationGeneration";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function quotationEdit(data) {
  let url = apiUrl + "/v1/tenant//quotationUpdate";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function getEditQuotation(data) {
  let url = apiUrl + "/v1/tenant/getQuotationDetails";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function getQuotationGenId(data) {
  let url = apiUrl + "/v1/tenant/quotationIdGenerate";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function form6Update(data, dispatch) {
  let url = apiUrl + "/v1/tenant/form6update";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      }
    });
  });
}

export function addSignature(data, dispatch) {
  let url = apiUrl + "/v1/tenant/addSignature";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      }
    });
  });
}

export function updatePanGst(data, dispatch) {
  let url = apiUrl + "/v1/customer/saveCustomerUpdate";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      }
    });
  });
}

export function sendForm6(data, dispatch) {
  let url = apiUrl + "/v1/tenant/sentform6";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      }
    });
  });
}

export function getAllAccounts() {
  let url = apiUrl + "/v1/tenant/getBankDetails";
  const request = fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        if (data.status === "200") {
          return data;
        } else {
          return data;
        }
      });
    }
  });
  return request;
}

export function rejectAllLead(data, dispatch) {
  let url = apiUrl + "/v1/tenant/quoteAccept";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      }
    });
  });
}

export function sendQuoteViaEmail(data, dispatch) {
  let url = apiUrl + "/v1/tenant/sendQuotation";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      }
    });
  });
}
