import React from "react";
import { Typography } from "../../../components/Wrappers/Wrappers";
import { Grid, Button, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import ViewMPUsers from "../components/ViewMPUsers";

const MPUsers = () => {
  const { t } = useTranslation();
  var classes = useStyles();
  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: "Marketplace", link: "/#/app/marketplace" },
    { label: t("Buyers"), link: "" },
  ];

  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <div className={classes.HeaderContainer}>
                  <div className={classes.tenantHeader}>
                    <Typography variant="h3" size="sm">
                      {" "}
                      {t("Buyers")}{" "}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 4,
                      marginTop: "10px",
                    }}
                  >
                    <div>
                      <Button
                        variant="outlined"
                        href="/#/app/addMPUser"
                        color="primary"
                        className={classes.tagButton}
                      >
                        {t("Add Buyer")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ViewMPUsers />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default MPUsers;
