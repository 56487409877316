import React, { useState, useEffect } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import useStyles from "../login/styles";
import toast, { Toaster } from "react-hot-toast";
import PasswordSet from "../components/PasswordSet";
import RefreshToken from "../components/RefreshToken";
import { ValidateToken } from "../LoginState";
import { Player } from "@lottiefiles/react-lottie-player";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PasswordReset(props) {
  var classes = useStyles();
  let query = useQuery();

  var [email, setEmail] = useState("");
  var [reset, showReset] = useState(true);
  var [data, setData] = useState(null);

  let history = useHistory();

  useEffect(() => {
    const token = query.get("token");
    if (!token) {
      history.push("/#/login");
    } else {
      ValidateToken(token).then((response) => {
        if (response) {
          if (response.status === "500") {
            toast.error(response.Message);
            setTimeout(() => {
              history.push("/#/login");
            }, 2000);
          } else if (response.status === "401") {
            toast.error(response.Message);
            showReset(false);
            setEmail(response.data.email);
            setData(response.data);
          } else {
            setEmail(response.data.email);
            setData(response.data);
          }
        }
      });
    }
  }, []);

  return (
    <div className={classes.container}>
      <Toaster />
      <Box alignSelf={"flex-start"} className={classes.logo}>
        <a href="https://my.binbag.in">
          <img
            src="assets/img/binbagLogo.png"
            alt="logo"
            style={{ width: "40%", height: "auto" }}
          />
        </a>
      </Box>

      <div className={classes.box}>
        <Paper className={classes.paperContainer1} elevation={2}>
          <div className={classes.formContainer}>
            {reset ? (
              <PasswordSet email={email} />
            ) : (
              <RefreshToken data={data} />
            )}
          </div>
        </Paper>
        <Paper className={classes.paperContainer2} elevation={2}>
          <Player
            autoplay
            loop
            src="/assets/animations/lock.json"
            style={{ height: "300px", width: "300px" }}
          ></Player>
        </Paper>
      </div>

      <Typography className={classes.copyright}>
        ©2025, Binbag Recycling Services Pvt Ltd. All Rights Reserved.
      </Typography>
    </div>
  );
}

export default withRouter(PasswordReset);
