import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Link,
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SectionTitle = ({ icon, title }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      mb: 2,
      borderBottom: "2px solid #f0f0f0",
      pb: 1,
    }}
  >
    {icon}
    <Typography variant="h6" sx={{ ml: 1 }}>
      {title}
    </Typography>
  </Box>
);

const InfoRow = ({ label, value }) => (
  <Box
    sx={{
      display: "flex",
      py: 1.5,
      borderBottom: "1px solid #f5f5f5",
      "&:last-child": { borderBottom: "none" },
    }}
  >
    <Typography
      sx={{
        width: "200px",
        color: "text.secondary",
        fontWeight: 500,
      }}
    >
      {label}:
    </Typography>
    <Typography sx={{ flex: 1 }}>{value}</Typography>
  </Box>
);

const ReviewDetails = ({ setForm, formData }) => {
  const { t } = useTranslation();
  var stateMpr = useSelector((state) => state.mprid);
  formData.MPRId = formData.MPRId ? formData.MPRId : stateMpr;
  var address = formData.contact_address
    ? JSON.parse(formData.contact_address)
    : "";
  var person = formData.contact_contact
    ? JSON.parse(formData.contact_contact)
    : "";
  const [finalserviceType, setFinalServiceType] = useState([]);
  const [UlField, setUlField] = useState(false);
  const [otherType, setOtherType] = useState([]);

  useEffect(() => {
    let getFinalServiceType =
      formData.service_type[formData.service_type.length - 1];
    setFinalServiceType(getFinalServiceType);
    setOtherType(formData.other_service_type);
    setUlField(localStorage.getItem("type") === "tenant");
  }, []);

  return (
    <Box sx={{ maxWidth: "1200px", margin: "0 auto" }}>
      <Paper
        elevation={0}
        sx={{ p: 4, borderRadius: 2, backgroundColor: "#fff" }}
      >
        <Typography variant="h5" sx={{ mb: 4, fontWeight: 600 }}>
          {t("common_review_details")}
        </Typography>

        {/* Basic Details Section */}
        <Card sx={{ mb: 4, boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
          <CardContent>
            <SectionTitle
              icon={<DescriptionIcon sx={{ color: "primary.main" }} />}
              title={t("common_basic_details")}
            />
            <Box sx={{ px: 2 }}>
              {/* <InfoRow label={t("mpr_id")} value={formData.MPRId} /> */}
              <InfoRow label={t("mpr_name")} value={formData.MPRName || "N/A"} />
              <InfoRow
                label={t("common_description")}
                value={formData.description || "N/A"}
              />
              <InfoRow
                label={t("common_service_category")}
                value={formData.service_category}
              />
              <InfoRow
                label={t("common_service_type")}
                value={
                  finalserviceType
                    ? finalserviceType.join(", ").replace("Others", "") +
                      otherType
                    : formData.service_type
                }
              />
              {UlField && (
                <InfoRow
                  label={t("mpr_inquiry")}
                  value={formData.inquirylead_id || "N/A"}
                />
              )}
            </Box>
          </CardContent>
        </Card>

        {/* Material Details Section */}
        <Card sx={{ mb: 4, boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
          <CardContent>
            <SectionTitle
              icon={<DescriptionIcon sx={{ color: "primary.main" }} />}
              title={t("mpr_material_details")}
            />
            <Grid container spacing={4} sx={{ px: 2 }}>
              {[
                { files: formData.files, title: "Documents" },
                { files: formData.goodsPic, title: "Images" },
              ].map((section, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    {section.title}
                  </Typography>
                  {section.files.length > 0 ? (
                    section.files.map((file, fileIndex) => (
                      <Box
                        key={fileIndex}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          p: 1.5,
                          mb: 1,
                          border: "1px solid #e0e0e0",
                          borderRadius: 1,
                        }}
                      >
                        <Typography variant="body2" sx={{ flex: 1 }}>
                          {file.fileName}
                        </Typography>
                        <Tooltip title={"View"}>
                          <IconButton
                            component={Link}
                            href={file.ref}
                            target="_blank"
                            size="small"
                          >
                            <AttachFileIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ))
                  ) : (
                    <Typography color="text.secondary">
                      {t("mpr_no_file_found")}
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>

        {/* Contact Details Section */}
        <Card sx={{ boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
          <CardContent>
            <SectionTitle
              icon={<PersonIcon sx={{ color: "primary.main" }} />}
              title={"Contact Details"}
            />
            <Box sx={{ px: 2 }}>
              <InfoRow
                label={"Pickup Address"}
                value={
                  address !== ""
                    ? `${address.address.address1}${
                        address.address.address2
                          ? `, ${address.address.address2}, `
                          : ", "
                      }${address.district}, ${address.state}, ${address.pincode}`
                    : ""
                }
              />
              <InfoRow
                label={t("mpr_contact_person")}
                value={
                  person !== ""
                    ? `${person.Name}, ${person.mobile.number}, ${person.email}`
                    : ""
                }
              />
            </Box>
          </CardContent>
        </Card>

        {/* Inspection Slots Section */}
        {/* <Card sx={{ mb: 4, boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
          <CardContent>
            <SectionTitle
              icon={<AccessTimeIcon sx={{ color: "primary.main" }} />}
              title={t("common_inspection_slots")}
            />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                px: 2,
              }}
            >
              {formData.slots.length > 0 ? (
                formData.slots.map((slot, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: 2,
                      border: "1px solid #e0e0e0",
                      borderRadius: 1,
                      minWidth: "200px",
                    }}
                  >
                    <Typography variant="subtitle2" color="primary">
                      Slot {index + 1}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {moment(slot.inspectionDate).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {slot.time}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography color="text.secondary">
                  {t("mpr_no_slots_found")}
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card> */}
      </Paper>
    </Box>
  );
};

export default ReviewDetails;
