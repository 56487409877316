import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Graph from "../components/charts/Graph";
import DashCard from "../components/DashCard";
import { getDashboardData } from "../InternalFormState";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import useStyles from "../styles";
import toast from "react-hot-toast";

export const AdminDash = () => {
  var classes = useStyles();
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const currentYear = new Date().getFullYear();
  const loggedInUser = useSelector((state) => state.userdata.getData);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [supplierDash, setSupplierDash] = useState([]);
  const [soldSupplierDash, setSoldSupplierDash] = useState([]);
  const [vendorDash, setVendorDash] = useState([]);
  const [reimbursementDash, setReimbursementDash] = useState([]);
  const [miscellaneousDash, setMiscellaneousDash] = useState([]);
  const [pendingData, setPendingData] = useState({});
  const [loader, setloader] = useState(true);
  const [supplierPayments, setSupplierPayments] = useState({});
  const [vendorPayments, setVendorPayments] = useState({});
  const [reimbursementPayments, setReimbursementPayments] = useState({});
  const [miscellaneousPayments, setMiscellaneousPayments] = useState({});

  const graphsData = [
    {
      title: "Supplier payments",
      data: supplierDash,
      soldAmount: soldSupplierDash,
      key: "supplier-graph",
    },
    { title: "Vendor payments", data: vendorDash, key: "vendor-graph" },
    {
      title: "Reimbursements",
      data: reimbursementDash,
      key: "reimbursement-graph",
    },
    {
      title: "Miscellaneous payments",
      data: miscellaneousDash,
      key: "miscellaneous-graph",
    },
  ];


  const startYear = 2024;

  const availableYearsData = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );

  

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    getTheDashboardData();
  }, [selectedYear]);

  async function getTheDashboardData() {
    setloader(true);

    const processPayments = (paymentData) => {
      const amountsForYear = new Array(12).fill(0);
      const soldAmountForYear = new Array(12).fill(0);
      paymentData.months?.forEach(({ month, total_amount, sold_amount }) => {
        amountsForYear[month - 1] = total_amount;
        soldAmountForYear[month - 1] = sold_amount;
      });
      return {
        total: paymentData?.total,
        pending: paymentData?.pending,
        approved: paymentData?.approved,
        partial: paymentData?.partial,
        completed: paymentData?.completed,
        sold: paymentData?.sold,
        closed: paymentData?.closed,
        "advance disbursed": paymentData?.["advance disbursed"],
        "expense report submitted": paymentData?.["expense report submitted"],
        "reupload expense report": paymentData?.["reupload expense report"],
        "payable to user": paymentData?.["payable to user"],
        "receivable by company": paymentData?.["receivable by company"],
        amounts: amountsForYear,
        soldAmount: soldAmountForYear,
      };
    };

    try {
      const data = { year: selectedYear };
      const response = await getDashboardData(data);

      if (response.status !== "200") {
        toast.error("An error occurred. Please try again.");
      }

      const {
        supplier_payment,
        vendor_payment,
        miscellaneous_payment,
        reimbursement_payment,
      } = response.data;

      const supplierPayments = processPayments(supplier_payment);
      const vendorPayments = processPayments(vendor_payment);
      const miscellaneousPayments = processPayments(miscellaneous_payment);
      const reimbursementPayments = processPayments(reimbursement_payment);

      setSupplierDash(supplierPayments.amounts);
      setSoldSupplierDash(supplierPayments.soldAmount);
      setVendorDash(vendorPayments.amounts);
      setReimbursementDash(reimbursementPayments.amounts);
      setMiscellaneousDash(miscellaneousPayments.amounts);
      setPendingData({
        pendingSupplierPayments: supplierPayments.pending,
        approvedSupplierPayments: supplierPayments.approved,
        completedSupplierPayments: supplierPayments.completed,
        partiallyPaidSupplierPayments: supplierPayments.partial,
        pendingVendorPayments: vendorPayments.pending,
        approvedVendorPayments: vendorPayments.approved,
        pendingMiscellaneousPayments: miscellaneousPayments.pending,
        approvedMiscellaneousPayments: miscellaneousPayments.approved,
        pendingReimbursementPayments: reimbursementPayments.pending,
        approvedReimbursementPayments: reimbursementPayments.approved,
      });

      setSupplierPayments(supplierPayments);
      setVendorPayments(vendorPayments);
      setMiscellaneousPayments(miscellaneousPayments);
      setReimbursementPayments(reimbursementPayments);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setloader(false);
    }
  }

  const formatNumber = (value) => {
    if (value >= 10000000) {
      return `${(value / 10000000).toFixed(2)}Cr`;
    } else if (value >= 100000) {
      return `${(value / 100000).toFixed(2)}L`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(2)}k`;
    }
    return value;
  };

  const YearDropdown = ({
    selectedYear,
    handleChangeYear,
    availableYearsData,
  }) => (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="year-select-label">Year</InputLabel>
        <Select
          labelId="year-select-label"
          id="year-select"
          value={selectedYear}
          label="Year"
          onChange={handleChangeYear}
        >
          {availableYearsData.map((year) => (
            <MenuItem value={year} key={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );

  return (
    <>
      <div style={{ margin: "14px" }}>
        {loader ? (
          <div className={classes.dashLoader} style={{ height: "100vh" }}>
            <CircularProgress sx={{ marginRight: "6px" }} />
          </div>
        ) : (
          <>
            <Box className={classes.dashBody} sx={{ paddingTop: { xs: 1 } }}>
              <Typography variant="h5">Payments dashboard</Typography>
              <YearDropdown
                selectedYear={selectedYear}
                handleChangeYear={handleChangeYear}
                availableYearsData={availableYearsData}
              />
            </Box>

            <div style={{ margin: "20px 0" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DashCard
                    title="Supplier"
                    data={supplierPayments}
                    loggedinUser={loggedInUser}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashCard
                    title="Vendor"
                    data={vendorPayments}
                    loggedinUser={loggedInUser}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashCard
                    title="Reimbursements"
                    data={reimbursementPayments}
                    loggedinUser={loggedInUser}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashCard
                    title="Miscellaneous"
                    data={miscellaneousPayments}
                    loggedinUser={loggedInUser}
                  />
                </Grid>
              </Grid>
            </div>

            <div style={{ margin: "20px 0" }}>
              {!isSmallScreen && (
                <>
                  <Typography variant="h5" gutterBottom>
                    Overview
                  </Typography>
                  <Grid container spacing={2}>
                    {graphsData.map(({ title, data, soldAmount, key }) => (
                      <Grid item lg={6} md={12} sm={12} xs={11} key={key}>
                        <Card sx={{ height: 400 }}>
                          <Graph
                            title={title}
                            amountData={data}
                            yaxisLabel={"Amount"}
                            dataLabels={true}
                            formatter={formatNumber}
                            soldData={soldAmount}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
