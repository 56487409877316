import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  Typography,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import { createMprId } from "../MprState";
import { useHistory } from "react-router-dom";

const BasicDetails = ({ setForm, formData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  var data = useSelector((state) => state.userdata);
  var custData = useSelector((state) => state);
  var branchData = custData.selectedBranch ? custData.selectedBranch._id : "";
  const [MPRName, setMPRName] = useState(formData.MPRName);
  const [description, setDescription] = useState(formData.description);
  const [otherServiceType, setOtherServiceType] = useState(
    formData.other_service_type
  );
  const [inquiryLeadId, setInquiryLeadId] = useState(formData.inquirylead_id);
  const [mprID, setMprID] = useState(null);
  const [UlField, setUlField] = useState(false);
  const [servicecat, setServicecat] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [otherType, setOtherType] = useState(
    formData.service_type.length > 0
      ? formData.service_type[formData.service_type.length - 1]
      : []
  );
  const [serviceCate, setServiceCate] = useState(formData.service_category);
  const history = useHistory();

  const handleServiceTypes = () => {
    let aray1 = [];
    let abbriviation = custData.selectedCustomer
      ? custData.selectedCustomer.abbrevation
      : "";
    let id = custData.selectedCustomer ? custData.selectedCustomer._id : "";
    let sentData = {
      abbrevation:
        localStorage.getItem("type") === "Customer"
          ? data && data.getData && data.getData.abbrevation
          : abbriviation,
      customerid:
        localStorage.getItem("type") === "Customer"
          ? localStorage.getItem("customerid")
          : id,
      thisCustomerBranch:
        data.getData &&
        localStorage.getItem("type") === "Customer" &&
        !data.getData.roles.includes("Customer_Admin")
          ? data.getData.thisCustomerBranch
          : localStorage.getItem("type") === "tenant"
            ? branchData
            : "",
    };
    createMprId(sentData, dispatch).then((response) => {
      if (
        typeof response.MPRID === "string" &&
        response.MPRID.includes("undefined")
      ) {
        history.push("/app/customers");
      }
      if (response && response.MPRID) {
        setMprID(response.MPRID);
      }
      if (response && response.serviceCategory) {
        response.serviceCategory.map((item, key) => {
          aray1.push(item.wasteStream);
        });
        setServicecat(aray1);
        response.serviceCategory &&
          response.serviceCategory.map((item) => {
            if (serviceCate === item.wasteStream) {
              if (item.serviceType.length > 0) {
                item.serviceType.push("Others");
              }
              setServiceType(item.serviceType);
            }
          });
      }
    });
    formData.MPRId = mprID;
  };

  useEffect(() => {
    handleServiceTypes();
  }, [serviceCate, otherType]);

  useEffect(() => {
    handleRole();
  });

  const handleRole = () => {
    if (localStorage.getItem("type") === "tenant") {
      setUlField(true);
    } else {
      setUlField(false);
    }
  };

  const handleMprName = (e) => {
    const value = e.target.value;
    setMPRName(value);
    formData.MPRName = e.target.value;
  };

  const handleDescription = (e) => {
    const value = e.target.value;
    setDescription(value);
    formData.description = e.target.value;
  };

  const handleOtherServiceType = (e) => {
    const value = e.target.value;
    setOtherServiceType(value);
    formData.other_service_type = e.target.value;
  };

  const handleInquiryLeadId = (e) => {
    const value = e.target.value;
    setInquiryLeadId(value);
    formData.inquirylead_id = e.target.value;
  };

  const handleOtherType = (e) => {
    setOtherType(e.target.value);
    formData.service_type.push(otherType, e.target.value);
  };

  const handleCateChange = (e) => {
    setServiceCate(e.target.value);
    formData.service_category = e.target.value;
    if (e.target.value === "Assets disposal") {
      setServiceType([]);
      setOtherType([]);
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item lg={2} md={1} xs={0} sm={0}></Grid>
      <Grid lg={8} md={10} xs={12} sm={12} item>
        <Paper elevation={2} sx={{ padding: "4px", borderRadius: "12px" }}>
          <div className={classes.form}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: 600, color: "#2c3e50" }}
            >
              {t("common_basic_details")}
            </Typography>
            <Divider sx={{ marginBottom: "20px" }} />

            <Grid container spacing={2}>
              {/* First row - full width */}
              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="mpr_id"
                  label="Tracking ID"
                  name="Id"
                  value={mprID}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> */}

              {/* Second row - 2 columns on md screens */}
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="mpr_name"
                  label="Lead Name"
                  name="MPRName"
                  value={MPRName}
                  onChange={handleMprName}
                  placeholder="Enter a unique identifier for this lead"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>{t("common_service_category")}</InputLabel>
                  <Select
                    value={serviceCate}
                    onChange={handleCateChange}
                    label={t("common_service_category")}
                    variant="outlined"
                  >
                    {servicecat.length > 0 &&
                      servicecat.map((item, key) => (
                        <MenuItem value={item} key={key}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Fourth row - Service types section */}
              {serviceType.length > 0 && (
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgba(0, 0, 0, 0.87)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "primary.main",
                        },
                      },
                    }}
                  >
                    <InputLabel required id="service-type-label">
                      {t("common_service_type")}
                    </InputLabel>
                    <Select
                      labelId="service-type-label"
                      multiple
                      value={otherType}
                      onChange={handleOtherType}
                      label={t("common_service_type")}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 224,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {serviceType.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={otherType.indexOf(name) > -1}
                            sx={{
                              color: "rgba(0, 0, 0, 0.54)",
                              "&.Mui-checked": {
                                color: "primary.main",
                              },
                            }}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {/* Fifth row - Conditional fields */}
              {otherType.includes("Others") && (
                <Grid item xs={12} md={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Other Service Type"
                    name="other_service_type"
                    value={otherServiceType}
                    onChange={handleOtherServiceType}
                    placeholder="Please specify other service type"
                  />
                </Grid>
              )}
              {/* Third row - full width */}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  id="mpr_description"
                  label="Notes"
                  name="description"
                  value={description}
                  onChange={handleDescription}
                  minRows={4}
                  placeholder="Add any relevant details about this lead or special requirements"
                />
              </Grid>

              {UlField && (
                <Grid item xs={12} md={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={t("mpr_inquiry")}
                    name="inquirylead_id"
                    value={inquiryLeadId}
                    onChange={handleInquiryLeadId}
                    placeholder="Enter inquiry ID if available"
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid item lg={2} md={1} xs={0} sm={0}></Grid>
    </Grid>
  );
};

export default BasicDetails;
