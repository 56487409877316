import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  IconButton,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AddInspectionSlotDialog = ({ open, onClose, onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  });

  const [isWorkingHours, setIsWorkingHours] = useState(true);
  const [startTime, setStartTime] = useState("10:00");
  const [endTime, setEndTime] = useState("17:00");

  const handleWorkingHours = (event) => {
    setIsWorkingHours(event.target.checked);
    if (event.target.checked) {
      setStartTime("10:00");
      setEndTime("17:00");
    }
  };

  const handleSubmit = async () => {
    const data = {
      inspectionDate: moment(selectedDate).format("DD-MM-YYYY"),
      time: `${startTime}-${endTime}`,
    };
    await onSubmit(data);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t("Add Inspection Slot")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t("common_date")}
                value={selectedDate}
                onChange={setSelectedDate}
                disablePast
                sx={{ width: "100%" }}
                format="dd-MM-yyyy" // Added format here
                renderInput={(params) => (
                  <TextField {...params} fullWidth variant="outlined" />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isWorkingHours}
                  onChange={handleWorkingHours}
                  color="primary"
                />
              }
              label={t("Working Hours (10AM - 5PM)")}
            />
          </Grid>
          {!isWorkingHours && (
            <>
              <Grid item xs={6}>
                <TextField
                  label={t("Start Time")}
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t("End Time")}
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              {t("Saving...")}
            </>
          ) : (
            t("common_save")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInspectionSlotDialog;
