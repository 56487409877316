import React, { useEffect } from "react";
import { Grid, TextField, IconButton, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { QUOTATION_NOTES } from "../utils/constants";

const Notes = ({
  notes,
  handleTextFieldChange,
  handleDeleteNote,
  addTextField,
  thankofProposal,
  handleThankOfProposal,
  classes,
  includeGST,
  setNotes, // Add this prop to handle initial notes
}) => {
  useEffect(() => {
    // Set initial notes based on GST inclusion
    const defaultNotes = includeGST
      ? QUOTATION_NOTES.GST_INCLUSIVE
      : QUOTATION_NOTES.GST_EXCLUSIVE;


    setNotes(defaultNotes);
  }, [includeGST]);

  return (
    <Grid>
      <Typography variant="h7">Notes:</Typography>
      {notes.map((value, index) => (
        <Grid key={index} style={{ display: "flex" }}>
          <TextField
            className={classes.formControl}
            value={value}
            placeholder="Notes"
            onChange={(event) =>
              handleTextFieldChange(index, event.target.value)
            }
            fullWidth
            multiline
          />
          <IconButton onClick={() => handleDeleteNote(index)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      ))}
      <Button onClick={addTextField}>
        <AddCircleIcon style={{ color: "#0D6937" }} />
        <span>Add note </span>
      </Button>

      <Grid item style={{ marginTop: "2rem" }}>
        <TextField
          className={classes.formControl}
          id="thankOfProposal"
          required={true}
          multiline
          label="Thank of proposal"
          placeholder="Notes"
          minRows={2}
          value={thankofProposal}
          InputLabelProps={{ shrink: true }}
          onChange={handleThankOfProposal}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default Notes;
