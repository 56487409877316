import React, { useEffect, useState } from "react";
import { Grid, IconButton, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import AttachmentIcon from "@mui/icons-material/Attachment";
import moment from "moment";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "./components/styles";
import Users from "../../components/ViewData/Users";
import { getLead, getFile } from "./LeadState";
import AssignLead from "./components/ViewLead/AssignLead";
import RejectedLead from "./components/ViewLead/RejectLead";
import MPRDetails from "./components/ViewLead/MPRDetails";
import MPRSlot from "./components/ViewLead/inspection/MPRSlot";
import MaterialDetails from "./components/ViewLead/MaterialDetails";
import Quotations from "./components/ViewLead/quotations/Quotations";
import Payment from "./components/ViewLead/payments/Payment";
// import Invoice from "./components/ViewLead/invoice/Invoice";
import Schedule from "./components/ViewLead/MaterialPickup/Schedule";
import Form6 from "./components/ViewLead/form6/Form6";
import CloseLead from "./components/ViewLead/CloseLead";
import { useLocation } from "react-router-dom";

const ViewLead = () => {
  const { t } = useTranslation();
  var classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("details");
  const state = useSelector((state) => state.selectedLead);
  const contactList = useSelector((state) => state.tenantContactList);
  const [showViewDetails, setShowViewDetails] = useState(false);
  const [showAssigned, setShowAssigned] = useState(false);
  const [showRejected, setShowRejected] = useState(false);
  const [showQuotation, setShowQuotation] = useState(false);
  const [showInspection, setShowInspection] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [requestUpdate, setRequestUpdate] = useState(false);
  const [list, setList] = useState([]);
  const [quotationDetails, setQuotationDetails] = useState();
  const [contactPerson, setContactPerson] = useState();
  const [customer, setCustomer] = useState();
  const [slotData, setSlotData] = useState([]);
  const [mprData, setMprData] = useState([]);
  const [martialList, setMaterialList] = useState([]);
  const [goodsList, setGoodsList] = useState([]);
  const [choosenSlot, setChoosenSlot] = useState({});
  const [quotation, setQuotation] = useState();
  const [paymentData, setPaymentData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [pickupData, setPickupData] = useState({});
  const [formsixData, setFormsixData] = useState({});
  const [formsixFile, setFormsixFile] = useState({});
  const [showFormsix, setShowFormSix] = useState(false);
  const [showCloseLead, setShowCloseLead] = useState(false);
  const [closeAllTabs, setCloseAllTabs] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const leadIdFromUrl = location.pathname.split("/").pop();

  const [isLoading, setIsLoading] = useState(true);

  const toggleTab = (tabName) => {
    setActiveTab(tabName);
    const tabState = {
      showViewDetails: false,
      showAssigned: false,
      showRejected: false,
      showQuotation: false,
      showInspection: false,
      showPayment: false,
      showInvoice: false,
      showSchedule: false,
      requestUpdate: false,
      showFormSix: false,
      showCloseLead: false,
    };

    tabState[tabName] = true;
    setTabStates(tabState);
  };

  const setTabStates = (newTabStates) => {
    setShowViewDetails(newTabStates.showViewDetails);
    setShowAssigned(newTabStates.showAssigned);
    setShowRejected(newTabStates.showRejected);
    setShowQuotation(newTabStates.showQuotation);
    setShowInspection(newTabStates.showInspection);
    setShowPayment(newTabStates.showPayment);
    setShowInvoice(newTabStates.showInvoice);
    setShowSchedule(newTabStates.showSchedule);
    setRequestUpdate(newTabStates.requestUpdate);
    setShowFormSix(newTabStates.showFormSix);
    setShowCloseLead(newTabStates.showCloseLead);
  };

  const handleShowDetails = () => {
    toggleTab("showViewDetails");
    setCloseAllTabs(false);
  };

  const handleShowAssigned = () => {
    toggleTab("showAssigned");
    setCloseAllTabs(false);
  };

  const handleShowRejected = () => {
    toggleTab("showRejected");
    setCloseAllTabs(false);
  };

  const handleShowQuotation = () => {
    toggleTab("showQuotation");
    setCloseAllTabs(false);
  };

  const handleShowInspection = () => {
    toggleTab("showInspection");
    setCloseAllTabs(false);
  };
  const handleShowPayment = () => {
    toggleTab("showPayment");
    setCloseAllTabs(false);
  };
  const handleShowInvoice = () => {
    toggleTab("showInvoice");
    setCloseAllTabs(false);
  };
  const handleshowSchedule = () => {
    toggleTab("showSchedule");
    setCloseAllTabs(false);
  };
  const handleRequestChange = () => {
    toggleTab("requestUpdate");
    setCloseAllTabs(false);
  };
  const handleFormSix = () => {
    toggleTab("showFormSix");
    setCloseAllTabs(false);
  };
  const handleCloseLead = () => {
    toggleTab("showCloseLead");
    setCloseAllTabs(false);
  };
  const handleCloseAll = () => {
    toggleTab("");
    setCloseAllTabs(true);
  };

  const getFiles = (file) => {
    let bodyData = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(bodyData).then((response) => {
      window.open(response.reference, "_blank");
    });
  };

  useEffect(() => {
    let data = [];
    let mprTable = [];
    let goods = [];
    let material = [];
    setIsLoading(true);

    let external_id =
      state.length > 0
        ? state[0].external_id
        : leadIdFromUrl
          ? leadIdFromUrl
          : "";
    if (state.length === 0 && !leadIdFromUrl) {
      history.push("/app/Leads");
      return;
    }
    if (state || leadIdFromUrl) {
      getLead(external_id, dispatch)
        .then((response) => {
          if (response && response.thisLeadDetails)
            setList(response.thisLeadDetails);
          if (response && response.quotation) setQuotation(response.quotation);
          if (response && response.thisFormContent)
            setFormsixData(response.thisFormContent);
          if (response && response.thisQuotationContent)
            setQuotationDetails(response.thisQuotationContent);
          if (response && response.form6) setFormsixFile(response.form6);

          if (response && response.mprUserDetails)
            setContactPerson(response.mprUserDetails);

          if (response && response?.customerDetails)
            setCustomer(response?.customerDetails);

          let mpr = response?.thisLeadMPR;

          mprTable.push([
            { label: t("mpr_name"), value: mpr?.name },
            { label: t("common_description"), value: mpr?.description },
            {
              label: t("mpr_last_date"),
              value: moment(mpr?.MPRLastDate).format("DD-MM-YYYY"),
            },
            {
              label: t("mpr_quotation_last_date"),
              value: moment(mpr?.QuatationLastDate).format("DD-MM-YYYY"),
            },
            {
              label: t("common_service_type"),
              value:
                mpr?.serviceType?.length > 1
                  ? mpr?.serviceType.join(",").replace("Others,", "")
                  : mpr?.serviceType.join(","),
            },
            {
              label: t("common_service_category"),
              value: mpr?.serviceCategory,
            },
            {
              label: t("common_customer_name"),
              value: state ? state[0]?.customerName : "",
            },
            {
              label: t("lead_assigned_to"),
              value:
                response && response?.userDetails
                  ? response?.userDetails?.UserName
                  : "",
            },
          ]);
          mpr?.item_list_file.map((file) => {
            material.push([
              file.fileName || file.filename,
              <IconButton
                onClick={() => getFiles(file)}
                target="_blank"
                className={classes.collapseIcon}
                size="large"
              >
                <AttachmentIcon />
              </IconButton>,
            ]);
          });
          mpr?.goodsPic.map((good) => {
            goods.push([
              good.fileName || good.filename,
              <IconButton
                onClick={() => getFiles(good)}
                className={classes.collapseIcon}
                size="large"
              >
                <AttachmentIcon />
              </IconButton>,
            ]);
          });
          if (response && response?.FinalSlots)
            setSlotData(response.FinalSlots);
          if (response && response?.payment) setPaymentData(response.payment);
          if (response && response?.invoice) setInvoiceData(response.invoice);
          if (response && response?.pickup) setPickupData(response.pickup);
          setChoosenSlot(response?.slotlost);
          setMprData(mprTable);
          setMaterialList(material);
          setGoodsList(goods);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    showViewDetails,
    showAssigned,
    showRejected,
    requestUpdate,
    showQuotation,
    showSchedule,
    showFormsix,
    showInvoice,
    showCloseLead,
  ]);
  const breadcrumb_viewTenant = [
    { label: t("common_leads"), link: "/#/app/Leads" },
    { label: list && list.external_id, link: "" },
  ];

  return (
    <>
      <BreadCrumbs data={breadcrumb_viewTenant} />
      <div className={classes.content}>
        {/* {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </div>
        ) : ( */}
        <Grid container spacing={3}>
          <Grid
            item
            xs={isCollapsed ? "auto" : 12}
            sm={isCollapsed ? "auto" : 6}
            md={isCollapsed ? "auto" : 4}
            lg={isCollapsed ? "auto" : 3}
            style={{
              paddingTop: "0px",
              flexShrink: 0,
            }}
          >
            <Users
              viewDetails={handleShowDetails}
              list={list}
              show={handleShowAssigned}
              reject={handleShowRejected}
              quotation={handleShowQuotation}
              inspection={handleShowInspection}
              payment={handleShowPayment}
              invoice={handleShowInvoice}
              schedule={handleshowSchedule}
              formsix={handleFormSix}
              form6Data={formsixData}
              closeLead={handleCloseLead}
              closeAll={closeAllTabs}
              customerDetails={customer}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              activeTab={activeTab}
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              flexGrow: 1,
              // maxWidth: isCollapsed ? "calc(100% - 64px)" : "calc(100% - 25%)",
            }}
          >
            {showViewDetails ? (
              <>
                <MPRDetails
                  mprData={mprData}
                  pickupAddress={pickupData}
                  contactPerson={contactPerson}
                  customerDetails={customer}
                />
                <MaterialDetails
                  list={list}
                  goodsList={goodsList}
                  martialList={martialList}
                />
              </>
            ) : showAssigned ? (
              <AssignLead
                list={list}
                contactList={contactList}
                hide={handleCloseAll}
                lead={list && list.external_id}
              />
            ) : showRejected ? (
              <RejectedLead
                list={list}
                hide={handleCloseAll}
                lead={list && list.external_id}
              />
            ) : showQuotation ? (
              <Quotations
                hide={handleCloseAll}
                list={list}
                quotation={quotation}
                quotationDetails={quotationDetails}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                contactList={contactList}
                contactPerson={contactPerson}
              />
            ) : showInspection ? (
              <MPRSlot
                list={list}
                slotData={slotData}
                lead={list && list.external_id}
                choosen={choosenSlot}
                close={handleRequestChange}
                inspectionClose={handleCloseAll}
              />
            ) : showPayment ? (
              <Payment
                list={list}
                payment={paymentData}
                close={handleCloseAll}
              />
            ) : // for the timebeing it is not required
            // ) : showInvoice ? (
            //   <Invoice
            //     list={list}
            //     invoice={invoiceData}
            //     close={handleCloseAll}
            //   />
            showSchedule ? (
              <Schedule
                pickup={pickupData}
                list={list}
                close={handleCloseAll}
              />
            ) : showFormsix ? (
              <Form6
                contactPerson={contactPerson}
                form6Datas={formsixData}
                close={handleCloseAll}
                form6File={formsixFile}
                list={list}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
              />
            ) : showCloseLead ? (
              <CloseLead list={list} close={handleCloseAll} />
            ) : (
              <>
                <MPRDetails
                  mprData={mprData}
                  pickupAddress={pickupData}
                  contactPerson={contactPerson}
                  customerDetails={customer}
                />
                <MaterialDetails
                  list={list}
                  goodsList={goodsList}
                  martialList={martialList}
                />
              </>
            )}
          </Grid>
        </Grid>
        {/* )} */}
      </div>
    </>
  );
};

export default ViewLead;
