import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Box,
  CircularProgress,
  Typography,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import EditIcon from "@mui/icons-material/Edit";
import EditQuotation from "./EditQuotation";
import useStyles from "../styles";
import QuotationForm from "./QuotationForm";
import CreateQuotation from "./CreateQuote";
import Table from "../../../../../components/Table/Table";
import {
  getFile,
  getLead,
  assignLead,
  rejectAllLead,
} from "../../../LeadState";
import QuotationPDF from "./QuotationPDF";
import { getEditQuotation } from "../../../LeadState";
import { formatIndianCurrency } from "../../../../../utils/currencyFormat";
import { quoationHeader } from "../configs/headers";
import { formatDateTime } from "../../../../../utils/helpers";
import toast from "react-hot-toast";
import AcceptQuotationDialog from "./components/AcceptQuoteDialog";
import CommentIcon from "@mui/icons-material/Comment";
import SendQuoteDialog from "./components/SendQuoteDialog";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { Badge } from "@mui/material";
import { StyledPaper } from "../../../styles";

const Quotations = ({
  list,
  quotation,
  hide,
  isCollapsed,
  setIsCollapsed,
  contactPerson,
}) => {
  var classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.selectedLead);
  let external_id = state.length > 0 ? state[0].external_id : "";
  const [quotations, setQuotations] = useState(quotation);
  const [lists, setLists] = useState(list);
  const [showAdd, setShowAdd] = useState(false);
  const [quotationData, setquotationData] = useState([]);
  const [qoutUrl, setQuotUrl] = useState("");
  const [showPDF, setShowPDF] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [showCreateQuotation, setShowCreateQuotation] = useState(false);
  const [editData, SetEditData] = useState("");
  const [comments, setComments] = useState("");
  const [editing, setEditing] = useState(false);
  const [isQuoteAccepted, setIsQuoteAccepted] = useState(false);

  const [openSendDialog, setOpenSendDialog] = useState(false);
  const [selectedQuoteFile, setSelectedQuoteFile] = useState({});

  const handleAdd = () => {
    setShowAdd(!showAdd);
    getLead(external_id, dispatch).then((response) => {
      setQuotations(response.quotation);
      setLists(response.thisLeadDetails);
    });
  };

  const handleCreateQuotation = () => {
    setIsCollapsed(true);
    setShowCreateQuotation((prev) => !prev);
    setShowAdd(false);
    getLead(external_id, dispatch).then((response) => {
      setQuotations(response.quotation);
      setLists(response.thisLeadDetails);
    });
  };

  const handleEditQuotation = (item) => {
    setEditing(true);
    setShowAdd(false);
    getLead(external_id, dispatch).then((response) => {
      setQuotations(response.quotation);
      setLists(response.thisLeadDetails);
    });
    if (item.quotationNo !== undefined) {
      let data = {
        quotationNo: item.quotationNo,
      };
      getEditQuotation(data).then((response) => {
        if (response.status === "200") {
          SetEditData(response.data);
          setEditEnabled((prev) => !prev);
          setIsCollapsed(true);
          setEditing(false);
        } else {
          toast.error("Failed to retrieve data.");
          setEditing(false);
        }
      });
    } else {
      toast.error("Failed to retrieve data.");
      setEditing(false);
    }
  };

  const handleshowhidepdf = () => {
    setShowPDF(!showPDF);
  };

  const handlepdf = (data) => {
    let body = {
      fileName: data.fileName,
      folderName: data.folderName,
      expireLimt: 1800,
    };
    getFile(body).then((response) => {
      setQuotUrl(response.reference);
      setShowPDF(true);
    });
  };

  const handleCloseEdit = () => {
    setEditEnabled((prev) => !prev);
    setShowAdd(false);
    getLead(external_id, dispatch).then((response) => {
      setQuotations(response.quotation);
      setLists(response.thisLeadDetails);
    });
  };

  const [acceptOpen, setAcceptOpen] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAcceptOpen = (quote) => {
    setSelectedQuote(quote);
    setAcceptOpen(true);
  };

  const handleAcceptQuote = async () => {
    setLoading(true);
    try {
      const body = {
        external_id: lists.external_id,
        leadData: {
          quotation: {
            folderName: selectedQuote.folderName,
            fileName: selectedQuote.fileName,
            quoteStatus: "accepted",
            totalAmount: selectedQuote.totalAmount,
            description: selectedQuote.description,
            referenceName: selectedQuote.referenceName,
            customerComments: comments,
          },
          status: "Quotation Accepted",
          reason: "Won",
        },
      };

      const response = await assignLead(body, dispatch);
      if (response.status === "200") {
        // Optionally reject other quotes
        await rejectAllLead({ leadID: list.external_id }, dispatch);

        // Refresh data
        const updatedData = await getLead(external_id, dispatch);
        setQuotations(updatedData.quotation);
        setLists(updatedData.thisLeadDetails);
        toast.success("Quotation accepted successfully");
      }
    } catch (error) {
      toast.error("Failed to accept quotation");
    } finally {
      setLoading(false);
      setAcceptOpen(false);
      setComments("");
    }
  };

  useEffect(() => {
    let data = [];

    if (!quotations?.length) return;

    // Sort quotations by date first
    const sortedQuotations = [...quotations].sort(
      (a, b) => new Date(b.genratedDate) - new Date(a.genratedDate)
    );

    // Find latest quotation from sorted array
    const latestQuotation = sortedQuotations[0];

    sortedQuotations.map((item, index) => {
      // Calculate percentage and amount change
      let percentageChange = null;
      let amountDifference = 0;

      if (index < sortedQuotations.length - 1) {
        const currentAmount = item.totalAmount.amount;
        const previousAmount = sortedQuotations[index + 1].totalAmount.amount;
        amountDifference = currentAmount - previousAmount;
        const change = (amountDifference / previousAmount) * 100;
        percentageChange = change === 0 ? null : change;
      }

      const amountDisplay = (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>
            {formatIndianCurrency(item.totalAmount.amount)}
          </Typography>
          {percentageChange !== null && (
            <Tooltip
              title={`Amount change from previous quote: ${formatIndianCurrency(amountDifference)}`}
              arrow
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color:
                    percentageChange > 0
                      ? "success.main"
                      : percentageChange < 0
                        ? "error.main"
                        : "text.primary",
                  fontSize: "0.875rem",
                }}
              >
                {percentageChange > 0 ? "↑" : "↓"}
                {Math.abs(percentageChange).toFixed(1)}%
              </Typography>
            </Tooltip>
          )}
        </Box>
      );

      const statusDisplay = (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>
            {item.quoteStatus === "accepted" ? "Accepted" : "N/A"}
          </Typography>
          {item?.customerComments && (
            <Tooltip title={item.customerComments} arrow placement="top">
              <IconButton size="small">
                <CommentIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
      const isLatest = item.genratedDate === latestQuotation.genratedDate;

      if (isLatest && item.quoteStatus === "accepted") {
        setIsQuoteAccepted(true);
      }

      const showEditIcon = isLatest && item.quotationNo !== undefined;

      const actions = (
        <Box sx={{ display: "flex", gap: 1 }}>
          {showEditIcon && (
            <Tooltip title="Edit Quotation">
              <IconButton
                onClick={() => handleEditQuotation(item)}
                size="large"
                disabled={item.quoteStatus === "accepted" || editing}
              >
                {editing ? (
                  <CircularProgress size={20} style={{ color: "#0D6937" }} />
                ) : (
                  <EditIcon />
                )}
              </IconButton>
            </Tooltip>
          )}

          {isLatest && item.quoteStatus !== "accepted" && (
            <Tooltip title="Accept Quotation">
              <IconButton
                onClick={() => handleAcceptOpen(item)}
                size="large"
                sx={{ color: "#0D6937" }}
              >
                <DoneOutlineIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );

      const row = [
        item.quotationNo ? item.quotationNo : item.referenceName,
        item.genratedDate ? formatDateTime(item.genratedDate) : "N/A",
        amountDisplay,
        statusDisplay,
        <IconButton onClick={() => handlepdf(item)} size="large">
          <PictureAsPdfIcon />
        </IconButton>,
        actions,
        <Tooltip title="Email quotation">
          <Badge
            badgeContent={list.quotationHistory?.length || 0}
            sx={{
              "& .MuiBadge-badge": {
                bgcolor: "#0D6937",
                color: "white",
                border: "1px solid #0D6937",
                right: 8,
                top: 8,
              },
            }}
            max={99}
          >
            <IconButton
              onClick={() => {
                setOpenSendDialog(true);
                setSelectedQuoteFile({
                  fileName: item.fileName,
                  folderName: item.folderName,
                });
              }}
              size="large"
              sx={{ color: "#0D6937" }}
            >
              <ForwardToInboxIcon />
            </IconButton>
          </Badge>
        </Tooltip>,
      ];

      data.push(row);
    });

    setquotationData(data);
  }, [
    showPDF,
    showAdd,
    quotations,
    editEnabled,
    editData,
    acceptOpen,
    editing,
  ]);

  return (
    <StyledPaper>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h5" component="h2">
            {t("common_quotations")}
          </Typography>
        </Grid>
        <Grid item md={6} lg={4}></Grid>
        <Grid item md={6} lg={8} className={classes.iconGrid}>
          {showPDF ? (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className={classes.buttonOutlined}
              onClick={handleshowhidepdf}
            >
              {t("common_close")}
            </Button>
          ) : (
            !isQuoteAccepted && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className={classes.buttonOutlined}
                  onClick={handleAdd}
                >
                  Upload
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className={classes.buttonOutlined}
                  onClick={handleCreateQuotation}
                  sx={{ margin: { sm: "8px", xs: "8px" } }}
                >
                  Create
                </Button>
              </>
            )
          )}
        </Grid>
        {showAdd ? (
          <>
            <Grid item lg={2} md={2}></Grid>
            <Grid item lg={8} md={8} xs={12}>
              <QuotationForm close={handleAdd} list={list} />
            </Grid>
            <Grid item lg={2} md={2}></Grid>
          </>
        ) : showCreateQuotation ? (
          <>
            <Grid item lg={12} xs={12}>
              <CreateQuotation close={handleCreateQuotation} list={list} />
            </Grid>
          </>
        ) : editEnabled ? (
          <>
            <Grid item lg={12} xs={12}>
              <EditQuotation
                close={handleCloseEdit}
                list={list}
                quotationDetails={editData}
              />
            </Grid>
          </>
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {showPDF != "" ? (
              <QuotationPDF
                url={qoutUrl}
                close={handleshowhidepdf}
                isCollapsed={isCollapsed}
              />
            ) : (
              <Table
                data={quotationData}
                header={quoationHeader}
                name=""
                options={{
                  filterType: "checkbox",
                  sortOrder: {
                    name: "Date",
                    direction: "desc",
                  },
                  print: false,
                  download: false,
                  filter: false,
                  selectableRows: "none",
                }}
              />
            )}
          </Grid>
        )}
      </Grid>

      <AcceptQuotationDialog
        open={acceptOpen}
        onClose={() => setAcceptOpen(false)}
        onAccept={handleAcceptQuote}
        loading={loading}
        comments={comments}
        onCommentsChange={setComments}
      />
      <SendQuoteDialog
        open={openSendDialog}
        onClose={() => setOpenSendDialog(false)}
        contactPerson={contactPerson}
        quoteFile={selectedQuoteFile}
        leadDetails={list}
        dispatch={dispatch}
      />
    </StyledPaper>
  );
};

export default Quotations;
