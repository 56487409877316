import React, { useState } from "react";
import {
  Grid,
  Paper,
  Divider,
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Box,
  Tooltip,
  Typography,
  Chip,
} from "@mui/material";
import classnames from "classnames";
import moment from "moment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getActions, createIcon } from "./utils";

import useStyles from "./styles";
import { StyledPaper } from "../../pages/leads/styles";

const Users = ({
  list,
  show,
  reject,
  quotation,
  inspection,
  payment,
  invoice,
  schedule,
  formsix,
  form6Data,
  closeLead,
  closeAll,
  viewDetails,
  customerDetails,
  isCollapsed,
  setIsCollapsed,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const loginData = useSelector((state) => state.userdata);
  const [selectedButton, setSelectedButton] = useState(null);
  const [showloader, setShowLoader] = useState(false);

  // Helper function to determine button states
  const getButtonStates = () => {
    const form6Button = list && list?.pickup?.pickupStatus ? false : true;
    const won_disable =
      list && (list.reason || "").toLowerCase() === "won" ? false : true;
    const assign_disable =
      list && (list.status || "").toLowerCase() === "open" ? true : false;
    const close_disable =
      list &&
      (list.status || "").toLowerCase() === "closed" &&
      (list.reason || "").toLowerCase() === "rejected"
        ? true
        : false;

    return { form6Button, won_disable, assign_disable, close_disable };
  };

  const { form6Button, won_disable, assign_disable, close_disable } =
    getButtonStates();

  // Define actions based on user role
  const actions = getActions({
    t,
    viewDetails,
    loginData,
    show,
    reject,
    inspection,
    quotation,
    payment,
    invoice,
    schedule,
    formsix,
    closeLead,
    close_disable,
    assign_disable,
    won_disable,
    form6Button,
  });

  return (
    <Box position="relative">
      <StyledPaper
        className={classnames(classes.widgetRoot, classes.paper, {
          [classes.collapsedContent]: isCollapsed,
          [classes.expandedContent]: !isCollapsed,
        })}
        sx={{
          width: isCollapsed ? "auto" : "100%",
          transition: "width 0.3s ease",
        }}
      >
        <IconButton
          className={classes.collapseButton}
          onClick={() => setIsCollapsed(!isCollapsed)}
          size="small"
        >
          {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>

        <Grid container spacing={2}>
          <Box className={classes.userInfoSection}>
            <Grid container spacing={2} alignItems="center">
              <Grid
                item
                xs={isCollapsed ? 12 : 3}
                sx={{
                  display: "flex",
                  justifyContent: isCollapsed ? "center" : "flex-start",
                }}
              >
                <Avatar className={classes.avatar}>L</Avatar>
              </Grid>

              {!isCollapsed && (
                <Grid item xs={9}>
                  <Box className={classes.infoContainer}>
                    <Typography
                      variant="subtitle1"
                      className={classes.idText}
                      noWrap
                    >
                      {list?.external_id}
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      noWrap
                    >
                      {customerDetails?.name}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      Created on:{" "}
                      {list && moment(list.createdAt).format("DD-MM-YYYY")}
                    </Typography>

                    <Chip
                      label={
                        list?.status === "Open"
                          ? "Unassigned"
                          : list?.status?.charAt(0).toUpperCase() +
                            list?.status?.slice(1)
                      }
                      size="small"
                      className={classes.statusChip}
                      variant="outlined"
                      color={list?.status === "Open" ? "warning" : "success"}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            {actions.map((item) => (
              <Box key={item.key} width="100%">
                {item.title === t("common_close") && showloader ? (
                  <CircularProgress />
                ) : (
                  <Tooltip
                    title={isCollapsed ? item.title : ""}
                    placement="right"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={!isCollapsed && createIcon(item.icon)}
                      className={classnames(classes.buttonOutlined, {
                        [classes.selectedBtn]:
                          selectedButton === item.title && !closeAll,
                      })}
                      style={{
                        width: "100%",
                        justifyContent: isCollapsed ? "center" : "flex-start",
                      }}
                      onClick={() => {
                        setSelectedButton(item.title);
                        item.func();
                      }}
                      disabled={item.disable}
                    >
                      {isCollapsed ? createIcon(item.icon) : item.title}
                    </Button>
                  </Tooltip>
                )}
              </Box>
            ))}
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>
  );
};

export default Users;
