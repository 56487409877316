// import React, { useState, useEffect } from "react";
// import {
//   Stepper,
//   Step,
//   StepLabel,
//   Button,
//   Paper,
//   CircularProgress,
//   useMediaQuery,
//   Box,
//   Typography,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
// import useStyles from "./styles";
// import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
// import CustomButton from "../../components/CustomButton";
// import BasicDetails from "./pages/BasicDetails";
// import InspectionSlots from "./pages/InspectionSlots";
// import MaterialDetails from "./pages/MaterialDetails";
// import ReviewDetails from "./pages/ReviewDetails";
// import ContactDetails from "./pages/ContactDetails";
// import { createMprId, addMPR, addLead } from "./MprState";
// import toast, { Toaster } from "react-hot-toast";

// function getStepContent(step, formData, setForm) {
//   switch (step) {
//     case 0:
//       return <BasicDetails formData={formData} setForm={setForm} />;
//     case 1:
//       return <MaterialDetails formData={formData} setForm={setForm} />;
//     case 2:
//       return <ContactDetails formData={formData} setForm={setForm} />;
//     // case 3:
//     //   return <InspectionSlots formData={formData} setForm={setForm} />;
//     case 3:
//       return <ReviewDetails formData={formData} setForm={setForm} />;
//     default:
//       return "Unknown step";
//   }
// }

// export default function AddMpr(props) {
//   const { t } = useTranslation();
//   const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

//   const logindata = useSelector((state) => state.userdata);

//   const classes = useStyles();
//   const steps = getSteps();
//   var data = useSelector((state) => state.userdata);
//   const custData = useSelector((state) => state);
//   var customerData = custData.selectedCustomer
//     ? custData.selectedCustomer._id
//     : "";
//   var branchData = custData.selectedBranch ? custData.selectedBranch._id : "";
//   const dispatch = useDispatch();
//   const [mprID, setMprID] = useState("");

//   const AddMPRData = [
//     { label: "Leads", link: "/#/app/MPR" },
//     { label: "New Lead", link: "" },
//   ];

//   function getSteps() {
//     return [
//       t("common_basic_details"),
//       t("mpr_material_details"),
//       t("common_contact_details"),
//       // t("common_inspection_slots"),
//       t("common_review_details"),
//     ];
//   }
//   useEffect(() => {
//     let sendData = {
//       abbrevation: data?.getData?.abbrevation || "",

//       customerid:
//         localStorage.getItem("type") === "Customer"
//           ? localStorage.getItem("customerid")
//           : customerData || "",

//       thisCustomerBranch:
//         data?.getData &&
//         localStorage.getItem("type") === "Customer" &&
//         !data.getData.roles?.includes("Customer_Admin")
//           ? data.getData.thisCustomerBranch
//           : localStorage.getItem("type") === "tenant"
//             ? branchData || ""
//             : "",
//     };

//     createMprId(sendData, dispatch).then((response) => {
//       if (typeof response === "string" && response.includes("undefined")) {
//         props.history.push("/app/customers"); // Redirect if response contains "undefined"
//       } else {
//         setMprID(response);
//       }
//     });
//   }, []);

//   const defaultData = {
//     MPRId: "",
//     MPRName: "",
//     pickupDate: new Date(),
//     description: "",
//     quotation_date: new Date(),
//     slots: [],
//     files: [],
//     goodsPic: [],
//     contact_address: "",
//     contact_contact: "",
//     service_type: [],
//     service_category: "E-Waste",
//     other_service_type: "",
//     inquirylead_id: "",
//     customerId:
//       localStorage.getItem("type") === "Customer"
//         ? localStorage.getItem("customerid")
//         : customerData,
//     tenantId: "",
//   };
//   const [activeStep, setActiveStep] = React.useState(0);
//   const [formData, setForm] = useState(defaultData);
//   const [showLoader, setShowloader] = React.useState(false);

//   const handleNext = () => {
//     if (formData.service_category === "E-Waste") {
//       const lastServiceTypeArrayLength =
//         formData.service_type.length !== 0 &&
//         formData.service_type[formData.service_type.length - 1].length;
//       if (!lastServiceTypeArrayLength) {
//         toast.error("Please fill in service type");
//       } else {
//         setActiveStep((prevActiveStep) => prevActiveStep + 1);
//       }
//     } else {
//       setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     }
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };
//   const handlePublish = (status) => {
//     setShowloader(true);
//     formData.description = formData.description || "E-Waste";

//     addMPR(formData, status, dispatch).then((response) => {
//       if (response.status === "200") {
//         const updatedData = {
//           ...response.Data,
//           MPR: {
//             ...response.Data.MPR,
//             createdBy: logindata?.getData?._id,
//           },
//         };

//         addLead(updatedData, dispatch).then((resp) => {
//           if (resp.status === "200") {
//             toast.success("Successfully created Lead & Assinged to you.");
//             setTimeout(() => props.history.push("/app/MPR"), 2000);
//           } else if (response.status === "401") {
//             setShowloader(false);
//             toast.error(response.message);
//             setTimeout(() => {
//               dispatch({ type: "LOG_OUT" });
//               localStorage.clear();
//             }, 2000);
//           } else {
//             let msg = [];
//             if (response.ErrorData) {
//               response.ErrorData.details.map((item) => {
//                 msg.push(item.message);
//               });
//               toast.error(`${msg.join()}`);
//               setShowloader(false);
//             }
//           }
//         });
//       } else if (response.status === "401") {
//         setShowloader(false);
//         toast.error(response.message);
//         setTimeout(() => {
//           dispatch({ type: "LOG_OUT" });
//           localStorage.clear();
//         }, 2000);
//       } else {
//         let msg = [];
//         if (response.ErrorData) {
//           response.ErrorData.details.map((item) => {
//             msg.push(item.message);
//           });
//           toast.error(`${msg.join().replace("thisUser", "Contact")}`);
//           setShowloader(false);
//         }
//         if (response.status === "404") {
//           toast.error(response.message);
//           setShowloader(false);
//         }
//       }
//     });
//   };

//   return (
//     <>
//       <BreadCrumbs data={AddMPRData} />
//       <Toaster />
//       <div className={classes.content}>
//         {isSmallScreen ? (
//           <Box sx={{ mb: 4 }}>
//             <Typography
//               variant="h6"
//               sx={{
//                 textAlign: "center",
//                 mb: 2,
//                 color: "primary.main",
//                 fontWeight: 500,
//               }}
//             >
//               {steps[activeStep]}
//             </Typography>
//             <Stepper
//               activeStep={activeStep}
//               sx={{
//                 padding: "18px 0px",
//                 "& .MuiStepConnector-line": {
//                   borderColor: "primary.light",
//                 },
//                 "& .MuiStepIcon-root": {
//                   color: "grey.300",
//                   "&.Mui-active": {
//                     color: "primary.main",
//                   },
//                   "&.Mui-completed": {
//                     color: "success.main",
//                   },
//                 },
//               }}
//             >
//               {steps.map((label, index) => (
//                 <Step
//                   key={label}
//                   sx={{
//                     width: 24,
//                     padding: 0,
//                     "& .MuiStepLabel-root": {
//                       padding: 0,
//                     },
//                   }}
//                 >
//                   <StepLabel />
//                 </Step>
//               ))}
//             </Stepper>
//           </Box>
//         ) : (
//           <Stepper
//             activeStep={activeStep}
//             sx={{
//               mb: 4,
//               padding: 2,
//               backgroundColor: "background.paper",
//               borderRadius: 1,
//               boxShadow: 1,
//               alignItems: "center",
//             }}
//           >
//             {steps.map((label) => (
//               <Step key={label}>
//                 <StepLabel>{label}</StepLabel>
//               </Step>
//             ))}
//           </Stepper>
//         )}

//         <div>
//           {getStepContent(activeStep, formData, setForm)}
//           <Paper className={classes.paperButton}>
//             <Button
//               href="/#/app/customers"
//               className={classes.button}
//               disabled={showLoader}
//             >
//               {t("common_cancel")}
//             </Button>
//             <Button
//               disabled={activeStep === 0 || showLoader}
//               onClick={handleBack}
//               className={classes.button}
//             >
//               {t("common_back")}
//             </Button>
//             {activeStep === steps.length - 1 ? (
//               <>
//                 {showLoader ? (
//                   <CircularProgress size={28} />
//                 ) : (
//                   <CustomButton
//                     variant="contained"
//                     title="Save lead"
//                     onclick={() => handlePublish("Open")}
//                   />
//                 )}
//               </>
//             ) : (
//               <CustomButton
//                 variant="contained"
//                 title={t("common_next")}
//                 onclick={handleNext}
//               />
//             )}
//           </Paper>
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  CircularProgress,
  useMediaQuery,
  Box,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import CustomButton from "../../components/CustomButton";
import BasicDetails from "./pages/BasicDetails";
// import InspectionSlots from "./pages/InspectionSlots";
import MaterialDetails from "./pages/MaterialDetails";
import ReviewDetails from "./pages/ReviewDetails";
import ContactDetails from "./pages/ContactDetails";
import { createMprId, addMPR, addLead } from "./MprState";
import toast, { Toaster } from "react-hot-toast";

function getStepContent(step, formData, setForm) {
  switch (step) {
    case 0:
      return <BasicDetails formData={formData} setForm={setForm} />;
    case 1:
      return <MaterialDetails formData={formData} setForm={setForm} />;
    case 2:
      return <ContactDetails formData={formData} setForm={setForm} />;
    case 3:
      return <ReviewDetails formData={formData} setForm={setForm} />;
    default:
      return "Unknown step";
  }
}

export default function AddMpr(props) {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const logindata = useSelector((state) => state.userdata);

  const classes = useStyles();
  const steps = getSteps();
  var data = useSelector((state) => state.userdata);
  const custData = useSelector((state) => state);
  var customerData = custData.selectedCustomer
    ? custData.selectedCustomer._id
    : "";
  var branchData = custData.selectedBranch ? custData.selectedBranch._id : "";
  const dispatch = useDispatch();
  const [mprID, setMprID] = useState("");

  const AddMPRData = [
    { label: "Leads", link: "/#/app/MPR" },
    { label: "New Lead", link: "" },
  ];

  function getSteps() {
    return [
      t("common_basic_details"),
      t("mpr_material_details"),
      t("common_contact_details"),
      t("common_review_details"),
    ];
  }

  useEffect(() => {
    let sendData = {
      abbrevation: data?.getData?.abbrevation || "",
      customerid:
        localStorage.getItem("type") === "Customer"
          ? localStorage.getItem("customerid")
          : customerData || "",
      thisCustomerBranch:
        data?.getData &&
        localStorage.getItem("type") === "Customer" &&
        !data.getData.roles?.includes("Customer_Admin")
          ? data.getData.thisCustomerBranch
          : localStorage.getItem("type") === "tenant"
            ? branchData || ""
            : "",
    };

    createMprId(sendData, dispatch).then((response) => {
      if (typeof response === "string" && response.includes("undefined")) {
        props.history.push("/app/customers");
      } else {
        setMprID(response);
      }
    });
  }, []);

  const defaultData = {
    MPRId: "",
    MPRName: "",
    pickupDate: new Date(),
    description: "",
    quotation_date: new Date(),
    slots: [],
    files: [],
    goodsPic: [],
    contact_address: "",
    contact_contact: "",
    service_type: [],
    service_category: "E-Waste",
    other_service_type: "",
    inquirylead_id: "",
    customerId:
      localStorage.getItem("type") === "Customer"
        ? localStorage.getItem("customerid")
        : customerData,
    tenantId: "",
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setForm] = useState(defaultData);
  const [showLoader, setShowloader] = React.useState(false);

  const handleNext = () => {
    if (activeStep === 2 && !formData.contact_contact) {
      toast.error("Please select a contact");
      return;
    }

    if (formData.service_category === "E-Waste") {
      const lastServiceTypeArrayLength =
        formData.service_type.length !== 0 &&
        formData.service_type[formData.service_type.length - 1].length;
      if (!lastServiceTypeArrayLength) {
        toast.error("Please fill in service type");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePublish = (status) => {
    setShowloader(true);
    formData.description = formData.description || "E-Waste";

    addMPR(formData, status, dispatch).then((response) => {
      if (response.status === "200") {
        const updatedData = {
          ...response.Data,
          MPR: {
            ...response.Data.MPR,
            createdBy: logindata?.getData?._id,
          },
        };

        addLead(updatedData, dispatch).then((resp) => {
          if (resp.status === "200") {
            toast.success("Successfully created Lead & Assinged to you.");
            setTimeout(() => props.history.push("/app/MPR"), 2000);
          } else if (response.status === "401") {
            setShowloader(false);
            toast.error(response.message);
            setTimeout(() => {
              dispatch({ type: "LOG_OUT" });
              localStorage.clear();
            }, 2000);
          } else {
            let msg = [];
            if (response.ErrorData) {
              response.ErrorData.details.map((item) => {
                msg.push(item.message);
              });
              toast.error(`${msg.join()}`);
              setShowloader(false);
            }
          }
        });
      } else if (response.status === "401") {
        setShowloader(false);
        toast.error(response.message);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      } else {
        let msg = [];
        if (response.ErrorData) {
          response.ErrorData.details.map((item) => {
            msg.push(item.message);
          });
          toast.error(`${msg.join().replace("thisUser", "Contact")}`);
          setShowloader(false);
        }
        if (response.status === "404") {
          toast.error(response.message);
          setShowloader(false);
        }
      }
    });
  };

  const isNextButtonDisabled = () => {
    if (activeStep === 2) {
      return !formData.contact_contact;
    }
    return false;
  };

  return (
    <>
      <BreadCrumbs data={AddMPRData} />
      <Toaster />
      <div className={classes.content}>
        {isSmallScreen ? (
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                mb: 2,
                color: "primary.main",
                fontWeight: 500,
              }}
            >
              {steps[activeStep]}
            </Typography>
            <Stepper
              activeStep={activeStep}
              sx={{
                padding: "18px 0px",
                "& .MuiStepConnector-line": {
                  borderColor: "primary.light",
                },
                "& .MuiStepIcon-root": {
                  color: "grey.300",
                  "&.Mui-active": {
                    color: "primary.main",
                  },
                  "&.Mui-completed": {
                    color: "success.main",
                  },
                },
              }}
            >
              {steps.map((label, index) => (
                <Step
                  key={label}
                  sx={{
                    width: 24,
                    padding: 0,
                    "& .MuiStepLabel-root": {
                      padding: 0,
                    },
                  }}
                >
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
          </Box>
        ) : (
          <Stepper
            activeStep={activeStep}
            sx={{
              mb: 4,
              padding: 2,
              backgroundColor: "background.paper",
              borderRadius: 1,
              boxShadow: 1,
              alignItems: "center",
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        <div>
          {getStepContent(activeStep, formData, setForm)}
          <Paper className={classes.paperButton}>
            <Button
              href="/#/app/customers"
              className={classes.button}
              disabled={showLoader}
            >
              {t("common_cancel")}
            </Button>
            <Button
              disabled={activeStep === 0 || showLoader}
              onClick={handleBack}
              className={classes.button}
            >
              {t("common_back")}
            </Button>
            {activeStep === steps.length - 1 ? (
              <>
                {showLoader ? (
                  <CircularProgress size={28} />
                ) : (
                  <CustomButton
                    variant="contained"
                    title="Save lead"
                    onclick={() => handlePublish("Open")}
                  />
                )}
              </>
            ) : (
              <CustomButton
                variant="contained"
                title={t("common_next")}
                onclick={handleNext}
                disabled={isNextButtonDisabled()}
              />
            )}
          </Paper>
        </div>
      </div>
    </>
  );
}
