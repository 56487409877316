import React from "react";
import { Grid } from "@mui/material";

import Iframe from "react-iframe";

const QuotationPDF = ({ url, close, isCollapsed }) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={1} md={1}></Grid>
        <Grid item lg={8} md={12} sm={12} xs={12}>
          <Iframe
            url={url}
            width={isCollapsed ? "1000px" : "800px"}
            height="1200px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </Grid>
        <Grid item lg={2} md={1}></Grid>
      </Grid>
    </>
  );
};

export default QuotationPDF;
