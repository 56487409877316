import React from "react";
import { Grid, TextField, FormControlLabel, Switch } from "@mui/material"; // Added Switch
import { NumberFormatCustom } from "../../../../../../utils/currencyFormat";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const GSTCalculations = ({
  subtotal,
  setSubtotal,
  cgstPercentage,
  handleCgstChange,
  cgstAmount,
  sgstPercentage,
  handleSgstChange,
  sgstAmount,
  igstPercentage,
  handleIgstChange,
  igstAmount,
  gstAmount,
  otherCharges,
  setOtherCharges,
  classes,
  setIncludeGST,
  includeGST,
}) => {
  const { t } = useTranslation();

  const handleGSTToggle = (event) => {
    setIncludeGST(event.target.checked);
  };

  return (
    <Grid container spacing={1}>
      <Grid item lg={6} md={6} xs={1} sm={1} />
      <Grid item lg={6} md={6} xs={11} sm={11}>
        <TextField
          className={classes.formControl}
          label={t("Taxable Amount")}
          value={subtotal}
          onChange={(e) => setSubtotal(e.target.value)}
          name="numberformat"
          id="formatted-numberformat-input-subtotal"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: NumberFormatCustom,
            readOnly: true,
          }}
        />
      </Grid>
      
      {/* GST Toggle Switch */}
      <Grid item lg={6} md={6} xs={1} sm={1} />
      <Grid item lg={6} md={6} xs={11} sm={11}>
        <FormControlLabel
          control={
            <Switch
              checked={includeGST}
              onChange={handleGSTToggle}
              name="gstToggle"
              color="primary"
            />
          }
          label={t("Include GST")}
        />
      </Grid>

      {includeGST && (
        <>
          {/* CGST Section */}
          <Grid item lg={6} md={6} xs={1} sm={1} />
          <Grid item lg={2} md={2} xs={5} sm={5}>
            <TextField
              className={classes.formControl}
              label={t("CGST (%)")}
              value={cgstPercentage}
              onChange={(e) => handleCgstChange(e.target.value)}
              name="numberformat"
              id="formatted-numberformat-input-cgst-percentage"
              InputLabelProps={{ shrink: true }}
              disabled={Boolean(gstAmount?.igst)}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={6} sm={6}>
            <TextField
              className={classes.formControl}
              label={t("CGST Amount")}
              value={cgstAmount}
              name="numberformat"
              id="formatted-numberformat-input-cgst"
              InputLabelProps={{ shrink: true }}
              disabled={Boolean(gstAmount?.igst)}
              InputProps={{
                inputComponent: NumberFormatCustom,
                readOnly: true,
              }}
            />
          </Grid>

          {/* SGST Section */}
          <Grid item lg={6} md={6} xs={1} sm={1} />
          <Grid item lg={2} md={2} xs={5} sm={5}>
            <TextField
              className={classes.formControl}
              label={t("SGST (%)")}
              value={sgstPercentage}
              onChange={(e) => handleSgstChange(e.target.value)}
              name="numberformat"
              id="formatted-numberformat-input-sgst-percentage"
              InputLabelProps={{ shrink: true }}
              disabled={Boolean(gstAmount?.igst)}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={6} sm={6}>
            <TextField
              className={classes.formControl}
              label={t("SGST Amount")}
              value={sgstAmount}
              name="numberformat"
              id="formatted-numberformat-input-sgst"
              InputLabelProps={{ shrink: true }}
              disabled={Boolean(gstAmount?.igst)}
              InputProps={{
                inputComponent: NumberFormatCustom,
                readOnly: true,
              }}
            />
          </Grid>

          {/* IGST Section */}
          <Grid item lg={6} md={6} xs={1} sm={1} />
          <Grid item lg={2} md={6} xs={5} sm={5}>
            <TextField
              className={classes.formControl}
              label={t("IGST (%)")}
              value={igstPercentage}
              onChange={(e) => handleIgstChange(e.target.value)}
              name="numberformat"
              id="formatted-numberformat-input-igst-percentage"
              InputLabelProps={{ shrink: true }}
              disabled={Boolean(gstAmount?.cgst || gstAmount?.sgst)}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={6} sm={6}>
            <TextField
              className={classes.formControl}
              label={t("IGST Amount")}
              value={igstAmount}
              name="numberformat"
              id="formatted-numberformat-input-igst"
              InputLabelProps={{ shrink: true }}
              disabled={Boolean(gstAmount?.cgst || gstAmount?.sgst)}
              InputProps={{
                inputComponent: NumberFormatCustom,
                readOnly: true,
              }}
            />
          </Grid>
        </>
      )}

      {/* Other Charges Section */}
      <Grid item lg={6} md={6} xs={1} sm={1} />
      <Grid item lg={6} md={6} xs={11} sm={11}>
        <TextField
          className={classes.formControl}
          label={t("Other Charges")}
          value={otherCharges}
          onChange={(e) => setOtherCharges(e.target.value)}
          name="numberformat"
          id="formatted-numberformat-input-other-charges"
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
      </Grid>
    </Grid>
  );
};

GSTCalculations.propTypes = {
  cgstPercentage: PropTypes.string.isRequired,
  handleCgstChange: PropTypes.func.isRequired,
  cgstAmount: PropTypes.string.isRequired,
  sgstPercentage: PropTypes.string.isRequired,
  handleSgstChange: PropTypes.func.isRequired,
  sgstAmount: PropTypes.string.isRequired,
  igstPercentage: PropTypes.string.isRequired,
  handleIgstChange: PropTypes.func.isRequired,
  igstAmount: PropTypes.string.isRequired,
  gstAmount: PropTypes.object.isRequired,
  otherCharges: PropTypes.string.isRequired,
  setOtherCharges: PropTypes.func.isRequired,
  includeGST: PropTypes.bool.isRequired,
  setIncludeGST: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default GSTCalculations;
