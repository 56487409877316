import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Box,
  Paper,
  Typography,
  Stack,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Table from "../../../components/Table/Table";
import { getAllEmployessLeavesInfo } from "../apis";
import toast from "react-hot-toast";
import { getCurrentYear, monthArray } from "../../../utils/helpers";

export default function EmployeesLeavesSummaryTable() {
  const { t } = useTranslation();
  const [fetchLoader, setFetchLoader] = useState(false);
  const [allLeavesData, setAllLeavesData] = useState([]);
  const [year, setYear] = useState("");

  async function getTheDashboardData() {
    const currentYear = getCurrentYear();
    setYear(currentYear);
    setFetchLoader(true);
    try {
      const formData = { year: currentYear };
      const response = await getAllEmployessLeavesInfo(formData);

      if (response.status !== "200") {
        toast.error("An error occurred. Please try again.");
        return;
      }

      setAllLeavesData(response.data);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setFetchLoader(false);
    }
  }

  useEffect(() => {
    getTheDashboardData();
  }, []);

  const datatableHeader = [
    {
      label: t("Employee"),
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Typography variant="body2" fontWeight={500}>
            {value}
          </Typography>
        ),
      },
    },
    {
      label: t("Employee Type"),
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Typography variant="body2" fontWeight={500}>
            {value}
          </Typography>
        ),
      },
    },
    {
      label: t("Casual Leave"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          const [used, available] = value.split(" / ");
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Chip
                label={`${used} `}
                size="small"
                color={parseInt(used) > 0 ? "primary" : "default"}
                sx={{ minWidth: 80 }}
              />
              <Typography variant="body2" color="text.secondary">
                {available}
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      label: t("Emergency Leave"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          const [used, available] = value.split(" / ");
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Chip
                label={`${used} `}
                size="small"
                color={parseInt(used) > 0 ? "warning" : "default"}
                sx={{ minWidth: 80 }}
              />
              <Typography variant="body2" color="text.secondary">
                {available}
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      label: t("Marriage Leave"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          const [used, available] = value.split(" / ");
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Chip
                label={`${used}`}
                size="small"
                color={parseInt(used) > 0 ? "success" : "default"}
                sx={{ minWidth: 80 }}
              />
              <Typography variant="body2" color="text.secondary">
                {available}
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      label: t("Privilege Leave"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          const [used, available] = value.split(" / ");
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Chip
                label={`${used} `}
                size="small"
                color={parseInt(used) > 0 ? "success" : "default"}
                sx={{ minWidth: 80 }}
              />
              <Typography variant="body2" color="text.secondary">
                {available}
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      label: t("Extra Leaves"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          if (value === "None") {
            return (
              <Typography variant="body2" color="text.secondary">
                None
              </Typography>
            );
          }

          const leaves = value.split(", ");
          return (
            <Stack direction="column" spacing={1}>
              {leaves.map((leave, index) => (
                <Chip
                  key={index}
                  label={leave}
                  size="small"
                  color="error"
                  sx={{ maxWidth: 200 }}
                />
              ))}
            </Stack>
          );
        },
      },
    },
  ];

  const tableData =
    allLeavesData?.map((item) => {
      const extraLeaveData = item.leaveData.extraLeave
        .map(
          (extra) =>
            `${extra.totalLeave} days in ${monthArray[extra.month - 1]}`
        )
        .join(", ");

      return [
        item?.userName,
        item?.isFactoryEmployee ? "Factory" : "Office",
        `${item?.leaveData?.casualLeave?.used} used / ${item?.leaveData?.casualLeave?.remaining} remaining`,
        `${item?.leaveData?.emergencyLeave?.used} used / ${item?.leaveData?.emergencyLeave?.remaining} remaining`,
        `${item?.leaveData?.marriageLeave?.used} used / ${item?.leaveData?.marriageLeave?.remaining} remaining`,
        `${item?.leaveData?.privilegeLeave?.used} used / ${item?.leaveData?.privilegeLeave?.remaining} remaining`,
        item.leaveData.extraLeave.length > 0 ? extraLeaveData : "None",
      ];
    }) || [];

  return (
    <Box sx={{ minHeight: "100%" }}>
      {/* Header */}
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 3,
          background: "linear-gradient(to right, #f8fafc, #ffffff)",
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Leave Overview {year}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Employee leave allocation and usage summary
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Paper>

      {/* Table */}
      <Paper elevation={1} sx={{ p: 2 }}>
        <Table
          data={tableData}
          header={datatableHeader}
          options={{
            filterType: "dropdown",
            print: false,
            download: true,
            downloadOptions: {
              filename: `Binbag-employees-leaveSummary-${year}`,
            },
            filter: true,
            selectableRows: "none",
            elevation: 0,
            customSort: (data, colIndex, order) => {
              return data.sort(
                (a, b) =>
                  (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) *
                  (order === "desc" ? 1 : -1)
              );
            },
            // tableBodyHeight: "550px",
            textLabels: {
              body: {
                noMatch: fetchLoader ? (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ py: 3 }}
                  >
                    <CircularProgress size={20} color="primary" />
                    <Typography>Loading data...</Typography>
                  </Stack>
                ) : (
                  <Typography color="text.secondary" sx={{ py: 3 }}>
                    No data available
                  </Typography>
                ),
              },
            },
          }}
        />
      </Paper>
    </Box>
  );
}
