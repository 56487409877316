import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Paper,
  Divider,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UploadFiles from "../../components/FileUploads/UploadFiles";
import { viewFiles } from "../../utils/getFiles";
import { uploadEmployeeDocuments, getEmployees } from "./apis";
import toast from "react-hot-toast";
import { documentSections } from "./data";

const HeaderCard = () => {
  return (
    <Paper
      sx={{
        width: "100%",
        p: 2,
        mb: 4,
      }}
      elevation={1}
    >
      <Box />
      <Stack spacing={2} sx={{ flex: 1 }}>
        <Typography variant="h6" fontWeight={600}>
          Document Management
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Manage all your important documents in one place. Upload, view, and
          organize your files securely.
        </Typography>
      </Stack>
    </Paper>
  );
};

const DocumentSection = ({
  title,
  onFileUpload,
  uploadedFilesFromServer,
  saveFile,
  newFilesToBUploaded,
  uploadingFilesToServer,
  fetching,
}) => {
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleUploadedFiles = (files) => {
    const filteredFiles = files.filter((item) => Object.keys(item).length > 0);
    const filesWithoutRef = filteredFiles.map((item) => {
      const { ref, ...newItem } = item;
      return newItem;
    });
    onFileUpload(filesWithoutRef);
  };

  return (
    <Paper sx={{ p: 3, mb: 2 }} elevation={0}>
      <Stack spacing={2}>
        <UploadFiles
          fileUploadData={handleUploadedFiles}
          setIsUploading={setUploadingFile}
          fileUploadType={`${title}`}
          title={`Upload ${title} `}
        />
        {fetching ? (
          <Typography
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            <CircularProgress size={24} /> Fetching uploaded files...
          </Typography>
        ) : (
          uploadedFilesFromServer.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "grey.100",
                p: 1,
                borderRadius: 1,
                gap: 1,
              }}
            >
              {uploadedFilesFromServer.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    bgcolor: "white",
                    p: 1,
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ flex: 1, mr: 2 }}>
                    {file.fileName}
                  </Typography>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => viewFiles(file)}
                      color="primary"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          )
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={saveFile}
          sx={{ ml: 2 }}
          disabled={
            uploadingFile ||
            newFilesToBUploaded.length === 0 ||
            uploadingFilesToServer ||
            fetching
          }
        >
          {uploadingFilesToServer ? (
            <CircularProgress size={24} />
          ) : (
            <Typography>Save Documents</Typography>
          )}
        </Button>
      </Stack>
    </Paper>
  );
};

const Documents = () => {
  const breadcrumb = [
    { label: "Home", link: "/#/app/dashboard" },
    { label: "Employee portal", link: "/#/app/employeePortal" },
    { label: "Documents", link: "" },
  ];

  const data = useSelector((state) => state.userdata.getData);
  const [employeeFiles, setEmployeeFiles] = useState();
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadingFilesToServer, setUploadingFilesToServer] = useState(false);
  const [fetching, setFetching] = useState(false);

  const uploadEmployeeFiles = async () => {
    setUploadingFilesToServer(true);
    const uploadData = {
      userId: data?._id,
      documentData: {
        ...uploadedFiles,
      },
    };
    const response = await uploadEmployeeDocuments(uploadData);
    if (response.status === "200") {
      toast.success("File uploaded suceessfully");
      setUploadingFilesToServer(false);
      fetchEmpDocuments();
      setUploadedFiles({});
    } else {
      toast.error("An error occured! Please try again");
      setUploadingFilesToServer(false);
    }
  };

  const handleUploadedFiles = (files, fileType) => {
    setUploadedFiles((prev) => ({
      ...prev,
      [fileType]: files
        .filter((item) => Object.keys(item).length > 0)
        .map((item) => {
          const { ref, ...newItem } = item;
          return newItem;
        }),
    }));
  };

  const fetchEmpDocuments = async () => {
    setFetching(true);
    try {
      const sendData = {
        searchTerm: data?.email,
      };
      const response = await getEmployees(sendData);

      if (response.status === "200" && response?.data?.length !== 0) {
        setEmployeeFiles(response?.data[0].employeeData.documents || []);
        setFetching(false);
      }
    } catch (error) {
      toast.error("Failed to fetch contacts");
      setFetching(false);
    }
  };
  useEffect(() => {
    fetchEmpDocuments();
  }, [data]);

  return (
    <Box sx={{ minHeight: "100vh", marginTop: "100px" }}>
      <Box sx={{ mb: 3 }}>
        <BreadCrumbs data={breadcrumb} />
      </Box>

      <HeaderCard saveFile={uploadEmployeeFiles} />

      <Stack spacing={4}>
        {documentSections.map((section, index) => (
          <Box key={index}>
            <Typography variant="h6" sx={{ mb: 3, color: "primary.main" }}>
              {section.title}
            </Typography>
            <Grid container spacing={3}>
              {section.subsections.map((subsection, subIndex) => (
                <Grid item xs={12} md={6} key={subIndex}>
                  <DocumentSection
                    {...subsection}
                    onFileUpload={(files) =>
                      handleUploadedFiles(files, subsection.schemaKey)
                    }
                    uploadedFilesFromServer={
                      employeeFiles && employeeFiles[subsection?.schemaKey]
                        ? employeeFiles[subsection?.schemaKey]
                        : []
                    }
                    newFilesToBUploaded={
                      uploadedFiles[subsection?.schemaKey]
                        ? uploadedFiles[subsection?.schemaKey]
                        : []
                    }
                    saveFile={uploadEmployeeFiles}
                    uploadingFilesToServer={uploadingFilesToServer}
                    fetching={fetching}
                  />
                </Grid>
              ))}
            </Grid>
            {index < documentSections.length - 1 && <Divider sx={{ mt: 4 }} />}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Documents;
