import React from "react";
import {
  Grid,
  TextField,
  Paper,
  Typography,
  Box,
  InputAdornment,
  Divider,
  Chip,
  Tabs,
  Tab,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import BusinessIcon from "@mui/icons-material/Business";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const QuotationHeader = ({
  headerTitle = "New Quotation",
  letterhead = "BRSPL",
  setLetterhead,
  quotationNo,
  generatedDate,
  handleGenDate,
  customer,
  quotationSubject,
  handleQuotationSubChange,
}) => {
  const handleLetterheadChange = (event, newValue) => {
    setLetterhead(newValue);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {headerTitle}
          </Typography>
          <Tabs
            value={letterhead}
            onChange={handleLetterheadChange}
            sx={{
              minHeight: 40,
              "& .MuiTab-root": {
                minHeight: 40,
                px: 3,
              },
            }}
          >
            <Tab
              label="BRSPL"
              value="BRSPL"
              sx={{
                borderRadius: "4px 4px 0 0",
                "&.Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "white",
                },
              }}
            />
            <Tab
              label="UGT"
              value="UGT"
              sx={{
                borderRadius: "4px 4px 0 0",
                "&.Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "white",
                },
              }}
            />
          </Tabs>
        </Box>
        <Chip
          label={`#${quotationNo}`}
          sx={{
            fontSize: "1rem",
            height: 32,
            "& .MuiChip-icon": {
              fontSize: "1.2rem",
              marginLeft: "8px",
            },
          }}
        />
      </Box>

      <Divider sx={{ mb: 3 }} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Customer"
            value={customer}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              value={generatedDate || new Date()}
              onChange={handleGenDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Subject"
            value={quotationSubject}
            onChange={handleQuotationSubChange}
            required
            multiline
            rows={2}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QuotationHeader;
