import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Button,
  FormControlLabel,
  Typography,
  CircularProgress,
  Switch,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Divider,
  Box,
  Alert,
  IconButton,
  Tooltip,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import { assignLead, getLead } from "../../../LeadState";
import Table from "../../../../../components/Table/Table";
import toast from "react-hot-toast";
import AddInspectionSlotDialog from "./components/AddInspectionSlotDialog";
import InspectionCompletionDialog from "./components/InspectionCompletionDialog";
import { handleDownloadImage, inspectionHeader, getFiles } from "./utils";
import moment from "moment";
import { StyledPaper, StyledCard } from "../../../styles";

const MPRSlot = ({ list, slotData, lead, close, choosen, inspectionClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [slot, setSlot] = useState([]);
  const [inspectionSlots, setInspectionSlots] = useState(
    list.inspection_slot || []
  );
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [completedSlot, setCompletedSlot] = useState(null);
  const [openCompletionDialog, setOpenCompletionDialog] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [isAddingSlot, setIsAddingSlot] = useState(false);
  const [isCompletingInspection, setIsCompletingInspection] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);

  const isInspectionCompleted = inspectionSlots.some(
    (slot) => slot.isCompletedOne
  );

  const handleChange = (slotItem) => {
    if (!isInspectionCompleted) {
      setSelectedSlot(slotItem);
      setOpenCompletionDialog(true);
    }
  };

  const handleCompletionSubmit = async (completionData) => {
    setIsCompletingInspection(true);
    try {
      const updatedSlots = inspectionSlots.map((slot) => ({
        ...slot,
        isCompletedOne: slot.id === completionData.slotDetails.id,
      }));

      const data = {
        external_id: lead,
        leadData: {
          status: "Inspection completed",
          inspectionCompleted: true,
          inspectionCompletedData: completionData,
          inspection_slot: updatedSlots,
        },
        mpr_id: list.Mpr_id,
      };

      await apiCall(data, "Successfully completed the inspection");
      setCompletedSlot(completionData.slotDetails);
      setOpenCompletionDialog(false);
      toast.success("Inspection completed successfully");
    } catch (error) {
      toast.error("Error completing inspection: " + error.message);
    } finally {
      setIsCompletingInspection(false);
    }
  };

  const handleAddSlot = async (newSlot) => {
    if (isInspectionCompleted) {
      toast.error("Cannot add new slots after inspection is completed");
      return;
    }

    setIsAddingSlot(true);
    try {
      const date = new Date();
      const uniqueId = `S${date.getTime()}`;
      const data = {
        external_id: lead,
        leadData: {
          inspection_slot: {
            id: uniqueId,
            inspectionDate: newSlot.inspectionDate,
            time: newSlot.time,
            status: "selected",
            isCompletedOne: false,
          },
          status: "Inspection Slot choose",
        },
        mpr_id: list.Mpr_id,
      };

      await apiCall(data, "Successfully Added New Inspection Slot");
      setOpenAddDialog(false);
    } finally {
      setIsAddingSlot(false);
    }
  };

  const apiCall = async (data, successMessage) => {
    if (!lead) {
      toast.error("No lead provided");
      return;
    }

    try {
      const response = await assignLead(data, dispatch);
      if (response.status === "200") {
        toast.success(successMessage);
        const leadResponse = await getLead(list.external_id, dispatch);
        setInspectionSlots(leadResponse.thisLeadDetails.inspection_slot);
      } else {
        throw new Error("API call failed");
      }
    } catch (error) {
      toast.error("Operation failed: " + error.message);
    }
  };

  useEffect(() => {
    const fetchImageUrls = async () => {
      if (list.inspectionCompletedData?.images?.length) {
        setIsLoadingImages(true);
        try {
          const urls = await Promise.all(
            list.inspectionCompletedData.images.map((image) => getFiles(image))
          );
          setImageUrls(urls.map((response) => response.reference));
        } catch (error) {
          toast.error("Error loading images: " + error.message);
        } finally {
          setIsLoadingImages(false);
        }
      }
    };

    fetchImageUrls();
  }, [list.inspectionCompletedData]);

  useEffect(() => {
    const data = inspectionSlots.map((item) => {
      const action = (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={item.isCompletedOne}
                onChange={() => handleChange(item)}
                disabled={isInspectionCompleted}
                color="primary"
              />
            }
            label={
              <Typography variant="body2" color="textSecondary">
                Mark completed
              </Typography>
            }
          />
          {item.isCompletedOne && (
            <Tooltip title="Inspection was completed on this slot">
              <IconButton size="small" color="success">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );

      return [item.inspectionDate, item.time, action];
    });

    setSlot(data);
  }, [inspectionSlots, isInspectionCompleted, t]);

  return (
    <StyledPaper>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="h2">
              {t("common_inspection_slots")}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddDialog(true)}
              disabled={isInspectionCompleted}
            >
              {t("Add Slot")}
            </Button>
          </Box>
          {isInspectionCompleted && (
            <Alert severity="info" sx={{ mt: 2 }}>
              Inspection has been completed. No further modifications are
              allowed.
            </Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          <Table
            data={slot}
            header={inspectionHeader}
            options={{
              filterType: "checkbox",
              print: false,
              download: false,
              filter: false,
              selectableRows: "none",
            }}
          />
        </Grid>

        {list.inspectionCompletedData && (
          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h6" pt={2}>
                Inspection Completion Details
              </Typography>
              <Divider sx={{ my: 2 }} />

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="subtitle2" color="textSecondary">
                        Completed Date & Time
                      </Typography>
                      <Typography variant="body1">
                        {
                          list.inspectionCompletedData.slotDetails
                            .inspectionDate
                        }{" "}
                        at {list.inspectionCompletedData.slotDetails.time}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="subtitle2" color="textSecondary">
                        Updated At
                      </Typography>
                      <Typography variant="body1">
                        {moment(
                          list.inspectionCompletedData.completedAt
                        ).format("DD MMM YYYY, h:mm A")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="subtitle2" color="textSecondary">
                        Notes
                      </Typography>
                      <Typography variant="body1">
                        {list.inspectionCompletedData.notes ||
                          "No notes provided"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="subtitle2" color="textSecondary">
                        Feedback
                      </Typography>
                      <Typography variant="body1">
                        {list.inspectionCompletedData.feedback ||
                          "No feedback provided"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/* Images Section */}
              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Inspection Images
              </Typography>
              {list.inspectionCompletedData?.images.length !== 0 ? (
                <Grid container spacing={2}>
                  {isLoadingImages ? (
                    <Grid item xs={12} sx={{ textAlign: "center", py: 4 }}>
                      <CircularProgress />
                    </Grid>
                  ) : (
                    imageUrls.map((url, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <StyledCard>
                          <CardMedia
                            component="img"
                            height="200"
                            image={url}
                            alt={`Inspection ${index + 1}`}
                            sx={{ objectFit: "cover" }}
                          />
                          <CardActions>
                            <Button
                              fullWidth
                              variant="contained"
                              startIcon={<DownloadIcon />}
                              onClick={() =>
                                handleDownloadImage(
                                  url,
                                  `inspection-image-${index + 1}.jpg`
                                )
                              }
                            >
                              Download
                            </Button>
                          </CardActions>
                        </StyledCard>
                      </Grid>
                    ))
                  )}
                </Grid>
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{ px: 1, py: 3 }}
                  color={"red"}
                >
                  No images found!
                </Typography>
              )}
            </StyledPaper>
          </Grid>
        )}
      </Grid>

      <AddInspectionSlotDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        onSubmit={handleAddSlot}
        formData={list.MPRDetails?.slot}
        isLoading={isAddingSlot}
      />

      <InspectionCompletionDialog
        open={openCompletionDialog}
        onClose={() => setOpenCompletionDialog(false)}
        onSubmit={handleCompletionSubmit}
        selectedSlot={selectedSlot}
        isLoading={isCompletingInspection}
      />
    </StyledPaper>
  );
};

export default MPRSlot;
