import React, { useEffect, useState } from "react";
import { Tabs, Tab, Badge } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDebounce } from "use-debounce";
import toast from "react-hot-toast";
import { formatIndianCurrency } from "../../../utils/currencyFormat";
import Table from "../../../components/Table/Table";
import useStyles from "../styles";
import DDRequestForm from "./DDRequestForm";
import { getAllDDRequests } from "../InternalFormState";
import SectionHeader from "../components/SectionHeader";
import {
  TabPanel,
  a11yProps,
  getStatusStyle,
} from "../components/config/tableHelper";
import { tableOptions } from "../components/config/tableConfig";

const ddTableHeader = [
  { name: "Date", options: { filter: false } },
  { name: "Id", options: { filter: false, display: false } },
  { name: "DD number", options: { filter: false, display: false } },
  { name: "DD type", options: { filter: true } },
  { name: "Amount", options: { filter: false } },
  { name: "Payment from", options: { filter: true } },
  { name: "Raised by", options: { filter: true } },
  { name: "Adjusted against", options: { filter: false, display: false } },
  { name: "Status", options: { filter: true } },
];

export default function TabPannelDD() {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const data = useSelector((state) => state.userdata.getData);
  const [ddRequests, setDDRequests] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

  const [tabCounts, setTabCounts] = useState({
    all: 0,
    pending: 0,
    submitted: 0,
  });

  const [pageData, setPageData] = useState({
    page: 1,
    limit: 10,
    searchTerm: "",
  });
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 20, 50]);

  let tabData = [];
  let myRequests = [];
  let allRequests = [];
  let pendingRequests = [];
  let ddSubmitted = [];

  if (
    data?.roles.includes("user") &&
    !data?.roles.includes("Viewer") &&
    !data?.roles.includes("Approver")
  ) {
    tabData = [{ label: "My Requests", index: 0, name: "", data: myRequests }];
  } else if (
    data?.roles.some((role) => ["Viewer", "Approver", "Admin"].includes(role))
  ) {
    tabData = [
      { label: "All", index: 0, name: "", data: allRequests },
      { label: "Pending", index: 1, name: "", data: pendingRequests },
      { label: "DD Submitted", index: 2, name: "", data: ddSubmitted },
    ];
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function fetchDDRequests(data) {
    setFetchLoader(true);
    try {
      const allDDRequests = await getAllDDRequests(data);
      if (allDDRequests.status === "200") {
        setDDRequests(allDDRequests.data);
        setTotalPages(allDDRequests.totalCount);
      } else {
        setDDRequests([]);
      }
    } catch (error) {
      toast.error("Error fetching DD requests");
    } finally {
      setFetchLoader(false);
    }
  }

  useEffect(() => {
    const maxRowsPerPage = totalPages || 10;
    const dynamicRowsPerPageOptions = [10, 20, 50].filter(
      (option) => option <= maxRowsPerPage
    );
    if (!dynamicRowsPerPageOptions.includes(maxRowsPerPage)) {
      dynamicRowsPerPageOptions.push(maxRowsPerPage);
    }
    setRowsPerPageOptions(dynamicRowsPerPageOptions);
  }, [totalPages]);

  useEffect(() => {
    fetchDDRequests({
      ...pageData,
      searchTerm: debouncedSearchTerm,
    });
  }, [showForm, pageData.page, pageData.limit, debouncedSearchTerm]);

  useEffect(() => {
    if (ddRequests && ddRequests.length > 0) {
      const counts = ddRequests.reduce(
        (acc, item) => {
          acc.all++;
          if (item.status === "Pending") acc.pending++;
          if (item.status === "DD Submitted") acc.submitted++;
          return acc;
        },
        { all: 0, pending: 0, submitted: 0 }
      );

      setTabCounts(counts);
    }
  }, [ddRequests]);

  const handleToggleView = (data) => {
    setShowForm(data);
  };

  const handleRowClick = (rowData, rowMeta) => {
    const selectedId = rowData[1];
    history.push(`/app/editDDRequest/${selectedId}`);
  };

  const handleSearchChange = (searchText) => {
    setSearchTerm(searchText);
    setPageData((prevState) => ({
      ...prevState,
      page: 1,
    }));
  };

  return (
    <>
      <div className={classes.root}>
        <SectionHeader
          title={showForm ? "New request" : "DD requests"}
          buttonText={showForm ? "All requests" : "New"}
          buttonAction={() => setShowForm((prev) => !prev)}
          buttonStyle={{ margin: "15px 10px" }}
        />

        {showForm ? (
          <DDRequestForm toggleView={handleToggleView} />
        ) : (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
            >
              {tabData.map((item, key) => (
                <Tab
                  key={key}
                  label={
                    item.label === "DD Submitted" ? (
                      <Badge
                        badgeContent={tabCounts.submitted}
                        color="primary"
                        sx={{
                          "& .MuiBadge-badge": {
                            right: -3,
                            top: -5,
                          },
                        }}
                      >
                        {item.label}
                      </Badge>
                    ) : (
                      item.label
                    )
                  }
                  {...a11yProps(item.index)}
                  classes={{ root: classes.tab }}
                />
              ))}
            </Tabs>
            {ddRequests &&
              ddRequests.length > 0 &&
              ddRequests.map((item) => {
                const statusStyle = getStatusStyle(item.status);
                const row = [
                  moment(item?.createdAt).format("DD-MM-YYYY"),
                  item?.id,
                  item?.ddNumber ? item?.ddNumber : "N/A",
                  item?.type,
                  formatIndianCurrency(item?.amount),
                  item?.paymentFrom,
                  item?.raisedBy[0]?.username,
                  item?.tenderAdjustedAgainst || "N/A",
                  <span style={statusStyle}>{item?.status}</span>,
                ];

                if (item?.status === "Pending") {
                  pendingRequests.push(row);
                }
                if (item?.status === "DD Submitted") {
                  ddSubmitted.push(row);
                }
                if (data?._id === item?.raisedBy[0]?._id) {
                  myRequests.push(row);
                }
                allRequests.push(row);
              })}

            {tabData.map((item, key) => (
              <TabPanel value={value} index={item.index} key={key}>
                <Table
                  data={item.data}
                  header={ddTableHeader}
                  name={""}
                  options={{
                    ...tableOptions(
                      fetchLoader,
                      rowsPerPageOptions,
                      setPageData,
                      handleRowClick,
                      "DD_Requests.csv",
                      totalPages,
                      pageData.page,
                      handleSearchChange
                    ),
                    tableBodyHeight: "550px",
                  }}
                />
              </TabPanel>
            ))}
          </>
        )}
      </div>
    </>
  );
}
