import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { TableContainer, Box, Card, Chip } from "@mui/material";

export default makeStyles((theme) => ({
  dashBox: {
    borderRadius: "10px",
    fontWeight: "bold",
    padding: "10px",
  },

  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },

  content: {
    padding: theme.spacing(4),
    height: "100dvh",
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(8),
    },
  },
  statusDiv: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      margin: "-24px",
      marginTop: theme.spacing(1),
    },
  },

  title: {
    marginLeft: "10px",
    marginBottom: "10px",
  },

  HeaderContainerEdit: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    justifyContent: "space-between",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  tagButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },

  form1: {
    marginTop: "2rem",
  },
  fileTypeInput: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    padding: "10px",
    borderRadius: "4px",
    marginLeft: "10px",
  },

  tableCell: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    border: "none",
  },

  dashLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "60vh",
  },

  dashBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    marginTop: "-10px",
  },
  optionsContainer: {
    marginTop: "10px",
    marginBottom: "18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  box: {
    display: "flex",
    gap: 10,
    width: "70%",
  },
  box2: {
    display: "flex",
    gap: 10,
    width: "70%",
    justifyContent: "space-between",
  },

  root: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.3s, box-shadow 0.3s",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    "&:hover": {
      boxShadow: theme.shadows[4],
    },
  },
  cardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  icon: {
    fontSize: 30,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(3),
  },
  height: "100%",
  boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 6px 16px rgba(0,0,0,0.1)",
  },
}));

export const LeaveStatCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  boxShadow: "none",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  "& .MuiTableCell-head": {
    backgroundColor: theme.palette.background.paper,
    fontWeight: 600,
  },
  "& .MuiTableCell-body": {
    fontSize: "0.875rem",
  },
}));

export const StatusChip = styled(Chip)(({ theme, variant }) => ({
  fontWeight: 500,
}));
