import React from "react";
import { Controller } from "react-hook-form";
import { TextField, Grid } from "@mui/material";

const ContactForm = ({ control, errors }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name="fullName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="standard"
              label="Full Name*"
              error={!!errors.fullName}
              helperText={errors.fullName?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="mobileNumber"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="standard"
              label="Mobile Number*"
              error={!!errors.mobileNumber}
              helperText={errors.mobileNumber?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="standard"
              label="Email*"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ContactForm;
