// import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
// import {
//   FormControl,
//   Input,
//   InputLabel,
//   Button,
//   CircularProgress,
//   Select,
//   Grid,
//   MenuItem,
//   TextField,
//   Dialog,
//   Typography,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Divider,
//   Box,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import {
//   NumberFormatCustom,
//   formatIndianCurrency,
// } from "../../../../../utils/currencyFormat";
// import moment from "moment";
// import useStyles from "../styles";
// import { assignLead, updatePanGst } from "../../../LeadState";
// import toast from "react-hot-toast";
// import PaymentRequestForm from "../../../../internalForms/paymentRequests/supplierCustomer/PaymentRequestForm";

// const AddPayment = (props) => {
//   const { t } = useTranslation();

//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const [selectedPickUpDate, setSelectedPickupDate] = useState(
//     props.payment && Object.keys(props.payment).length !== 0
//       ? new Date(props.payment.payBy)
//       : new Date()
//   );
//   const [paidOn, setPaidOn] = useState(new Date());
//   const [quotevalue, setQuoteValue] = useState(
//     props.payment && Object.keys(props.payment).length !== 0
//       ? props.payment.totalAmount
//       : props.list.transactionAmount !== ""
//         ? props.list.transactionAmount.amount
//         : ""
//   );
//   const [region, setRegion] = useState("INR");
//   const [paymethod, setPaymethod] = useState("");
//   const [transId, setTransId] = useState("");
//   const [amountPaid, setAmountPaid] = useState("");
//   const [showloader, setShowLoader] = useState(false);
//   var loggedInDatas = useSelector((state) => state.userdata);
//   const [open, setOpen] = useState(false);
//   const [pan, setPan] = useState("");
//   const [gst, setGst] = useState("");
//   const [msg, setMsg] = useState("");
//   const [showPanError, setShowPanError] = useState(false);
//   const [showGstError, setShowGstError] = useState(false);
//   const [customerId, setCustomerId] = useState("");
//   const [messageDataGst, setMessageDataGst] = useState("");
//   const [messageDataPan, setMessageDataPan] = useState("");
//   const [supplierPaymentId, setSupplierPaymentId] = useState("");

//   const onResponseFromSupplierPaymentApi = (data) => {
//     setSupplierPaymentId(data.paymentId);
//     console.log("dataparent", data); // Do something with the data
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setShowPanError(false);
//     setShowGstError(false);
//     setPan("");
//     setGst("");
//   };

//   const handlepickupDate = (date) => {
//     setSelectedPickupDate(date);
//   };
//   const handlepaidOn = (date) => {
//     setPaidOn(date);
//   };
//   const handleSubmit = () => {
//     setShowLoader(true);
//     let status;
//     let paymentStatus;
//     let paidon;
//     if (
//       loggedInDatas.getData &&
//       loggedInDatas.getData.type === "tenant" &&
//       !loggedInDatas.getData.roles.includes("user")
//     ) {
//       status = "Payment Completed";
//       paymentStatus = "Paid";
//       paidon = paidOn;
//     } else {
//       status = "Payment Initiated";
//       paymentStatus = "Initiated";
//       paidon = "";
//     }

//     let data = {
//       external_id: props.list.external_id,
//       leadData: {
//         payment: {
//           totalAmount: quotevalue,
//           paymentMode: paymethod,
//           transactionID: transId,
//           payBy: selectedPickUpDate,
//           PaidOn: paidon,
//           amountPaid: amountPaid,
//           paymentStatus: paymentStatus,
//           supplierPaymentId,
//         },
//         status: status,
//       },
//       mpr_id: props.list.Mpr_id,
//     };
//     assignLead(data, dispatch).then((response) => {
//       setGst("");
//       setPan("");
//       if (response.status === "200") {
//         toast.success("Payment Saved Successfully");
//         setTimeout(() => props.close(), 2000);
//         setShowLoader(false);
//       } else if (response.status === "500") {
//         toast.error(response.message);
//         setShowLoader(false);
//         setGst(response.gst);
//         setPan(response.pan);
//         setMsg(
//           pan === "" && gst !== ""
//             ? "PAN is not available"
//             : gst === "" && pan !== ""
//               ? "GST is not available"
//               : pan === "" && gst === ""
//                 ? "PAN and GST is not available"
//                 : ""
//         );
//         setCustomerId(response.customerId);
//         handleClickOpen();
//       } else {
//         toast.error("Something went wrong");
//         setShowLoader(false);
//       }
//     });
//   };

//   const handlePanGst = () => {
//     if (!showGstError && !showPanError) {
//       let data = {
//         updateValue: {
//           gst: gst,
//           pan: pan,
//         },
//         thisCustomer: customerId,
//       };
//       updatePanGst(data, dispatch).then((response) => {
//         if (response.status === "200") {
//           toast.success("Details Updated Successfully");

//           handleClose();
//         } else {
//           toast.error("Something went wrong, Try again!");
//         }
//       });
//     } else {
//       toast.error("Please check PAN and GST");
//     }
//   };

//   const handlePan = (value) => {
//     setPan(value);
//     let panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
//     if (!panRegex.test(value) && value !== "") {
//       setShowPanError(true);
//       setMessageDataPan("Invalid PAN format");
//     } else {
//       setShowPanError(false);
//       setMessageDataPan("");
//     }
//   };

//   const handleGst = (value) => {
//     setGst(value);
//     let gstRegex =
//       /^([0-9]){2}([A-Z]){5}([0-9]){4}([A-Z]){1}([0-9]){1}([A-Z]){1}([0-9]){1}?$/;
//     if (!gstRegex.test(value) && value !== "") {
//       setShowGstError(true);
//       setMessageDataGst("Invalid Gst format");
//     } else {
//       setShowGstError(false);
//       setMessageDataGst("");
//     }
//   };

//   const PaymentDetailsCard = ({ paymentData, t }) => {
//     const details = [
//       {
//         label: t("lead_paid_on"),
//         value: moment(paymentData.PaidOn).format("DD-MM-YYYY"),
//         cols: 4,
//       },

//       {
//         label: t("lead_payment_total_amount"),
//         value: formatIndianCurrency(paymentData.totalAmount),
//         cols: 4,
//       },
//       {
//         label: t("mpr_invoice_amount") + " " + t("common_paid"),
//         value: formatIndianCurrency(paymentData.amountPaid),
//         cols: 4,
//       },

//       {
//         label: t("lead_payment_status"),
//         value: paymentData.paymentStatus,
//         cols: 4,
//       },
//       {
//         label: t("lead_payment_mode"),
//         value: paymentData.paymentMode || "N/A",
//         cols: 4,
//       },

//       {
//         label: t("lead_payment_transaction"),
//         value: paymentData.transactionID || "N/A",
//         cols: 4,
//       },
//     ];

//     return (
//       <Box sx={{ p: 3, bgcolor: "background.paper", borderRadius: 1 }}>
//         <Grid container spacing={3}>
//           <Grid item xs={12}>
//             <Typography variant="h5" gutterBottom>
//               {t("common_payment_details")}
//             </Typography>
//             <Divider sx={{ mb: 3 }} />
//           </Grid>

//           {details.map((detail, index) => (
//             <Grid item xs={12} md={6} lg={detail.cols} key={index}>
//               <Box
//                 sx={{
//                   p: 2,
//                   bgcolor: "grey.50",
//                   borderRadius: 1,
//                   height: "100%",
//                 }}
//               >
//                 <Typography
//                   variant="subtitle2"
//                   color="text.secondary"
//                   gutterBottom
//                   sx={{ fontWeight: 500 }}
//                 >
//                   {detail.label}
//                 </Typography>
//                 <Typography
//                   variant="body1"
//                   sx={{
//                     fontWeight: "medium",
//                     color: "text.primary",
//                     wordBreak: "break-word",
//                   }}
//                 >
//                   {detail.value}
//                 </Typography>
//               </Box>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//     );
//   };

//   const supplierPaymentData = {
//     leadId: props.list.external_id,
//     amount: quotevalue,
//   };

//   return (
//     <>
//       <Box py={4}>
//         {props.paymentData &&
//         Object.keys(props.paymentData).length !== 0 &&
//         props.paymentData.paymentStatus.toLowerCase() ===
//           "paid".toLowerCase() ? (
//           <PaymentDetailsCard paymentData={props.paymentData} t={t} />
//         ) : (
//           <PaymentRequestForm
//             paymentData={supplierPaymentData}
//             onResponse={onResponseFromSupplierPaymentApi}
//           />
//         )}

//         {/* //  <div
//           //   style={{
//           //     marginTop: "2rem",
//           //     marginBottom: "2rem",
//           //     float: "right",
//           //     display: "flex",
//           //     alignItems: "center",
//           //   }}
//           // >
//           //   <Button
//           //     variant="outlined"
//           //     onClick={() => props.close()}
//           //     className={classes.button}
//           //   >
//           //     {t("common_back")}
//           //   </Button>
//           //   {showloader ? (
//           //     <CircularProgress />
//           //   ) : props.paymentData &&
//           //     Object.keys(props.paymentData).length !== 0 &&
//           //     props.paymentData.paymentStatus.toLowerCase() ===
//           //       "paid".toLowerCase() ? (
//           //     <></>
//           //   ) : (
//           //     <Button
//           //       variant="outlined"
//           //       color="primary"
//           //       onClick={handleSubmit}
//           //       className={classes.buttonOutlined}
//           //     >
//           //       {t("common_save")}
//           //     </Button>
//           //   )}
//           // </div> */}
//       </Box>
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Attention</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             {msg} for this customer, please provide needed information.
//           </DialogContentText>
//           <TextField
//             margin="dense"
//             id="pan"
//             label="PAN"
//             type="text"
//             inputProps={{ pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}" }}
//             fullWidth
//             variant="standard"
//             onChange={(e) => handlePan(e.target.value)}
//             value={pan}
//           />
//           {showPanError ? (
//             <Typography color="error">{messageDataPan}</Typography>
//           ) : (
//             <></>
//           )}
//           <TextField
//             margin="dense"
//             id="name"
//             label="GST"
//             type="text"
//             fullWidth
//             // eslint-disable-next-line
//             inputProps={{
//               pattern: "d{2}[A-Z]{5}d{4}[A-Z]{1}[A-Zd]{1}[Z]{1}[A-Zd]{1}",
//             }}
//             variant="standard"
//             onChange={(e) => handleGst(e.target.value)}
//             value={gst}
//             // error = {showGstError}
//           />
//           {showGstError ? (
//             <Typography color="error">{messageDataGst}</Typography>
//           ) : (
//             <></>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button variant="default" onClick={handleClose}>
//             Cancel
//           </Button>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={handlePanGst}
//             disabled={
//               pan.length < 10 ||
//               gst.length < 15 ||
//               messageDataPan !== "" ||
//               messageDataGst !== ""
//             }
//           >
//             Update
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default AddPayment;

//new logic
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Box,
  Grid,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { assignLead } from "../../../LeadState";
import toast from "react-hot-toast";
import PaymentRequestForm from "../../../../internalForms/paymentRequests/supplierCustomer/PaymentRequestForm";
import { formatIndianCurrency } from "../../../../../utils/currencyFormat";

const AddPayment = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedInDatas = useSelector((state) => state.userdata);
  const [showloader, setShowLoader] = useState(false);
  const [supplierPaymentId, setSupplierPaymentId] = useState("");

  // Handler for receiving payment ID from child component
  const onResponseFromSupplierPaymentApi = (response) => {
    if (response && response.paymentId) {
      setSupplierPaymentId(response.paymentId);
      handleSubmit(response.paymentId);
    }
  };

  const handleSubmit = (paymentId) => {
    setShowLoader(true);
    const status =
      loggedInDatas.getData?.type === "tenant" &&
      !loggedInDatas.getData?.roles.includes("user")
        ? "Payment Completed"
        : "Payment Initiated";

    const paymentStatus = status === "Payment Completed" ? "Paid" : "Initiated";

    const data = {
      external_id: props.list.external_id,
      leadData: {
        payment: {
          totalAmount: props.list.transactionAmount?.amount,
          paymentStatus: paymentStatus,
          supplierPaymentId: paymentId,
          // paymentMode: paymethod,
          // transactionID: transId,
          createdOn: Date.now(),
          // PaidOn: paidon,
        },
        supplierPaymentId,
        status: status,
      },
      mpr_id: props.list.Mpr_id,
    };

    assignLead(data, dispatch)
      .then((response) => {
        if (response.status === "200") {
          toast.success("Payment Saved Successfully");
          setTimeout(() => props.close(), 2000);
        } else {
          toast.error(response.message || "Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while saving payment");
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const PaymentDetailsCard = ({ paymentData, t }) => {
    const details = [
      {
        label: t("lead_paid_on"),
        value: moment(paymentData.PaidOn).format("DD-MM-YYYY"),
        cols: 4,
      },
      {
        label: t("lead_payment_total_amount"),
        value: formatIndianCurrency(paymentData.totalAmount),
        cols: 4,
      },
      {
        label: t("lead_payment_status"),
        value: paymentData.paymentStatus,
        cols: 4,
      },
      {
        label: t("lead_payment_mode"),
        value: paymentData.paymentMode || "N/A",
        cols: 4,
      },
      {
        label: t("lead_payment_transaction"),
        value: paymentData.transactionID || "N/A",
        cols: 4,
      },
    ];

    return (
      <Box sx={{ p: 3, bgcolor: "background.paper", borderRadius: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              {t("common_payment_details")}
            </Typography>

            <Divider sx={{ mb: 3 }} />
          </Grid>

          {details.map((detail, index) => (
            <Grid item xs={12} md={6} lg={detail.cols} key={index}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "grey.50",
                  borderRadius: 1,
                  height: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  {detail.label}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "medium",
                    color: "text.primary",
                    wordBreak: "break-word",
                  }}
                >
                  {detail.value}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const supplierPaymentData = {
    leadId: props.list.external_id,
    amount: props.list.transactionAmount?.amount,
  };

  return (
    <Box py={4}>
      {props.paymentData &&
      Object.keys(props.paymentData).length !== 0 &&
      props.paymentData.paymentStatus.toLowerCase() === "paid" ? (
        <PaymentDetailsCard paymentData={props.paymentData} t={t} />
      ) : (
        <PaymentRequestForm
          paymentData={supplierPaymentData}
          onResponse={onResponseFromSupplierPaymentApi}
        />
      )}
    </Box>
  );
};

export default AddPayment;
