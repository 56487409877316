import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  fileUploadSection: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  styledCard: {
    background: theme.palette.background.paper,
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
    borderRadius: "16px",
    overflow: "visible",
    transition: "transform 0.3s ease-in-out",
  },
  uploadZone: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: "12px",
    padding: theme.spacing(3),
    textAlign: "center",
    backgroundColor: theme.palette.primary.main + "0A",
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.main + "1A",
      borderStyle: "solid",
    },
  },
}));
