import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FileBase64 from "react-file-base64";
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  Select,
  Grid,
  MenuItem,
  Paper,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fileupload } from "../../../../material_pickup_request/MprState";
import { assignLead } from "../../../LeadState";
import { NumberFormatCustom } from "../../../../../utils/currencyFormat";
import toast from "react-hot-toast";
import useStyles from "./styles";

const QuotationForm = ({ list, close }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedInData = useSelector((state) => state.userdata);

  const [formData, setFormData] = useState({
    quotRefName: "",
    quotdesc: "",
    quotevalue: "",
    region: "INR",
    genratedDate: new Date(),
  });

  const [fileData, setFileData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = async () => {
    if (!fileData) {
      toast.error("Please select a file first");
      return;
    }

    if (fileData.type !== "application/pdf") {
      toast.error("Please upload PDF File only");
      return;
    }

    setIsUploading(true);
    try {
      const baseData = fileData.base64.split(",")[1];
      const filetype = fileData.type.split("/")[1];

      const tokenData = {
        abbrevation: loggedInData.getData.abbrevation,
        fileType: "quotation",
        folderName: list.external_id,
        expireLimt: 1800,
        extension: filetype,
      };

      const response = await fileupload(baseData, tokenData, fileData.type);
      if (response.status === "200") {
        setUploadedFile(response);
        toast.success("File uploaded successfully");
      }
    } catch (error) {
      toast.error("Failed to upload file");
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const quotationData = {
        external_id: list.external_id,
        leadData: {
          quotation: {
            folderName: `quotation/${list.external_id}`,
            fileName: uploadedFile?.fileName || uploadedFile?.filename,
            quoteStatus: "Pdf Generated",
            totalAmount: {
              region: formData.region,
              amount: formData.quotevalue,
            },
            description: formData.quotdesc,
            referenceName: formData.quotRefName,
            customerComments: "",
            genratedDate: formData.genratedDate,
          },
          status: "Quotation Generated",
        },
        mpr_id: list.Mpr_id,
      };

      const response = await assignLead(quotationData, dispatch);
      if (response.status === "200") {
        toast.success("Quotation Sent Successfully");
        setTimeout(close, 2000);
      }
    } catch (error) {
      toast.error("Failed to submit quotation");
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateForm = () => {
    if (!formData.quotRefName.trim()) {
      toast.error("Please enter reference name");
      return false;
    }
    if (!formData.quotevalue) {
      toast.error("Please enter quotation value");
      return false;
    }
    if (!uploadedFile) {
      toast.error("Please upload quotation file");
      return false;
    }
    return true;
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Upload quotation
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={`${t("quotation")} ${t("common_reference")} ${t("common_name")}`}
            name="quotRefName"
            value={formData.quotRefName}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("common_description")}
            name="quotdesc"
            value={formData.quotdesc}
            onChange={handleInputChange}
            variant="outlined"
            multiline
            rows={3}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <Select
              value={formData.region}
              name="region"
              onChange={handleInputChange}
              displayEmpty
            >
              <MenuItem value="INR">INR</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            label={t("quotation_value")}
            value={formData.quotevalue}
            name="quotevalue"
            onChange={handleInputChange}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.fileUploadSection}>
            <Typography variant="subtitle2" gutterBottom>
              {t("quotation_upload_file")}
            </Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <FileBase64 multiple={false} onDone={setFileData} />
              <Button
                variant="contained"
                onClick={handleFileUpload}
                disabled={isUploading || !fileData}
                startIcon={isUploading && <CircularProgress size={20} />}
              >
                {t("common_upload")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box className={classes.actionButtons}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || !uploadedFile}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t("common_publish")}
        </Button>
      </Box>
    </Paper>
  );
};

export default QuotationForm;
