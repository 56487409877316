import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { useTranslation } from "react-i18next";
import { NumberFormatCustom } from "../../../../../../utils/currencyFormat";

const FinalAmount = ({
  region,
  setRegion,
  quotationValue,
  roundOffTotal,
  totalInWords,
  classes,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      {/* First Row: Currency and Amount */}
      <Grid item lg={3} md={6} xs={1} sm={1} /> {/* Spacer */}
      <Grid container item lg={9} md={6} xs={11} sm={11} spacing={2}>
        <Grid item lg={4} md={4} xs={4} sm={4}>
          {/* <FormControl
            fullWidth
            className={classes.formControl}
            style={{ marginTop: "12px" }}
          >
            <InputLabel id="state_drop">{t("Currency")}</InputLabel>
            <Select
              label={t("Currency")}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              labelId="state_drop"
              id="region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            >
              <MenuItem value={"INR"}>INR</MenuItem>
            </Select>
          </FormControl> */}
        </Grid>
        <Grid item lg={8} md={8} xs={8} sm={8}>
          <TextField
            className={classes.formControl}
            label={t("Total Amount")}
            value={quotationValue}
            name="numberformat"
            id="formatted-numberformat-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Round Off">
                    <IconButton onClick={roundOffTotal} edge="end">
                      <CachedIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              inputComponent: NumberFormatCustom,
            }}
            inputProps={{ style: { fontSize: 20, fontWeight: "700" } }}
          />
        </Grid>
      </Grid>
      {/* Second Row: Amount in Words */}
      <Grid item lg={3} md={6} xs={1} sm={1} /> {/* Spacer */}
      <Grid item lg={9} md={6} xs={11} sm={11}>
        <TextField
          fullWidth
          className={classes.formControl}
          label={t("Total Amount in Words")}
          value={totalInWords}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

FinalAmount.propTypes = {
  region: PropTypes.string.isRequired,
  setRegion: PropTypes.func.isRequired,
  quotationValue: PropTypes.string.isRequired,
  roundOffTotal: PropTypes.func.isRequired,
  totalInWords: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default FinalAmount;
