import { useEffect, useState } from "react";

export const useUserRole = (loggedInData) => {
  const [roles, setRoles] = useState({
    isViewer: false,
    isOnlyUser: false,
    isApprover: false,
    isAuthorizer: false,
    isAdmin: false,
  });

  useEffect(() => {
    if (!loggedInData) return;

    const hasRole = (role) => loggedInData.roles.some((item) => item === role);

    setRoles({
      isViewer: hasRole("user") && hasRole("Viewer"),
      isOnlyUser: hasRole("user") && loggedInData.roles.length === 1,
      isApprover: hasRole("Approver"),
      isAuthorizer: hasRole("Authorizer"),
      isAdmin: hasRole("Admin"),
    });
  }, [loggedInData]);

  return roles;
};
