import React from "react";
import { Box, Typography, IconButton, Grid, Paper } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { viewFiles } from "../../../utils/getFiles";

const EmployeeDocuments = ({ documents }) => {
  // Define the order and labels for document types
  const documentTypes = [
    { key: "aadharcard", label: "Aadhaar Card" },
    { key: "pancard", label: "PAN Card" },
    { key: "bankpassbook", label: "Bank Passbook" },
    { key: "appointmentletter", label: "Employment Letter" },
    { key: "resume", label: "Resume" },
    { key: "educationcertificate", label: "Education Certificate" },
    { key: "experiencecertificate", label: "Experience Certificate" },
    { key: "otherdocuments", label: "Other Documents" },
  ];

  const handleViewFile = (file) => {
    if (file) {
      viewFiles(file);
    }
  };

  return (
    <Box sx={{ py: 2 }}>
      <Grid container spacing={2}>
        {documentTypes.map((docType) => {
          const files = documents?.documents?.[docType.key] || [];

          return (
            files.length > 0 && (
              <Grid item xs={12} key={docType.key} md={6}>
                <Paper
                  elevation={1}
                  sx={{
                    p: 2,
                    mb: 2,
                    backgroundColor:
                      files.length > 0 ? "grey.100" : "transparent",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ mb: 2 }}
                  >
                    {docType.label}
                  </Typography>
                  {files.map((file, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        bgcolor: "white",
                        p: 1,
                        borderRadius: 1,
                        mb: 1,
                      }}
                    >
                      <Typography variant="body2" sx={{ flex: 1, mr: 2 }}>
                        {file.fileName}
                      </Typography>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => handleViewFile(file)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Paper>
              </Grid>
            )
          );
        })}
      </Grid>
    </Box>
  );
};

export default EmployeeDocuments;
