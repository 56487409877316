import React, { useEffect, useState } from "react";
import { 
  Box, 
  Stack, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails 
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import LeaveRequestsTable from "./components/LeaveRequestsTable";
import EmployeesLeavesSummaryTable from "./components/EmployeesLeavesSummaryTable";
import { useSelector } from "react-redux";
import { LeaveDash } from "./components/LeaveDash";
import toast from "react-hot-toast";
import { getAllEmployessLeavesInfo } from "./apis";
import { getCurrentYear } from "../../utils/helpers";

const Leaves = () => {
  const breadcrumb = [
    { label: "Home", link: "/#/app/dashboard" },
    { label: "Employee portal", link: "/#/app/employeePortal" },
    { label: "Leaves", link: "" },
  ];

  const [leaveData, setLeaveData] = useState([]);
  const [expanded, setExpanded] = useState(false); 

  const loggedInUser = useSelector((state) => state.userdata.getData);

  useEffect(() => {
    async function getLoggedInUserLeaveInfo() {
      const currentYear = getCurrentYear();
      try {
        const formData = { year: currentYear };
        const response = await getAllEmployessLeavesInfo(formData);

        if (response.status !== "200") {
          toast.error("An error occurred. Please try again.");
          return;
        }

        // Filter leave requests for the current user
        const userLeaveRequests = response.data.filter(
          (leave) => leave?.userId === loggedInUser?._id
        );
        setLeaveData(userLeaveRequests[0]?.leaveData);
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      }
    }

    getLoggedInUserLeaveInfo();
  }, [loggedInUser]);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ marginTop: "100px" }}>
      <Box sx={{ mb: 3 }}>
        <BreadCrumbs data={breadcrumb} />
      </Box>
      <Stack spacing={3}>
        <Typography variant="h6" gutterBottom sx={{ mb: 2, fontWeight: 600 }}>
          Leave Dashboard
        </Typography>

        <Accordion 
          expanded={expanded} 
          onChange={handleAccordionChange}
          sx={{ 
            boxShadow: 'none', 
            border: '1px solid rgba(0, 0, 0, 0.12)',
            '&:before': {
              display: 'none',
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="leave-dashboard-content"
            id="leave-dashboard-header"
          >
            <Typography variant="subtitle1" fontWeight={600}>
              Available leaves
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LeaveDash
              user={loggedInUser}
              showHrManual={true}
              leaveData={leaveData}
            />
          </AccordionDetails>
        </Accordion>

        <LeaveRequestsTable leaveData = {leaveData} />
        {!loggedInUser?.roles?.includes("user") && (
          <EmployeesLeavesSummaryTable />
        )}
      </Stack>
    </Box>
  );
};

export default Leaves;