import React, { useState } from "react";
import {
  Grid,
  Button,
  Paper,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import classnames from "classnames";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import { addMPUser } from "./MarketplaceState";
import NotificationContainer from "../notifications/NotificationContainer";
const AddMPUser = () => {
  const methods = useForm({
    defaultValues: {
      name: "",
      email: "",
      gst: "",
      pan: "",
      mobile: "",
    },
  });

  const classes = useStyles();
  const history = useHistory();

  const [message, setMessage] = useState("");
  const [notify, setNotify] = useState(false);
  const [type, setType] = useState("");

  const [showLoader, setShowLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const { t, i18n } = useTranslation();

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: "Marketplace", link: "/#/app/marketplace" },
    { label: t("Add Buyer"), link: "" },
  ];

  const handleFormSubmit = async (formData) => {
    const userObject = {
      name: {
        fname: formData.name,
      },
      mobile: formData.mobile,
      email: formData.email,
      gst: formData.gst,
      pan: formData.pan,
    };
    // console.log("User Input Data:", userObject);

    setShowLoader(true);
    try {
      const response = await addMPUser(userObject);
      // console.log("API Response:", response);
      setShowLoader(false);
      if (response.status === 200) {
        setMessage("New MP User added successfully");
        setType("success");
        setNotify(true);
        setTimeout(() => {
          setNotify(false);
          history.push("/app/mpUsers");
        }, 2000);
      } else {
        response.ErrorData
          ? setMessage(response?.ErrorData[0]?.message)
          : setMessage(response.message);
        setType("error");
        setIsError(true);
        setTimeout(() => setIsError(false), 2000);
      }
    } catch (error) {
      // console.error("API Error:", error.ErrorData[0]?.message);
      error.ErrorData
        ? setMessage(error?.ErrorData[0]?.message)
        : setMessage(error.message);
      setMessage(error.message);
      setType("error");
      setIsError(true);
      setTimeout(() => setIsError(false), 2000);
      setShowLoader(false);
    }
  };

  return (
    <div className={classes.content}>
      <BreadCrumbs data={breadcrumb} />
      {notify ? (
        <NotificationContainer message={message} notificationtype={type} />
      ) : (
        <></>
      )}
      {isError ? (
        <NotificationContainer message={message} notificationtype={type} />
      ) : (
        <></>
      )}
      <Grid container spacing={3}>
        <Grid item lg={3} md={1} xs={0} sm={0}></Grid>
        <Grid item lg={6} md={10} xs={12} sm={12}>
          <Paper
            className={classes.paperButton}
            classes={{
              root: classnames(classes.widgetRoot, {
                [classes.noWidgetShadow]: null,
              }),
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      {...methods.register("name", { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      type="email"
                      fullWidth
                      margin="normal"
                      {...methods.register("email")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      inputProps={{ maxLength: 10 }}
                      {...methods.register("mobile", { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="GST"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      inputProps={{ maxLength: 15 }}
                      {...methods.register("gst", { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="PAN"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      inputProps={{ maxLength: 10 }}
                      {...methods.register("pan", { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {showLoader ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          onClick={() => history.push("/app/mpUsers")}
                          className={classes.buttonOutlined}
                        >
                          {t("Back")}
                        </Button>
                        <Button
                          type="submit"
                          variant="outlined"
                          color="primary"
                          style={{ marginLeft: "10px" }}
                          className={classes.buttonOutlined}
                        >
                          {t("Submit")}
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Paper>
        </Grid>
        <Grid item lg={3} md={1} xs={0} sm={0}></Grid>
      </Grid>
    </div>
  );
};

export default AddMPUser;
