import React from "react";
import { Paper, Box, Typography, IconButton } from "@mui/material";
import CakeIcon from "@mui/icons-material/Cake";
import WorkIcon from "@mui/icons-material/Work";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "../styles";
import { deleteEmployeeAnniversayNotifications } from "../apis";

const CelebrationAlert = ({ type, message, subMessage, onClose }) => {
  const classes = useStyles();

  // More subtle color palette
  const colors =
    type === "birthday"
      ? {
          bg: "linear-gradient(135deg, rgba(255, 236, 239, 0.9) 0%, rgba(255, 245, 247, 0.9) 100%)",
          icon: "#E91E63",
          text: "#424242",
          border: "#FFD6E0",
        }
      : {
          bg: "linear-gradient(135deg, rgba(232, 245, 253, 0.9) 0%, rgba(237, 247, 255, 0.9) 100%)",
          icon: "#1976D2",
          text: "#424242",
          border: "#BBDEFB",
        };

  return (
    <Paper
      elevation={0}
      className={classes.celebrationCard}
      sx={{
        background: colors.bg,
        borderRadius: "8px",
        position: "relative",
        overflow: "hidden",
        border: `1px solid ${colors.border}`,
      }}
    >
      <IconButton
        size="small"
        className={classes.closeButton}
        onClick={onClose}
        aria-label="close"
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      <Box className={classes.messageContainer}>
        <Box
          className={classes.iconContainer}
          sx={{
            backgroundColor: `${colors.icon}15`,
            border: `1px solid ${colors.icon}30`,
          }}
        >
          {type === "birthday" ? (
            <CakeIcon sx={{ color: colors.icon }} />
          ) : (
            <WorkIcon sx={{ color: colors.icon }} />
          )}
        </Box>
        <Box sx={{ flex: 1, pr: 4 }}>
          <Typography
            variant="subtitle1"
            sx={{
              color: colors.text,
              fontWeight: 600,
              marginBottom: 0.5,
            }}
          >
            {message}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: colors.text,
              opacity: 0.8,
            }}
          >
            {subMessage}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const CelebrationAlerts = ({ celebrations, setCelebrations }) => {

  const classes = useStyles();

  const handleClose = async (celebration) => {
    try {
      const response = await deleteEmployeeAnniversayNotifications({
        _id: celebration?._id,
      });

      if (response.status === "200") {
        // Remove the specific celebration from the state
        setCelebrations((prevCelebrations) =>
          prevCelebrations.filter((item) => item?._id !== celebration?._id)
        );
      }
    } catch (error) {
      console.error("Error deleting celebration:", error);
    }
  };

  return (
    <Box className={classes.alertContainer}>
      {celebrations.map((celebration) => (
        <CelebrationAlert
          key={celebration._id}
          type={celebration.type}
          message={celebration.message}
          subMessage={celebration.subMessage}
          onClose={() => handleClose(celebration)}
        />
      ))}
    </Box>
  );
};

export default CelebrationAlerts;
