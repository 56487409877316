import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import AsyncSelect from "react-select/async";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import {
  getFile,
  searchTag,
  updateLot,
  getTagById,
  fileUpload,
} from "../MarketplaceState";
import CancelIcon from "@mui/icons-material/Cancel";
import FileBase64 from "react-file-base64";
import moment from "moment";
import { NumberFormatCustom } from "../../../utils/currencyFormat";

import toast from "react-hot-toast";

const EditLot = (props) => {
  const { t } = useTranslation();
  const lotData = useSelector((state) => state);
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [showImageLoader, setShowImageLoader] = useState(false);
  const [inputValue, setValue] = useState();
  const [status, setStatus] = useState(lotData.selectedLot.status);
  const [selectedValue, setSelectedValue] = useState([]);
  const [title, setTitle] = useState(
    Object.keys(lotData.selectedLot).length > 0 ? lotData.selectedLot.title : ""
  );
  const [basePrice, setBasePrice] = useState(
    Object.keys(lotData.selectedLot).length > 0
      ? lotData.selectedLot.basePrice
      : ""
  );

  // Fields for single item lot type
  const [quantity, setQuantity] = useState(lotData.selectedLot.quantity || 0);
  const [quantityUnit, setQuantityUnit] = useState(
    lotData.selectedLot.quantityUnit || ""
  );
  const [ratePerUnit, setRatePerUnit] = useState(
    lotData.selectedLot.ratePerUnit || 0
  );
  const [lotType, setLotType] = useState(
    lotData.selectedLot.lotType || "SAME_ITEMS"
  );

  const [location, setLocation] = useState(
    lotData.selectedLot.location || {
      address: "",
      city: "",
      state: "",
      pinCode: "",
    }
  );

  const [lotAvailability, setLotAvailability] = useState(
    lotData.selectedLot.lotAvailability
  );

  const [images, setImages] = useState(
    Object.keys(lotData.selectedLot).length > 0
      ? lotData.selectedLot.images
      : []
  );

  const [description, setDescription] = useState(
    Object.keys(lotData.selectedLot).length > 0
      ? lotData.selectedLot.description
      : ""
  );
  const [detailedDescription, setDetailedDescription] = useState(
    Object.keys(lotData.selectedLot).length > 0
      ? lotData.selectedLot.detailedDescription
      : ""
  );
  const [lastDate, setLastDate] = useState(
    Object.keys(lotData.selectedLot).length > 0
      ? lotData.selectedLot.lastDate
      : ""
  );
  const [imageAlert, setImageAlert] = useState(false);
  var newArray = [...images];
  var newTagArray = [];
  const [titleError, setTitleError] = useState("");
  const [dateError, setDateError] = useState("");
  const [basicPriceError, setBasicPriceError] = useState("");

  const [locationError, setLocationError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [detailedDescriptionError, setdetailedDescriptionError] = useState("");
  const [isError, setIsError] = useState(false);

  const breadcrumb = [
    { label: t("common_lot"), link: "/#/app/marketplace" },
    { label: t("edit_lot"), link: "" },
  ];
  const classes = useStyles();

  const handleImageChange = async (e) => {
    await e.map(async (item, key) => {
      if (
        item.type === "image/png" ||
        item.type === "image/jpeg" ||
        item.type === "image/jpg"
      ) {
        setShowImageLoader(true);
        setImageAlert(false);
        var fileName = item.name;
        var fileType = fileName.split(".").pop();
        var baseData = item.base64.split(",");
        let getTokenData = {
          abbrevation: Math.floor(Math.random() * 100) + "Mrkplc",
          fileType: fileType,
          folderName: "mrkplc/",
          expireLimt: 1800,
          extension: fileName.split(".").pop(),
        };
        getAwsResponse(baseData, getTokenData, e);
      } else {
        setImageAlert(true);
      }
    });
  };

  const getAwsResponse = async (baseData, getTokenData, e) => {
    await fileUpload(
      baseData.length > 0 ? baseData[1] : "",
      getTokenData,
      e.type
    ).then(async (response) => {
      if (response.status === "200") {
        const objwithRef = {
          fileName: response.fileName || response.filename,
          ref: response.reference,
          imageId: response.fileName || response.filename,
          folderName:
            response.folderName || response.folder || response.FolderName,
        };
        images.push(objwithRef);
        newArray = images;
        setImages([...newArray]);
        if (newArray.length === images.length) {
        }
        setShowImageLoader(false);
      } else {
        setShowImageLoader(false);
      }
    });
  };

  const getAllTag = (e, callback) => {
    if (e.length > 0) {
      searchTag(e).then((response) => {
        const tempArray = [];
        if (response.data.tags) {
          response.data.tags.forEach((element) => {
            tempArray.push({
              label: `${element.item}`,
              value: element._id,
            });
          });
        } else {
          console.error("error");
        }
        callback(tempArray);
      });
    }
  };

  const handleLotTypeChange = (event) => {
    setLotType(event.target.value);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleQuantityUnitChange = (e) => {
    setQuantityUnit(e.target.value);
  };

  const handleRatePerUnitChange = (e) => {
    setRatePerUnit(e.target.value);
  };

  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleAvailabilityChange = (event) => {
    setLotAvailability(event.target.value);
  };

  const handleChange = (value) => {
    setSelectedValue(value.value);
    value.map((item) => {
      const newwObj = { label: item.label, value: item.value };
      newTagArray.push(newwObj);
      setSelectedValue(newTagArray);
    });
  };

  const deleteFile = (data, index) => {
    let array = [...images];
    const newArray = array.filter((m) => m.fileName !== data.fileName);
    setImages(newArray);
  };

  const getTagNameById = async () => {
    let newTagArray = [];
    await lotData?.selectedLot?.tagId?.map(async (item) => {
      const newTagNameList = await getTagById(item);
      const newwObj = {
        label: newTagNameList.data.tags[0].item,
        value: newTagNameList.data.tags[0]._id,
      };
      newTagArray.push(newwObj);
      if (lotData.selectedLot.tagId.length === newTagArray.length) {
        setSelectedValue(newTagArray);
      }
    });
  };

  const getUploadedImages = async () => {
    const newarr = [];
    images.map(async (item) => {
      const GetFile = await getFile(item.fileName, item.folderName);
      const objwithRef = {
        fileName: GetFile.fileName || GetFile.filename,
        ref: GetFile.reference,
        imageId: GetFile.fileName || GetFile.filename,
        folderName: GetFile.folderName || GetFile.folder || GetFile.FolderName,
      };
      newarr.push(objwithRef);
      if (images.length === newarr.length) {
        setImages(newarr);
      }
    });
  };

  const handleSubmit = () => {
    if (title === "") {
      toast.error("Please enter the title");
    } else if (basePrice === "") {
      toast.error("Please enter the base price");
    } else if (location === "") {
      toast.error("Please enter the location");
    } else if (lastDate === "") {
      toast.error("Please enter the close date");
    } else if (description === "") {
      toast.error("Please enter the description");
    } else if (detailedDescription === "") {
      toast.error("Please enter the Detailed Description");
    } else if (selectedValue.length === 0) {
      toast.error("Please selct the tags");
    } else if (images.length === 0) {
      toast.error("Please Upload the images");
    } else {
      setIsError(false);
      const data = getFormDetails();
      setShowLoader(true);
      updateLot(data, lotData.selectedLot._id).then((response) => {
        setShowLoader(false);
        if (response.status === 200) {
          toast.success("Successfully updated the Lot details!");
          setShowLoader(false);
          setTimeout(() => props.history.push("/app/marketplace"), 2000);
        } else {
          let msg = [];
          if (response.ErrorData) {
            response.ErrorData.details.map((item) => {
              msg.push(item.message);
            });
            toast.error(`${msg.join()}`);
          } else {
            toast.error(response.message);
          }
          setShowLoader(false);
        }
      });
    }
  };

  const getFormDetails = () => {
    let tagsArray = [];
    selectedValue.map((item) => {
      tagsArray.push(item.value);
    });
    const isChangedDate = moment(lastDate).isAfter(moment());
    if (isChangedDate) {
      var updateFormData = {
        data: {
          title: title,
          quantity,
          quantityUnit,
          ratePerUnit,
          lotType,
          images: images,
          location: location,
          tenantId: localStorage.getItem("customerid"),
          tagId: tagsArray,
          lastDate: lastDate,
          basePrice: basePrice,
          status: status,
          description: description,
          detailedDescription: detailedDescription,
        },
      };

      return updateFormData;
    } else {
       updateFormData = {
        data: {
          title: title,
          quantity,
          quantityUnit,
          ratePerUnit,
          lotType,
          images: images,
          location: location,
          tenantId: localStorage.getItem("customerid"),
          tagId: tagsArray,
          lastDate: lastDate,
          basePrice: basePrice,
          status: "closed",
          description: description,
          detailedDescription: detailedDescription,
        },
      };

      return updateFormData;
    }
  };

  useEffect(() => {
    getUploadedImages();
    getTagNameById();
    if (Object.keys(lotData.selectedLot).length === 0) {
      history.push("/app/marketplace");
    }
  }, []);

  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() + 7);
  const formattedDefaultDate = defaultDate.toISOString();

  var backendate = new Date(
    lotData?.selectedLot?.lastDate
      ? lotData?.selectedLot?.lastDate
      : formattedDefaultDate
  )
    .toISOString()
    .slice(0, 10);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <Grid container spacing={1}>
          <Grid item lg={2} md={1} xs={0} sm={0}></Grid>
          <Grid item lg={8} md={10} xs={12} sm={12}>
            <Paper className={classes.paper}>
              <div className={classes.form1}>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} xs={12} sm={12}>
                    <div className={classes.formField}>
                      <form autoComplete="off">
                        <div
                          className={classes.form1}
                          style={{ marginTop: "-10px" }}
                        >
                          <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xs={12} sm={12}>
                              {" "}
                              <Typography variant="h3">Edit Lot</Typography>
                            </Grid>
                            <Grid item lg={12} md={12} xs={12} sm={12}>
                              <Box sx={{ marginBottom: "1.5rem" }}>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                  gutterBottom
                                >
                                  Type
                                </Typography>
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    row
                                    aria-label="lot-type"
                                    name="lot-type"
                                    value={lotType}
                                    onChange={handleLotTypeChange}
                                  >
                                    <FormControlLabel
                                      value="SAME_ITEMS"
                                      control={<Radio />}
                                      label="Single item"
                                    />
                                    <FormControlLabel
                                      value="DIFFERENT_ITEMS"
                                      control={<Radio />}
                                      label="Lot"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Box>
                            </Grid>

                            {/* Show these fields only for SAME_ITEMS lot type */}
                            {lotType === "SAME_ITEMS" && (
                              <>
                                <Grid item xs={12} md={4}>
                                  <Box>
                                    <Typography
                                      variant="subtitle1"
                                      fontWeight="bold"
                                      gutterBottom
                                    >
                                      Quantity
                                    </Typography>
                                    <TextField
                                      id="quantity"
                                      placeholder="Enter quantity"
                                      fullWidth
                                      name="quantity"
                                      value={quantity}
                                      onChange={handleQuantityChange}
                                      variant="outlined"
                                      margin="dense"
                                      type="number"
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Box>
                                    <Typography
                                      variant="subtitle1"
                                      fontWeight="bold"
                                      gutterBottom
                                    >
                                      Unit
                                    </Typography>
                                    <TextField
                                      id="quantityUnit"
                                      placeholder="e.g. kg, pcs"
                                      fullWidth
                                      name="quantityUnit"
                                      value={quantityUnit}
                                      onChange={handleQuantityUnitChange}
                                      variant="outlined"
                                      margin="dense"
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Box>
                                    <Typography
                                      variant="subtitle1"
                                      fontWeight="bold"
                                      gutterBottom
                                    >
                                      Rate Per Unit
                                    </Typography>
                                    <TextField
                                      id="ratePerUnit"
                                      placeholder="Rate per unit"
                                      fullWidth
                                      name="ratePerUnit"
                                      value={ratePerUnit}
                                      onChange={handleRatePerUnitChange}
                                      variant="outlined"
                                      margin="dense"
                                      // type="number"
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </>
                            )}
                            <Grid item lg={12} md={12} xs={12} sm={12}>
                              <div>
                                <TextField
                                  id="title"
                                  label={t("lot_title")}
                                  fullWidth
                                  name="title"
                                  value={title}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setTitle(value);
                                    if (value.length <= 1) {
                                      setTitleError(
                                        "Please enter atleast 2 characters"
                                      );
                                    } else {
                                      setTitleError("");
                                      setTitle(value);
                                    }
                                  }}
                                  error={title ? titleError : ""}
                                  helperText={title ? titleError : ""}
                                  variant="outlined"
                                  margin="dense"
                                />
                              </div>
                              <div>
                                <TextField
                                  id="basic_price"
                                  label={t("lot_basic_price")}
                                  fullWidth
                                  name="basicPrice"
                                  value={basePrice}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    setBasePrice(value);
                                    if (value === "") {
                                      setBasicPriceError("Field is required");
                                    } else {
                                      setBasicPriceError("");
                                      setBasePrice(value);
                                    }
                                  }}
                                  error={basePrice ? basicPriceError : ""}
                                  helperText={basePrice ? basicPriceError : ""}
                                  variant="outlined"
                                  margin="dense"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                />
                              </div>
                              <div>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6}>
                                    <Box sx={{ marginBottom: "1rem" }}>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        gutterBottom
                                      >
                                        Location of material
                                      </Typography>
                                      <TextField
                                        id="address-street"
                                        placeholder="Street Address"
                                        fullWidth
                                        name="address"
                                        value={location.address || ""}
                                        onChange={(e) => {
                                          const updatedLocation = {
                                            ...location,
                                            address: e.target.value,
                                          };
                                          setLocation(updatedLocation);
                                        }}
                                        error={!!locationError}
                                        helperText={locationError}
                                        variant="outlined"
                                        margin="dense"
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Box sx={{ marginBottom: "1rem" }}>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        gutterBottom
                                      >
                                        City
                                      </Typography>
                                      <TextField
                                        id="address-city"
                                        placeholder="City"
                                        fullWidth
                                        name="city"
                                        value={location.city || ""}
                                        onChange={(e) => {
                                          const updatedLocation = {
                                            ...location,
                                            city: e.target.value,
                                          };
                                          setLocation(updatedLocation);
                                        }}
                                        variant="outlined"
                                        margin="dense"
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Box sx={{ marginBottom: "1rem" }}>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        gutterBottom
                                      >
                                        State
                                      </Typography>
                                      <TextField
                                        id="address-state"
                                        placeholder="State"
                                        fullWidth
                                        name="state"
                                        value={location.state || ""}
                                        onChange={(e) => {
                                          const updatedLocation = {
                                            ...location,
                                            state: e.target.value,
                                          };
                                          setLocation(updatedLocation);
                                        }}
                                        variant="outlined"
                                        margin="dense"
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Box sx={{ marginBottom: "1rem" }}>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        gutterBottom
                                      >
                                        Pin Code
                                      </Typography>
                                      <TextField
                                        id="address-pincode"
                                        placeholder="Pin Code"
                                        fullWidth
                                        name="pinCode"
                                        value={location.pinCode || ""}
                                        onChange={(e) => {
                                          const updatedLocation = {
                                            ...location,
                                            pinCode: e.target.value,
                                          };
                                          setLocation(updatedLocation);
                                        }}
                                        variant="outlined"
                                        margin="dense"
                                      />
                                    </Box>
                                  </Grid>

                                  <Grid item xs={12} md={6}>
                                    <Box sx={{ marginBottom: "1rem" }}>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        gutterBottom
                                      >
                                        Status
                                      </Typography>
                                      <FormControl
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                      >
                                        <Select
                                          labelId="status-select-label"
                                          id="status-select"
                                          value={status}
                                          onChange={handleStatusChange}
                                          // label="Status"
                                        >
                                          <MenuItem value="open">
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              Open
                                            </Box>
                                          </MenuItem>
                                          <MenuItem value="closed">
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              Closed
                                            </Box>
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Box sx={{ marginBottom: "1.5rem" }}>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        gutterBottom
                                      >
                                        Availability
                                      </Typography>
                                      <FormControl
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                      >
                                        <Select
                                          labelId="availability-select-label"
                                          id="availability-select"
                                          value={lotAvailability}
                                          onChange={handleAvailabilityChange}
                                          // label="Availability"
                                        >
                                          <MenuItem value="upcoming">
                                            Upcoming
                                          </MenuItem>
                                          <MenuItem value="current">
                                            Current
                                          </MenuItem>
                                          <MenuItem value="past">Past</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </div>
                              <div>
                                <TextField
                                  name="date"
                                  id="date"
                                  fullWidth
                                  label={t("close_date")}
                                  InputLabelProps={{ shrink: true }}
                                  type="date"
                                  defaultValue={backendate}
                                  onChange={(e) => {
                                    const selected = e.target.value;
                                    const isAfter =
                                      moment(selected).isAfter(moment());
                                    if (isAfter) {
                                      setLastDate(moment(selected));
                                      setDateError("");
                                    } else {
                                      setLastDate(selected);
                                      setDateError(
                                        "Please enter the future date"
                                      );
                                    }
                                  }}
                                  error={lastDate ? dateError : ""}
                                  helperText={lastDate ? dateError : ""}
                                  variant="outlined"
                                  margin="dense"
                                  inputProps={{ min: getCurrentDate() }}
                                />
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <TextField
                                  fullWidth
                                  multiline
                                  variant="outlined"
                                  id="detaileDescription"
                                  label={t("lot_detailed_description")}
                                  name="detaileDescription"
                                  value={detailedDescription}
                                  minRows={5}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setDetailedDescription(value);
                                    if (value.length <= 1) {
                                      setdetailedDescriptionError(
                                        "Please enter atleast 2 characters"
                                      );
                                    } else {
                                      setdetailedDescriptionError("");
                                      setDetailedDescription(value);
                                    }
                                  }}
                                  error={
                                    detailedDescription
                                      ? detailedDescriptionError
                                      : ""
                                  }
                                  helperText={
                                    detailedDescription
                                      ? detailedDescriptionError
                                      : ""
                                  }
                                />
                              </div>

                              <div style={{ marginTop: "1rem" }}>
                                <AsyncSelect
                                  id="tags"
                                  placeholder={t("lot_search_tag")}
                                  isMulti
                                  name="tags"
                                  value={selectedValue}
                                  noOptionsMessage={() => "No tag found"}
                                  loadOptions={getAllTag}
                                  onInputChange={(e) => handleInputChange(e)}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    width: "width: calc(100% - 2*15px)",
                                  }}
                                >
                                  <span>{t("common_upload_details")}</span>
                                  <Grid container spacing={2}>
                                    <Grid item lg={12} md={8} sm={12} xs={12}>
                                      <div className={classes.fileTypeInput}>
                                        <FileBase64
                                          className={classes.input}
                                          multiple={true}
                                          onDone={(e) => handleImageChange(e)}
                                        />
                                      </div>
                                      {imageAlert ? (
                                        <span className={classes.spanAlert}>
                                          Please upload .png,.jpg,.jpeg image
                                          formats
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                      <label htmlFor="lot-file">
                                        {showImageLoader ? (
                                          <CircularProgress />
                                        ) : (
                                          <></>
                                        )}
                                      </label>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        {
                          <div className="result">
                            {images.length > 0 &&
                              images.map((data, index) => {
                                return (
                                  <div className="img-wrapp" key={index}>
                                    <img
                                      src={data.ref}
                                      alt=""
                                      className="image-wrapperr"
                                    />
                                    <CancelIcon
                                      className="closee"
                                      onClick={() => deleteFile(data, index)}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        }

                        <div className={classes.editLotDiv}>
                          {
                            <Button
                              href="/#/app/marketplace"
                              variant="outlined"
                              className={classes.button}
                            >
                              {t("common_back")}
                            </Button>
                          }

                          {showLoader ? (
                            <CircularProgress />
                          ) : lotData.selectedLot.progressStatus ===
                            "finalized" ? (
                            ""
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              onClick={handleSubmit}
                              disabled={showLoader}
                            >
                              {t("edit_lot")}
                            </Button>
                          )}
                        </div>
                      </form>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
          <Grid item lg={2} md={1} xs={0} sm={0}></Grid>
        </Grid>
      </div>
    </>
  );
};
export default EditLot;
