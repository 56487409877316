import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
  Button,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LocalAtm, Assignment, MonetizationOn } from "@mui/icons-material";
import toast from "react-hot-toast";

import { assignLead } from "../../LeadState";
import {
  NumberFormatCustom,
  formatIndianCurrency,
} from "../../../../utils/currencyFormat";

const InfoCard = ({ title, value, icon }) => (
  <Box sx={{ mb: 3 }}>
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      {icon}
      <Typography variant="subtitle2" color="text.secondary" sx={{ ml: 1 }}>
        {title}
      </Typography>
    </Box>
    <Typography variant="body1" sx={{ ml: 4, fontWeight: 500 }}>
      {value || "N/A"}
    </Typography>
  </Box>
);

const CloseLead = ({ list, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const [boughtValue, setBoughtValue] = useState(
    list.transactionAmount?.amount || ""
  );
  const [soldValue, setSoldValue] = useState(
    list.transactionValue?.amount || ""
  );
  const [region, setRegion] = useState("INR");
  const [material, setMaterial] = useState(list.materialHandling || "");
  const [loading, setLoading] = useState(false);

  const handleCloseLead = async () => {
    setLoading(true);
    try {
      const response = await assignLead(
        {
          external_id: list.external_id,
          leadData: {
            status: "Closed",
            reason: "Won",
            materialHandling: material,
            transactionValue: {
              region: region,
              amount: soldValue,
            },
          },
          mpr_id: list.Mpr_id,
        },
        dispatch
      );

      if (response.status === "200") {
        toast.success("Lead Closed Successfully");
        close();
      } else {
        toast.error("Something went wrong! Please try again!");
      }
    } catch (error) {
      toast.error("An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const formStyles = {
    select: {
      mb: 3,
      "& .MuiInputBase-root": {
        borderRadius: 1,
      },
    },
    textField: {
      mb: 3,
      "& .MuiInputBase-root": {
        borderRadius: 1,
      },
    },
  };

  if (list.status === "Closed") {
    return (
      <Card sx={{ mt: -3 }}>
        <CardContent>
          <Typography variant="h6" color="primary" gutterBottom>
            {t("lead_close_lead")}
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <InfoCard
                title={t("lead_bought_amount")}
                value={formatIndianCurrency(list.transactionAmount.amount)}
                icon={<MonetizationOn color="primary" />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InfoCard
                title={t("lead_sold_amount")}
                value={formatIndianCurrency(list.transactionValue.amount)}
                icon={<LocalAtm color="primary" />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InfoCard
                title={t("lead_material_handling")}
                value={list.materialHandling}
                icon={<Assignment color="primary" />}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mt: -3 }}>
      <CardContent>
        <Typography variant="h6" color="primary" gutterBottom>
          {t("lead_close_lead")}
        </Typography>
        <Divider sx={{ mb: 4 }} />

        <Box sx={{ maxWidth: 600, mx: "auto" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined" sx={formStyles.select}>
                <InputLabel>{t("mpr_invoice_region")}</InputLabel>
                <Select
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  label={t("mpr_invoice_region")}
                >
                  <MenuItem value="INR">INR</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                label={t("lead_bought_it_for")}
                value={boughtValue}
                fullWidth
                variant="outlined"
                onChange={(e) => setBoughtValue(e.target.value)}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  readOnly: true,
                }}
                sx={formStyles.textField}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" sx={formStyles.select}>
                <InputLabel>{t("lead_material_handling")}</InputLabel>
                <Select
                  value={material}
                  onChange={(e) => setMaterial(e.target.value)}
                  label={t("lead_material_handling")}
                >
                  <MenuItem value="To Own Plant">To Own Plant</MenuItem>
                  <MenuItem value="Sold to Buyer">Sold to Buyer</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined" sx={formStyles.select}>
                <InputLabel>{t("mpr_invoice_region")}</InputLabel>
                <Select
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  label={t("mpr_invoice_region")}
                >
                  <MenuItem value="INR">INR</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                label={t("lead_sold_it_for")}
                value={soldValue}
                fullWidth
                variant="outlined"
                onChange={(e) => setSoldValue(e.target.value)}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                sx={formStyles.textField}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseLead}
              disabled={
                loading ||
                soldValue.trim() === "" ||
                material.trim() === "" ||
                list.transactionValue?.amount
              }
              sx={{
                minWidth: 150,
                height: 45,
                borderRadius: 2,
                textTransform: "none",
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("lead_close_lead")
              )}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CloseLead;
