import { apiUrl, apiMPUrl } from "../../data/config";
import { Buffer } from "buffer";

// export function getTagList(searchData){
//     let url = apiMPUrl+'/v1/market/tag/get/'+`${searchData}`
//     return fetch(url,{
//         method: 'GET',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type':'application/json',
//         }
//     }).then( function(response) {
//         if(response.ok){
//             return response.json().then((data) => {
//                 dispatch({
//                     type: "SET_TENANT_CUSTOMER_LIST",
//                     payload: data
//                 })
//                 return data
//             }
//             )
//         }else{
//             return response
//         }
//     })
// }

export function addMPUser(data) {
  const url = apiMPUrl + "/v1/market/user/create";
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify({ data: data }),
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("API request failed");
    }
  });
}

export function getMPUsers() {
  const url = apiMPUrl + "/v1/market/users";
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("API request failed");
    }
  });
}
export async function updateMPUser(userId, updatedData) {
  const url = apiMPUrl + `/v1/market/user/updatemany/${userId}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify({ data: updatedData, _id: userId }),
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("API request failed");
    }
  });
}

export async function deleteMPUser(userId) {
  const url = `${apiMPUrl}/v1/market/user/delete/${userId}`;
  const requestOptions = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("API request failed");
    }
  });
}

export async function getMPUser(userId) {
  const url = `${apiMPUrl}/v1/market/user/${userId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("API request failed");
    }
  });
}

export function addTag(data) {
  let url = apiMPUrl + "/v1/market/tag/create/";
  let basicData = {
    data: {
      item: data.tagName,
    },
  };
  let bodyData = { ...basicData };
  const request = fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(bodyData),
  })
    .then(function (response) {
      if (response.ok) {
        return response.json().then((data) => {
          return data;
        });
      } else {
        return response.json().then((errorData) => {
          return errorData.message || "Network Error";
        });
      }
    })
    .catch(function (error) {
      return "Network Error";
    });

  return request;
}

export function deleteTag(id) {
  let url = apiMPUrl + "/v1/market/tag/delete/" + `${id}`;
  return fetch(url, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  }).then((response) => {
    return response.json().then((data) => {
      return data;
    });
  });
}

export function getAllUnclassifiedLead(dispatch) {
  let url = apiUrl + "/v1/getAllUnclassifiedLead";
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return {
        status: "500",
        message: "Something went wrong!",
      };
    }
  });
}

export function getAllTags() {
  let url = apiMPUrl + "/v1/market/tag/get/all";
  const request = fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
  return request;
}

export function getlotBids(lotId) {
  let url = apiMPUrl + `/v1/market/bid/get/highest/${lotId}`;
  const request = fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
  return request;
}

export function finalizeBids(data, paymentDetails) {
  let url = apiMPUrl + "/v1/market/bid/sendPaymentLink";
  let basicData = {
    data: {
      paymentDetails,
      finalBid: data,
    },
  };
  let bodyData = { ...basicData };
  const request = fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },

    body: JSON.stringify(bodyData),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
  return request;
}

export function PaymentRecived(data, paymentDetails) {
  let url = apiMPUrl + "/v1/market/bid/paymentReceived";
  let basicData = {
    data: {
      paymentDetails,
      finalBid: data,
    },
  };
  let bodyData = { ...basicData };
  const request = fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },

    body: JSON.stringify(bodyData),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
  return request;
}

export function updateTagName(id, data) {
  let basicData = {
    data: {
      item: data,
      isActive: true,
    },
  };
  let bodyData = { ...basicData };
  let url = apiMPUrl + "/v1/market/tag/update/" + `${id}`;
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status == "200") {
        return data;
      } else if (data.status == "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function updateStatus(id, status) {
  let basicData = {
    data: {
      isActive: status,
    },
  };
  let bodyData = { ...basicData };
  let url = apiMPUrl + "/v1/market/tag/update/" + `${id}`;
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status == "200") {
        return data;
      } else if (data.status == "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function searchTag(searchData) {
  let url = apiMPUrl + "/v1/market/tag/get/" + `${searchData}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  }).then((response) => {
    return response.json().then((data) => {
      return data;
    });
  });
}

//Lots api
export function getAllLots() {
  let url = apiMPUrl + "/v1/market/lot/get/all";
  const request = fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
  return request;
}

export function getAllOpenLots() {
  let url = apiMPUrl + "/v1/market/lot/get/active";
  const request = fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
  return request;
}

export function getAllClosedLots() {
  let url = apiMPUrl + "/v1/market/lot/get/closed";
  const request = fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
  return request;
}

export function addLot(data) {
  let url = apiMPUrl + "/v1/market/lot/create";
  let bodyData = { ...data };
  const request = fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },

    body: JSON.stringify(bodyData),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
  return request;
}

export function fileUpload(formData, getTokenData, fileType) {
  let url = apiUrl + "/v1/fileinAWS";
  let getRef = apiUrl + "/v1/filegetAWS";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(getTokenData),
  }).then((response) => {
    if (response.ok) {
      return response.json().then((data) => {
        return fetch(data.reference.url, {
          method: "PUT",
          headers: {
            ContentType: fileType,
            "Content-Type": fileType,
            ContentEncoding: "base64",
            "Content-Encoding": "base64",
          },
          body: Buffer.from(formData, "base64"),
        }).then((res) => {
          if (res.ok) {
            return fetch(getRef, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
              },
              body: JSON.stringify({
                fileName: data.reference.fileName || data.reference.filename,
                folderName:
                  data.reference.folderName ||
                  data.reference.folder ||
                  data.reference.FolderName,
                expireLimt: 1800,
              }),
            }).then((list) => {
              return list.json().then((returnData) => {
                return {
                  status: "200",
                  message: "File Uploaded SuccessFully",
                  fileName: data.reference.fileName || data.reference.filename,
                  reference: returnData.reference,
                  folderName:
                    data.reference.folderName ||
                    data.reference.folder ||
                    data.reference.FolderName,
                };
              });
            });
          } else {
            return {
              status: "500",
              message: "Something went wrong",
            };
          }
        });
      });
    }
  });
}

// export function getLotById(id, dispatch) {
//     let url = apiUrl + 'v1/market/lot/get/details/' + + `${id}`

//     return fetch(url, {
//         method: 'GET',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//         },
//     }).then(response => {
//         if (response.ok) {
//             return response.json().then((data) => {
//                 if (data.status === "200") {
//                     dispatch({
//                         type: "SET_SELECTED_LOT_ID",
//                         payload: data._id
//                     })
//                     dispatch({
//                         type: "SET_SELECTED_LOT",
//                         payload: data
//                     })
//                     return data
//                 }
//             })
//         }
//     })
// }

export function getFile(fileName, folderName) {
  let getRef = apiUrl + "/v1/filegetAWS";
  return fetch(getRef, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify({
      fileName: fileName,
      folderName: folderName,
      expireLimt: 1800,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return {
        status: result.status,
        message: result.message,
        fileName: fileName,
        reference: result.reference,
        folderName: folderName,
      };
    })
    .catch((error) => console.error("error", error));
}

export function updateLot(data, id) {
  let url = apiMPUrl + "/v1/market/lot/update/" + `${id}`;
  let bodyData = { ...data };
  const request = fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },

    body: JSON.stringify(bodyData),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
  return request;
}

export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";
export const convertINR = (number) => {
  const currency = (number ? number : 10000).toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "INR",
  });
  return currency.slice(0, -3);
};
export function getLotBids(lotId) {
  let url = apiMPUrl + "/v1/market/bid/get/highest/" + `${lotId}`;
  const request = fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
  return request;
}

export function getTagById(id) {
  let url = apiMPUrl + "/v1/market/tag/get/individual/" + `${id}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status == "200") {
        return data;
      } else if (data.status == "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}
