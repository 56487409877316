export const monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getCurrentYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return currentYear;
};

export const formatDateTime = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" }); // "Feb"
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  return `${day} ${month}, ${formattedHours}:${minutes} ${ampm}`;
};

export const openGoogleMaps = (address, formatAddress) => {
  if (!address) return;
  const formattedAddress = formatAddress(address);
  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    formattedAddress
  )}`;
  window.open(mapsUrl, "_blank");
};

export const openEmailClient = (email) => {
  if (!email) return; // Do nothing if no email is provided
  const mailtoUrl = `mailto:${email}`;
  window.open(mailtoUrl, "_blank"); // Open in a new tab or window
};
