import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Chip,
  Box,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Person,
  Category,
  Build,
  Assignment,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Description,
  LocationOn,
  LocalShipping,
  Person2,
  Email,
  Phone,
  BusinessOutlined,
} from "@mui/icons-material";
import { openEmailClient, openGoogleMaps } from "../../../../utils/helpers";
import { StyledPaper } from "../../styles";

const MPRDetails = ({
  mprData,
  pickupAddress,
  contactPerson,
  customerDetails,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const textLimit = 100;

  const text = mprData.length > 0 ? mprData[0][1]?.value : "N/A";
  const isAssigned =
    mprData.length > 0 &&
    mprData[0][7]?.value !== undefined &&
    mprData[0][7]?.value !== "";

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const formatAddress = (address) => {
    if (!address) return "N/A";
    const { Address, City, State, Pincode } = address;
    const addressParts = [
      Address.address1,
      Address.address2,
      City,
      State,
      Pincode,
    ].filter(Boolean);
    return addressParts.join(", ");
  };

  const InfoItem = ({ icon, label, value, onClick }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 1,
        mb: 2,
        cursor: onClick ? "pointer" : "default", // Change cursor to pointer if onClick is provided
        "&:hover": {
          backgroundColor: onClick ? "action.hover" : "transparent", // Add hover effect
        },
      }}
      onClick={onClick}
    >
      {icon}
      <Box>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          {label}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <StyledPaper
      sx={{
        mt: -3,
        mb: 2,
        pt: 2,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InfoItem
            icon={<BusinessOutlined color="primary" />}
            label="Customer"
            value={customerDetails?.name || "N/A"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoItem
            icon={<Person color="primary" />}
            label="Lead Name"
            value={mprData.length > 0 ? mprData[0][0]?.value : "N/A"}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InfoItem
            icon={<Category color="primary" />}
            label={t("common_service_category")}
            value={mprData.length > 0 ? mprData[0][5]?.value : "N/A"}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InfoItem
            icon={<Build color="primary" />}
            label={t("common_service_type")}
            value={mprData.length > 0 ? mprData[0][4]?.value : "N/A"}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InfoItem
            icon={<Assignment color="primary" />}
            label={t("lead_assigned_to")}
            value={
              <Chip
                label={
                  isAssigned ? mprData[0][7]?.value : t("leads_unassigned")
                }
                color={isAssigned ? "success" : "warning"}
                size="small"
                sx={{ mt: 0.5 }}
              />
            }
          />
        </Grid>

        {/* Contact person */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Contact person
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <InfoItem
            icon={<Person2 color="primary" />}
            label="Contact Person"
            value={contactPerson?.userName || "N/A"}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InfoItem
            icon={<Email color="primary" />}
            label="Email"
            value={contactPerson?.email || "N/A"}
            onClick={() => openEmailClient(contactPerson?.email)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InfoItem
            icon={<Phone color="primary" />}
            label="Phone"
            value={contactPerson?.mobile?.number || "N/A"}
          />
        </Grid>

        {/* Pickup Information */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Pickup Location
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <InfoItem
            icon={<LocationOn color="primary" />}
            label="Address"
            value={formatAddress(pickupAddress?.ReceiverAddress)}
            onClick={() =>
              openGoogleMaps(pickupAddress?.ReceiverAddress, formatAddress)
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InfoItem
            icon={<LocalShipping color="primary" />}
            label="Pickup Status"
            value={
              <Chip
                label={pickupAddress?.pickupStatus ? "Picked Up" : "Pending"}
                color={pickupAddress?.pickupStatus ? "success" : "warning"}
                size="small"
                sx={{ mt: 0.5 }}
              />
            }
          />
        </Grid>

        {/* Description Section */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
            <Description color="primary" />
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Description
              </Typography>
              {text?.length > textLimit || expanded ? (
                <Box>
                  <Typography variant="body1">
                    {expanded ? text : `${text?.slice(0, textLimit)}...`}
                  </Typography>
                  <IconButton
                    onClick={toggleExpanded}
                    size="small"
                    sx={{ ml: -1, mt: 1 }}
                  >
                    {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    <Typography variant="button" sx={{ ml: 1 }}>
                      {expanded ? "Show Less" : "Show More"}
                    </Typography>
                  </IconButton>
                </Box>
              ) : (
                <Typography variant="body1">{text}</Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default MPRDetails;
