import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Drawer as MuiDrawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Home as HomeIcon,
  Dashboard as DashboardIcon,
  Payment as PaymentIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  Payments as PaymentsIcon,
  MiscellaneousServices as MiscellaneousServicesIcon,
} from "@mui/icons-material";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import TabPannelPaymnet from "../paymentRequests/supplierCustomer/TabPanelPayment";
import TabPannelRemibursement from "../reimbursements/TabPanelReimbursement";
import TabPanelMiscellaneousReq from "../miscellaneousRequests/TabPanelMiscellaneousReq";
import { AdminDash } from "../dashboard/AdminDash";
import TabPannelVendor from "../paymentRequests/vendor/VendorTabPanel";
import TabPanelDD from "../demandDraft/TabPanelDD";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  marginTop: "65px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  marginTop: "65px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function ResponsiveDrawer() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(!isSmallScreen);
  const [selectedComponent, setSelectedComponent] = useState("");
  const data = useSelector((state) => state.userdata.getData);
  const backTabData = useSelector((state) => state.selectedBackTab);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (
      data &&
      data.roles.some((item) => "user" === item) &&
      !data.roles.some((item) => "Admin" === item) &&
      !data.roles.some((item) => "Approver" === item)
    ) {
      setSelectedComponent("supplierPaymentComp");
    } else if (
      data?.roles.some((item) => "Approver" === item) ||
      data?.roles.some((item) => "Admin" === item)
    ) {
      setSelectedComponent(isSmallScreen ? "supplierPaymentComp" : "adminComp");
    }
  }, [data,isSmallScreen]);

  useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  useEffect(() => {
    if (backTabData !== undefined && backTabData !== "") {
      setSelectedComponent(backTabData);
    }
  }, [backTabData]);

  const navigateToDashboard = () => {
    window.location.replace("/#/app/dashboard");
  };

  const makeDashDefault = () => {
    dispatch({
      type: "SET_BACK_TAB",
      payload: "",
    });
  };

  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");

  useEffect(() => {
    if (activeTab === "reimbursement") {
      setSelectedComponent("reimbursementComp");
    } else if (activeTab === "supplierPayment") {
      setSelectedComponent("supplierPaymentComp");
    } else if (activeTab === "vendorPayment") {
      setSelectedComponent("vendorPaymentComp");
    } else if (activeTab === "miscellaneousRequest") {
      setSelectedComponent("miscellaneousReqComp");
    } else if (activeTab === "ddRequest" || activeTab === "demandDraft") {
      setSelectedComponent("ddRequestComp");
    }
  }, [activeTab]);

  const showthisComponent = (targetComponent) => {
    if (targetComponent === "backHome") {
      navigateToDashboard();
      makeDashDefault();
      return;
    }
    setSelectedComponent(targetComponent);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const menuItems = [
    { text: "Home", targetComponent: "backHome", icon: <HomeIcon /> },
    ...(data?.roles.some((item) => "Admin" === item || "Approver" === item) &&
    !isSmallScreen
      ? [
          {
            text: "Dashboard",
            targetComponent: "adminComp",
            icon: <DashboardIcon />,
          },
        ]
      : []),
    {
      text: "Supplier payments",
      targetComponent: "supplierPaymentComp",
      icon: <PaymentIcon />,
    },
    {
      text: "Vendor payments",
      targetComponent: "vendorPaymentComp",
      icon: <PaymentsIcon />,
    },
    {
      text: "Reimbursements",
      targetComponent: "reimbursementComp",
      icon: <CurrencyExchangeIcon />,
    },
    {
      text: "Miscellaneous",
      targetComponent: "miscellaneousReqComp",
      icon: <MiscellaneousServicesIcon />,
    },
    {
      text: "Demand drafts",
      targetComponent: "ddRequestComp",
      icon: <RequestPageIcon />,
    },
  ];

  return (
    <Box sx={{ display: "flex", background: "white" }}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  borderRadius: 2,
                  "&.MuiButtonBase-root:hover": {
                    bgcolor:
                      item.targetComponent === selectedComponent
                        ? "#0D6937"
                        : "#fffff",
                  },
                  backgroundColor:
                    item.targetComponent === selectedComponent
                      ? "#0D6937"
                      : "inherit",
                  color:
                    item.targetComponent === selectedComponent
                      ? "white"
                      : "inherit",
                  m: 1,
                  transition: "all 0.3s",
                }}
                onClick={() => showthisComponent(item.targetComponent)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    m: open ? 1 : "auto",
                    justifyContent: "center",
                    color:
                      item.targetComponent === selectedComponent
                        ? "white"
                        : "inherit",
                  }}
                >
                  <Tooltip title={item.text}>{item.icon}</Tooltip>
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box sx={{ flexGrow: 1, p: { xs: 1, md: 2 }, pt: { xs: 4, md: 6 } }}>
        {selectedComponent === "adminComp" && <AdminDash />}
        {selectedComponent === "supplierPaymentComp" && <TabPannelPaymnet />}
        {selectedComponent === "vendorPaymentComp" && <TabPannelVendor />}
        {selectedComponent === "reimbursementComp" && (
          <TabPannelRemibursement />
        )}
        {selectedComponent === "miscellaneousReqComp" && (
          <TabPanelMiscellaneousReq />
        )}
        {selectedComponent === "ddRequestComp" && <TabPanelDD />}
      </Box>
    </Box>
  );
}
