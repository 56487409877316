export const quoationHeader = [
  {
    label: "Quote ID",
    options: {
      filter: true,
    },
  },
  {
    label: "Date",
    options: {
      filter: true,
    },
  },
  {
    name: "Amount",
    options: {
      filter: false,
    },
  },
  {
    name: "Comments",
    options: {
      filter: false,
    },
  },
  {
    name: "File",
    options: {
      filter: false,
    },
  },
  {
    name: "Edit / Accept",
    options: {
      filter: false,
    },
  },
  {
    name: "Email",
    options: {
      filter: false,
    },
  },
];
