import React from "react";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import FileBase64 from "react-file-base64";
import { getFiles } from "../utils/helperFunctions";

const styles = {
  container: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  uploadContainer: {
    display: "flex",
    justifyContent: "end",
  },
  uploadSection: {
    display: "flex",
    alignItems: "center",
  },
  fileUpload: {
    display: "flex",
    marginTop: "10px",
  },
  divider: {
    backgroundColor: "black",
    height: "1px",
    marginTop: "5px",
    marginBottom: "5px",
  },
};

const SignatureView = ({
  signature,
  handleEditSignature,
  classes,
  getFiles,
}) => (
  <Grid item xs={12} style={styles.container}>
    <Typography variant="subtitle2">Signature:</Typography>
    <IconButton
      onClick={() => getFiles(signature)}
      target="_blank"
      className={classes.collapseIcon}
      size="large"
    >
      <ImageIcon />
    </IconButton>
    <IconButton onClick={handleEditSignature}>
      <EditIcon className={classes.collapseIcon} size="large" />
    </IconButton>
  </Grid>
);

const UploadSection = ({
  handleCloseSignature,
  handleupdateImage,
  showSignatureLoader,
  handleChange,
  classes,
}) => (
  <Grid style={styles.uploadContainer}>
    <Grid item xs={12} lg={10} md={8}>
      <Grid item xs={12} lg={4} md={4} style={styles.uploadSection}>
        <Typography variant="body1">Upload Signature</Typography>
        <IconButton onClick={handleCloseSignature}>
          <CancelIcon className="close" />
        </IconButton>
      </Grid>

      <Grid item xs={12} style={styles.fileUpload}>
        <FormControl variant="standard">
          <FileBase64
            className={classes.input}
            multiple={false}
            onDone={handleupdateImage}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "right" }}>
        {showSignatureLoader ? (
          <CircularProgress size={24} />
        ) : (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => handleChange("Signature")}
            className={classes.button}
          >
            Upload
          </Button>
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider style={styles.divider} />
      </Grid>
    </Grid>
  </Grid>
);

const SignatureUploader = ({
  handleEditSignature,
  showSignature,
  handleCloseSignature,
  handleupdateImage,
  signature,
  handleChange,
  showSignatureLoader,
  classes,
}) => {
  return (
    <div>
      {showSignature ? (
        <SignatureView
          signature={signature}
          handleEditSignature={handleEditSignature}
          classes={classes}
          getFiles={getFiles}
        />
      ) : (
        <UploadSection
          handleCloseSignature={handleCloseSignature}
          handleupdateImage={handleupdateImage}
          showSignatureLoader={showSignatureLoader}
          handleChange={handleChange}
          classes={classes}
        />
      )}
    </div>
  );
};

export default SignatureUploader;
