import { apiUrl } from "../../data/config";

export function addAddress(data) {
  let url = apiUrl + "/v1/customer/newAddress";
  let bodyData = {
    branch: {
      address: data.address,
      district: data.district,
      state: data.state,
      country: data.country,
      pincode: data.pincode,
      createdAt: new Date().toISOString(),
      createdBy: "System",
      updatedBy: "System",
      isActive: true,
      tenant_id: data.tenantId,
    },
    customer: data.customerId,
  };

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      return data;
    });
  });
}
export function deleteAddress(id) {
  let url = apiUrl + "/v1/customer/deletedetails";
  let bodyData = {
    deleteItem: "customerBranch",
    deleteid: id,
  };
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      return data;
    });
  });
}

export function addCustomerBranchByTenant(data) {
  let url = apiUrl + "/v1/tenant/addBranch";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return response;
    }
  });
}
