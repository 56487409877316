import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { addCustomerBranchByTenant } from "../CustomerState";
import CustomerBranchForm from "./CustomerBranchForm";

const AddNewBranch = ({
  showBranchDialog,
  setShowBranchDialog,
  customerData,
}) => {
  const handleClose = () => {
    setShowBranchDialog(false);
    setShowLoader(false);
  };
  const [showLoader, setShowLoader] = useState(false);

  const getdata = useSelector((state) => state.userdata.getData);

  const methods = useForm({
    defaultValues: {
      email: "",
      city: "",
      state: "",
      country: "India",
    },
  });

  const handleSubmitNew = async (data) => {
    setShowLoader(true);
    const bodyData = {
      branch: {
        address: {
          address1: data.address1,
          address2: "",
        },
        district: data.city,
        state: data.state,
        country: data.country,
        pincode: data.pincode,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        createdBy: "Self",
        updatedBy: "Self",
        isActive: true,
        tenant_id: getdata?.customerid,
      },
      customer: customerData.Customer._id,
    };
    try {
      const response = await addCustomerBranchByTenant(bodyData);
      if (response.status === "200") {
        toast.success(response.message);
        setShowLoader(false);
        setShowBranchDialog(false);
        // window.location.reload();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Failed to add customer location. Please try again.");
    } finally {
      setShowLoader(false);
      setShowBranchDialog(false);
    }
  };

  return (
    <>
      <Dialog open={showBranchDialog} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h4">
            Add New Location for - {customerData?.Customer?.name}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmitNew)}>
              <CustomerBranchForm />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "14px",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {showLoader ? (
                  <CircularProgress />
                ) : (
                  <Button variant="contained" color="primary" type="submit">
                    Add
                  </Button>
                )}
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddNewBranch;
