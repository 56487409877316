import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    content:{
        padding: theme.spacing(2),
        paddingTop: theme.spacing(16),
        [theme.breakpoints.only("sm")]: {
          padding: theme.spacing(0), 
          paddingTop: theme.spacing(14),
        },
        [theme.breakpoints.only("xs")]: {
          padding: theme.spacing(0), 
          paddingTop: theme.spacing(14),
        },
      },
    Datapaper:{
        marginBottom:theme.spacing(4),
    },
    grid:{
        padding: theme.spacing(1)
    },
    userGrid:{
        paddingLeft:theme.spacing(2)
    },
    buttonGrid:{
        paddingTop:theme.spacing(2)
    },
    backIcon:{
        "&:hover": {
           textDecoration :'none'
        }
    },
    backIconGrid:{
        marginTop:'1rem',
        textAlign : 'right'
    },
    collapseIcon:{
        cursor:'pointer',
        color: theme.palette.primary.main,
        "&:hover": {
            //color: theme.palette.secondary.main
        }
    },
    iconGrid:{
        textAlign : 'right'
    },
    avatargrid:{
        // alignSelf:'center',
        // padding: theme.spacing(1),
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },
    buttonOutlined:{
        color: theme.palette.primary.main,
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        "&:hover" : {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.contrastText
        }
      },
      radioContactSelect: {
        border:'1px solid',
        borderColor:theme.palette.primary.main,
      },
      radioContact:{
        
      }
}));
