import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Button,
  Grid,
  CircularProgress,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import useStyles from "../styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCutomerList,
  sendCredential,
  UpdateCustomerStatus,
} from "../CustomerState";
import moment from "moment";
import Switch from "@mui/material/Switch";
import Table from "../../../components/Table/Table";
import { TabPanel, a11yProps } from "../../tableConfig";
import AddNewBranch from "./AddBranch";
import Contacts from "./Contacts";

export default function TabPannel() {
  const classes = useStyles();
  const { t } = useTranslation();
  var roleDefine = useSelector((state) => state.userdata);
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [customerData, setCustomerData] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [branchData, setBranchData] = useState([]);
  const [showBranch, setShowBranch] = useState(false);
  const dispatch = useDispatch();
  const [sendButton, setSendButton] = useState(false);
  const [notificationChecked, setNotificationChecked] = useState();
  const [selectedCustomerId, setSelectedCustomerId] = useState();
  const [openSend, setOpenSend] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showBranchDialog, setShowBranchDialog] = useState(false);
  const [showContactsDialog, setShowContactsDialog] = useState(false);
  const [contactData, setContactData] = useState([]);

  const datatableHeader = [
    "Date",
    t("common_customer_name"),
    t("gst"),
    t("pan"),
    t("View"),
  ];

  if (sendButton) {
    datatableHeader.push(
      t("common_notifications"),
      t("common_sendCredentials")
    );
  }

  const branchHeader = [
    "Date",
    t("common_address"),
    t("common_city"),
    t("common_state"),
    t("View"),
    t("common_action"),
  ];

  const handleChangeNotification = (event, item) => {
    let data = {
      thisCustomer: item.Customer._id,
      updateValue: {
        notificationAllowed: event.target.checked,
      },
    };
    UpdateCustomerStatus(data).then((response) => {
      if (response.status === "200") {
        toast.success(response.message);
      } else if (response.status === "401") {
        toast.error(response.message);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      } else {
        toast.error(response.message);
      }
    });
    setNotificationChecked((prev) => !prev);
  };

  const handleClose = () => {
    setOpenSend(false);
  };

  const handleSendCredentials = () => {
    setShowLoader(true);
    let data = {
      _id: selectedCustomerId,
    };
    sendCredential(data).then((response) => {
      if (response.status === "200") {
        setShowLoader(false);
        setOpenSend(false);
        toast.success(response.message);
      } else if (response.status === "401") {
        setShowLoader(false);
        setOpenSend(false);
        toast.error(response.message);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      } else {
        setShowLoader(false);
        setOpenSend(false);
        toast.error(response.message);
      }
    });
  };

  const handleShowContacts = (item) => {
    setContactData(item);
    setShowContactsDialog(true);
  };

  const handleShowBranch = (item) => {
    let branchItems = [...(item?.Branches || [])];

    
    // Sort branches by date in descending order
    branchItems.sort(
      (a, b) => moment(b.BranchInfo.createdAt).valueOf() - moment(a.BranchInfo.createdAt).valueOf()
    );
    

    let data = branchItems.map((element) => {
      let address =
        element.BranchInfo.address.address1 +
        (element.BranchInfo.address.address2
          ? "," + element.BranchInfo.address.address2
          : "");
      return [
        moment(element.BranchInfo.createdAt).format("DD-MM-YYYY"),
        address,
        element.BranchInfo.district,
        element.BranchInfo.state + " - " + element.BranchInfo.pincode,
        <Button
          variant="outlined"
          color="success"
          size="small"
          onClick={() => handleShowContacts(element)}
          className={classes.buttonOutlined}
        >
          Contacts
        </Button>,
        <Button
          variant="outlined"
          color="success"
          size="small"
          onClick={() => handleRowClick(element)}
          className={classes.buttonOutlined}
        >
          Add Lead
        </Button>,
      ];
    });

    setBranchData(data);
    setShowBranch(!showBranch);
    setSelectedItem(item);
    dispatch({
      type: "SET_SELECTED_CUSTOMER_DATA",
      payload: item.Customer,
    });
  };

  const handleClickOpenSend = (_id) => {
    setSelectedCustomerId(_id);
    setOpenSend(true);
  };

  useEffect(() => {
    if (
      roleDefine.getData &&
      roleDefine.getData.type === "tenant" &&
      roleDefine.getData.roles &&
      Array.isArray(roleDefine.getData.roles) &&
      !roleDefine.getData.roles.includes("user")
    ) {
      setSendButton(true);
    } else {
      setSendButton(false);
    }

    let bodyData = {
      tenant_id: localStorage.getItem("customerid"),
    };

    getCutomerList(bodyData, dispatch).then((response) => {
      if (response.status === "200") {
        setFetchLoader(false);
        // Sort the response data by date before processing
        const sortedCustomers = response.finalCustomer.sort((a, b) => {
          return (
            moment(b.Customer.createdAt).valueOf() -
            moment(a.Customer.createdAt).valueOf()
          );
        });

        const data = sortedCustomers.map((item) => [
          moment(item.Customer.createdAt).format("DD-MM-YYYY"),
          item.Customer.name,
          item.Customer.gst || "N/A",
          item.Customer.pan || "N/A",
          <Button
            variant="outlined"
            color="success"
            size="small"
            onClick={() => handleShowBranch(item)}
            className={classes.buttonOutlined}
          >
            Locations
          </Button>,
          roleDefine.getData &&
          roleDefine.getData.type === "tenant" &&
          roleDefine.getData.roles &&
          Array.isArray(roleDefine.getData.roles) &&
          !roleDefine.getData.roles.includes("user") ? (
            <Switch
              checked={item.Customer.notificationAllowed}
              onChange={(event) => handleChangeNotification(event, item)}
              color="success"
              sx={{
                marginTop: { sm: "-6px", xs: "-6px" },
                marginLeft: { sm: "-10px", xs: "-10px" },
              }}
            />
          ) : (
            ""
          ),
          roleDefine.getData &&
          roleDefine.getData.type === "tenant" &&
          roleDefine.getData.roles &&
          Array.isArray(roleDefine.getData.roles) &&
          !roleDefine.getData.roles.includes("user") ? (
            <Grid>
              <Button
                variant="outlined"
                color="success"
                size="small"
                onClick={() => handleClickOpenSend(item.Customer._id)}
                disabled={item.Customer.notificationAllowed === false}
                className={classes.buttonOutlined}
              >
                Send
              </Button>
            </Grid>
          ) : (
            ""
          ),
        ]);

        setCustomerData(data);
      } else {
        if (response.status === "401") {
          setFetchLoader(false);
          toast.error(response.message);
          setTimeout(() => {
            dispatch({ type: "LOG_OUT" });
            localStorage.clear();
          }, 2000);
        } else {
          setFetchLoader(false);
          toast.error(response.message);
        }
      }
    });
  }, [notificationChecked, sendButton]);

  const handleRowClick = (item) => {
    dispatch({
      type: "SET_SELECTED_BRANCH",
      payload: item,
    });

    history.push("/app/tenant/addLead");
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabData = [{ label: t("Customers"), index: 0, name: "" }];

  return (
    <div className={classes.root}>
      <Toaster />
      <Card>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          {tabData.map((item, key) => {
            return (
              <Tab
                label={item.label}
                {...a11yProps(item.index)}
                classes={{ root: classes.tab }}
                key={key}
              />
            );
          })}
        </Tabs>
        {showBranch ? (
          <div style={{ textAlign: "right", marginRight: "2rem" }}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.buttonOutlined}
              style={{ marginRight: "1rem" }}
              onClick={() => setShowBranchDialog(true)}
            >
              Add New Location
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowBranch(false)}
              size="small"
              color="primary"
              className={classes.buttonOutlined}
            >
              {t("common_back")}
            </Button>
          </div>
        ) : (
          <></>
        )}

        <TabPanel value={value} index={0} key={1}>
          {showBranch ? (
            <Table
              data={branchData}
              header={branchHeader}
              name={`Pickup Locations - ${selectedItem?.Customer?.name}`}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
                tableBodyHeight: "600px",
                textLabels: {
                  body: {
                    noMatch: fetchLoader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#0D6937", marginRight: "5px" }}
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      "No locations found"
                    ),
                  },
                },
              }}
            />
          ) : (
            <Table
              data={customerData}
              header={datatableHeader}
              // name={t("customer_list")}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
                tableBodyHeight: "600px",
                textLabels: {
                  body: {
                    noMatch: fetchLoader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#0D6937", marginRight: "5px" }}
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      "No Customers "
                    ),
                  },
                },
              }}
            />
          )}
        </TabPanel>
      </Card>
      <Dialog
        open={openSend}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to send login credentials?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            disabled={showLoader}
          >
            Cancel
          </Button>
          {showLoader ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              onClick={handleSendCredentials}
              variant="contained"
              className={classes.buttonOutlined}
              disabled={showLoader}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <AddNewBranch
        showBranchDialog={showBranchDialog}
        setShowBranchDialog={setShowBranchDialog}
        customerData={selectedItem}
      />
      <Contacts
        userData={contactData}
        customer={selectedItem}
        showContactsDialog={showContactsDialog}
        setShowContactsDialog={setShowContactsDialog}
      />
    </div>
  );
}
