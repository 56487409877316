import { getFile } from "../apis/globalApi";

export const viewFiles = (file) => {
  let body = {
    fileName: file.fileName || file.filename,
    folderName: file.folderName || file.folder || file.FolderName,
    expireLimt: 1000,
  };
  getFile(body)
    .then((response) => {
      if (response.status === "200") {
        window.open(response.reference, "_blank");
      } else {
        return response.message;
      }
    })
    .catch((error) => {
      return error;
    });
};
